import { TableCell, withStyles, Theme, createStyles } from '@material-ui/core';

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.secondary.contrastText,
      fontSize: 18,
    },
  })
)(TableCell);

export default StyledTableCell;
