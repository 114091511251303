import React, { Fragment, useState } from 'react';
import QrReader from 'react-qr-reader';
import {
  Dialog,
  DialogContent,
  useMediaQuery,
  useTheme,
  makeStyles,
  Theme,
  createStyles,
  IconButton,
  Typography,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import SpendZuz from './SpendZuz';
import { useDispatch } from 'react-redux';
import { error } from '../redux/actions/alertAction';
import { initSqrTxn } from '../redux/actions/txnAction';
import userSearchService from '../services/userSearchService';

const { REACT_APP_QR_PREFIX } = process.env;

//Passsed from Header
type PROPS = {
  //Function for closing qr dialog
  openCloseQR: (isOpen: boolean) => void;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.secondary.main,
      height: '500px',
      width: '400px',
    },
    dialogClose: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
      alignItems: 'center',
    },
    root: {
      margin: 0,
      padding: theme.spacing(2),
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  })
);

//Dialog containing qr scanner
// FIXLATER
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function QRScanner(props: PROPS) {
  const theme = useTheme();
  const isMobile = !useMediaQuery(theme.breakpoints.up('sm'));
  const classes = useStyles();
  const { openCloseQR } = props;
  const [spendZuzDialog, setSpendZuzDialog] = useState(false);
  const dispatch = useDispatch();
  const [storeName, setStoreName] = useState('');
  const [storeId, setStoreId] = useState('');
  const [qrCreator, setQrCreator] = useState('');
  const [usePOS, setusePOS] = useState('none'); // POS being used by seller
  const [posTid, setPosTid] = useState(''); // extra data for POS transaction

  // any ZUZ which reciever will definitely accept (in addition to their own)
  let additionalZuz: Array<string> = [];
  const [amt, setAmt] = useState<number | undefined>();
  let lastcall = 0;
  console.log(`QR for ${usePOS}`);

  //Handles scanning the qr code
  const handleScan = async (scan: any) => {
    if (scan !== null) {
      console.log(lastcall);
      const thiscall = Date.now();
      if (thiscall - lastcall < 3000) return;
      lastcall = thiscall;
      console.log('making call');
      // check to see if this is from square
      if (scan.indexOf('https://square.link') === 0) {
        // process the link from square
        initSqrTxn(scan)
          .then((res: any) => {
            if (res.status === 'error') {
              dispatch(error(res.msg));
            } else {
              // we got back some valid data
              console.log(res);
              setStoreName(res.sqrdata.issuerName);
              setStoreId(res.sqrdata.issuerID);
              additionalZuz = res.sqrdata.addtionalZuz;
              setAmt(res.sqrdata.amount);
              setPosTid(res.sqrdata.rid);
              setusePOS('square');
              setSpendZuzDialog(true);
            }
          })
          .catch(() => {
            dispatch(error('Error initiating transaction'));
          });
        return;
      }
      // handle an object from our system
      let scanObj;

      //Try to scan qr code and show error message if value of qr code isn't an JSON object
      try {
        console.log(scan);
        scanObj = JSON.parse(scan);
      } catch {
        // this may be new kind of QR code, we are transitioning to
        if (scan.indexOf(REACT_APP_QR_PREFIX) === 0) {
          const pieces = scan.substring(14).split('/');
          const issuer = await userSearchService.getIssuer(pieces[1]);
          scanObj = {
            id: pieces[1],
            amt: parseFloat(pieces[0]),
            code: pieces[2],
            name: issuer.businessName,
          };
        } else {
          dispatch(error('Invalid QR code'));
          return;
        }
      }

      console.log(scanObj);

      if (scanObj.name === undefined || scanObj.id === undefined) {
        dispatch(error('Invalid QR code'));
      } else {
        setStoreName(scanObj.name);
        // The storeId is required to be a string, so casting
        setStoreId(String(scanObj.id));
        setAmt(scanObj.amt);
        setQrCreator(scanObj.code);
        console.log('show spendzuz!');
        setSpendZuzDialog(true);
      }
    }
  };

  //Handles error when trying scan qr code
  const handleError = (errorMsg: string) => {
    dispatch(error('Invalid QR code:' + errorMsg));
  };

  return (
    <Fragment>
      {!spendZuzDialog && (
        <Dialog
          open={true}
          onClose={() => openCloseQR(false)}
          fullScreen={isMobile}
          maxWidth={'md'}
          classes={{
            paper: classes.dialog,
          }}
        >
          <MuiDialogTitle disableTypography className={classes.root}>
            <Typography variant="h6" style={{ alignItems: 'center' }}>
              Scan QR Code
            </Typography>
            <IconButton
              className={classes.dialogClose}
              onClick={() => openCloseQR(false)}
            >
              <FontAwesomeIcon
                icon={['far', 'times-circle']}
                color={theme.palette.primary.main}
                size="lg"
              />
            </IconButton>
          </MuiDialogTitle>
          <DialogContent>
            <QrReader
              onError={handleError}
              onScan={handleScan}
              style={{ width: '100%', justifyContent: 'center' }}
            />
          </DialogContent>
        </Dialog>
      )}
      {spendZuzDialog && (
        <SpendZuz
          openCloseSpendDialog={setSpendZuzDialog}
          openCloseQRDialog={openCloseQR}
          storeName={storeName}
          storeId={storeId}
          amt={amt}
          pos={usePOS}
          extraZuz={additionalZuz}
          qrCreator={qrCreator}
          posTid={posTid}
        ></SpendZuz>
      )}
    </Fragment>
  );
}

export default QRScanner;
