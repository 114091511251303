import React, { useState, useEffect, ChangeEvent } from 'react';
import { useDependenciesDebugger } from '../utils/depdebug'; // used to debug changes causing re-rendering
import { useSelector, useDispatch, useStore } from 'react-redux';
import { getUserById } from '../services/userSearchStoreService';

import {
  makeStyles,
  Theme,
  createStyles,
  Dialog,
  useMediaQuery,
  useTheme,
  DialogContent,
  IconButton,
  Typography,
  FormControl,
  Button,
  TextField,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { IAppState } from '../redux/reducers/reducer';
import { IUSERBOLTDATA } from '../utils/types';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { initTxn } from '../redux/actions/txnAction';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import {
  selectActingID,
  selectActingUser,
  selectIsDelegate,
} from '../redux/selectors';
import { ToggleButton } from '@material-ui/lab';
import BoltLogo from './common/BoltLogo';

function floor2penny(x:number):number {
	 return Math.floor(x*100)/100;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.secondary.main,
      minHeight: '400px',
      minWidth: '400px',
      padding: 10,
    },
    dialogContent: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
    },
    dialogClose: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
      alignItems: 'center',
    },
    root: {
      margin: 0,
      padding: theme.spacing(2),
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    selectZuzForm: {
      minWidth: 200,
      borderColor: theme.palette.secondary.main,
      alignSelf: 'center',
    },
    select: {
      alignSelf: 'center',
      width: '300px',
    },
    submitButton: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.secondary.light,
      margin: '25px',
      width: 150,
      '&:hover': {
        backgroundColor: theme.palette.secondary.light,
        color: theme.palette.primary.main,
      },
    },
    textFieldStyle: {
      width: 150,
      margin: 0,
    },
    formControlMargins: {
      marginTop: '-16px',
      marginBottom: '-8px',
    },
    tipButton: {
      backgroundColor: theme.palette.background.default,
      color: 'theme.palette.primary,main',
      margin: '15px',
      padding: '16px',
      paddingRight: '15px',
      paddingLeft: '15px',
      '&: selected': {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.secondary.light,
      },
      '&: active': {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.secondary.light,
      },
    },
    tipButtonSelected: {
      backgroundColor: 'black',
    },
  })
);

//Passed from QRScanner
type PROPS = {
  //Function for closing spend zuz dialog
  openCloseSpendDialog: (isOpen: boolean) => void;
  //Function for closing qr dialog
  openCloseQRDialog?: (isOpen: boolean) => void;
  //Name of store requesting zuz
  storeName: string;
  //Id of the store requesting zuz
  storeId: string;
  //Amount that the user needs to transfer
  amt?: number;
  // pos being used
  pos?: string;
  // any zuz, in addition to reciever (storeId), that are also definitely accepted by reciever
  extraZuz: Array<string>;
  // the identity of the app that created the QR (if from our app)
  qrCreator: string;
  // extra data for POS transaction
  posTid: string;
};

//Dialog for spending zuz
// FIXLATER
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function SpendZuz(props: PROPS) {
  const dispatch = useDispatch();
  const getState = useStore().getState;

  console.log(props);
  const theme = useTheme();
  const classes = useStyles();
  const isMobile = !useMediaQuery(theme.breakpoints.up('sm'));
  const {
    openCloseSpendDialog,
    openCloseQRDialog,
    storeName,
    storeId,
    amt,
    pos,
    extraZuz,
    qrCreator,
    posTid,
  } = props;
  const isDelegate = useSelector(selectIsDelegate);
  const actingID = useSelector(selectActingID);

  //Get user information
  const user = useSelector(selectActingUser);

  //Get all of the user's bolts
  const allUserbolts = useSelector((state: IAppState) => state.userBolts);

  const userbolts = Object.values(allUserbolts).filter(
    (bolt) => bolt.amount > 0
  );
  console.log(`spendzuz: ${storeName}, ${storeId}`);
  console.log(userbolts);

  //Get all of the user's bolts accepted for this transaction: all from store and any in extraZuz
  const storeZuz = userbolts.filter(
    (userbolt) =>
      userbolt.bolt.issuer.toString() === storeId ||
      extraZuz.includes(userbolt.bolt.specID)
  );
  console.log(storeZuz);

  // the potentially selected zuz.  If there are any for store,
  // first of those.  If not, then first of userbolts.
  const firstZuz = storeZuz.length >= 1 ? storeZuz[0] : userbolts[0];
  console.log(firstZuz);

  //State to keep track of the selected zuz and its validity
  const [selectedZuz, setSelectedZuz] = useState(firstZuz.bolt.specID);
  const [validSelectedZuz, setValidSelectedZuz] = useState(true);

  //States for amount and validity of the amount entered
  const [validAmt, setValidAmt] = useState(true); //Only used when qr code doesn't include an amount
  const [amount, setAmount] = useState(amt === undefined ? '' : amt);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [baseAmount, setBaseAmount] = useState(amt === undefined ? '' : amt);
  const [tipAmount, setTipAmount] = useState(0);

  const [tipAdded, setAddedTip] = useState(false);

  // store information
  const [tip, setTip] = useState({ ask: false, percents: [] });
  useEffect(() => {
    console.log(storeId);
    const getUserByIdCurrent = getUserById(dispatch, getState);
    getUserByIdCurrent(storeId).then((usr: any) => {
      console.log(`fetched ${storeId}`);
      console.log(usr);
      if ('tips' in usr) setTip(usr.tips);
    });
  }, [storeId]);

  console.log(
    `sz: ${selectedZuz}, fz: ${
      firstZuz.bolt.specName
    }, amount:${amount}  store:${JSON.stringify(tip)}`
  );

  // Creates the More... option
  const dummyZuz: IUSERBOLTDATA = {
    amount: 0,
    bolt: {
      version: 1,
      issuer: 0,
      issuerName: '',
      issueDate: '',
      interest: [{ start: new Date(), stop: null, rate: 0 }],
      buyback: {},
      redeem: [{ start: new Date(), fixedfee: 0, discount: 0, kind: 'Fiat' }],
      createdAt: '',
      specID: '',
      specName: 'More...',
      donation: false,
    },
  };

  // State for the selected option text that are displayed in the autocomplete
  console.log(`setting iv with ${firstZuz.bolt.specName}`);
  const [inputValue, setInputValue] = useState(firstZuz.bolt.specName);
  console.log(`iv: ${inputValue}, fz: ${firstZuz.bolt.specName}`);

  // State for the display of the popup option list in the autocomplete
  const [open, setOpen] = useState(false);
  console.log(`open:${open}`);

  // should we show just store's zuz or all of them
  const [showAllZuz, setShowAllZuz] = useState(false);
  console.log(`saz:${showAllZuz}`);

  useDependenciesDebugger({
    isDelegate,
    actingID,
    user,
    allUserbolts,
    showAllZuz,
    amount,
    selectedZuz,
    validSelectedZuz,
    validAmt,
    inputValue,
    open,
    qrCreator,
  });

  // zuzoptions are the options we will show in autocomplete drop
  // down.  Start with the ones for the target store and 'more' if
  // there are others
  let zuzoptions =
    userbolts.length > storeZuz.length ? storeZuz.concat(dummyZuz) : storeZuz;
  if (showAllZuz) {
    zuzoptions = userbolts;
  }
  console.log(`zuzoptions=${zuzoptions.length}, sz=${storeZuz.length}`);

  //Handles user input to textfield for amount
  const handleAmountChange = (e: ChangeEvent<HTMLInputElement>) => {
    console.log('hac');

    setAmount(e.target.value);
    ``;

    //If not valid amount
    if (isNaN(Number(e.target.value)) || Number(e.target.value) < 0) {
      setValidAmt(false);
      //If valid amount
    } else {
      setValidAmt(true);
    }
  };

  // Handles remove tip
  const handleRemoveTip = () => {
    setTipAmount(0);
    setAmount(baseAmount);
    setAddedTip(false);
  };

  //Handles tip when user clicks on a button
  const handleTip = (e: React.MouseEvent<HTMLButtonElement>) => {
    // Declares the values as base amount first
    console.log('handletip');
    setAmount(baseAmount);
    console.log('id:' + e.currentTarget.id);
    console.log('tip:' + tipAmount);
    console.log('baseAmount:' + baseAmount);
    setTipAmount(
        floor2penny((tip.percents[Number(e.currentTarget.id)] / 100) * Number(baseAmount))
    );
    console.log('tip:' + tipAmount);
    console.log('baseAmount:' + baseAmount);
    // Change the backend tip amount
    setAmount(
      Number.parseFloat(String(Number(baseAmount) + Number(tipAmount))).toFixed(
        2
      )
    );
    console.log(
      'amount, ' +
        Number.parseFloat(
          String(Number(baseAmount) + Number(tipAmount))
        ).toFixed(2)
    );
    setAddedTip(true);
  };

  //Set new value of amount
  const floatAmt = Number.parseFloat(String(baseAmount)).toFixed(2);

  const floatTipAmount = Number.parseFloat(String(tipAmount)).toFixed(2);

  const finalAmount = Number(floatAmt) + Number(floatTipAmount);
  console.log(`New Value: ${floatAmt}, ${floatTipAmount}, ${finalAmount}`);

  //Handles spending zuz when user clicks submit
  const handleSpend = () => {
    console.log('handleSpend');
    //If the amount entered or selected user isn't valid
    if (
      amount === undefined ||
      isNaN(Number(amount)) ||
      Number(amount) <= 0 ||
      selectedZuz === ''
    ) {
      if (
        amount === undefined ||
        isNaN(Number(amount)) ||
        Number(amount) <= 0
      ) {
        setValidAmt(false);
      }
      if (selectedZuz === '') {
        setValidSelectedZuz(false);
      }
      //If amount entered and selected user is valid
    } else {
      dispatch(
        initTxn(
          selectedZuz,
          storeId,
          Number(finalAmount),
          qrCreator,
          Number(tipAmount),
          isDelegate ? actingID : undefined,
          pos,
          posTid
        )
      );
      openCloseSpendDialog(false);
      if (openCloseQRDialog) openCloseQRDialog(false);
    }
  };

  return (
    <Dialog
      open={true}
      onClose={() => openCloseSpendDialog(false)}
      fullScreen={isMobile}
      maxWidth={'md'}
      classes={{
        paper: classes.dialog,
      }}
    >
      <MuiDialogTitle disableTypography className={classes.root}>
        <IconButton
          className={classes.dialogClose}
          onClick={() => openCloseSpendDialog(false)}
        >
          <FontAwesomeIcon
            icon={['far', 'times-circle']}
            color={theme.palette.primary.main}
            size="lg"
          />
        </IconButton>
      </MuiDialogTitle>
      <DialogContent className={classes.dialogContent}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: '25px',
          }}
        >
          <Typography variant="h6" style={{ alignItems: 'center' }}>
            {user?.firstName} <span>&nbsp;</span>
          </Typography>
          <FontAwesomeIcon
            icon={['far', 'long-arrow-right']}
            color={theme.palette.primary.main}
            size="2x"
          />
          <Typography variant="h6" style={{ alignItems: 'center' }}>
            {' '}
            <span>&nbsp;</span> {storeName}
          </Typography>
        </div>

        <BoltLogo specID={selectedZuz} />
        <div style={{ display: 'flex', marginTop: '25px' }}>
          <Typography variant="h6">
            Spend: <span>&thinsp;</span>
          </Typography>
          {/* Only display TextField if qr code doesn't include amount */}
          {amt ? (
            <Typography variant="h6">
              {' '}
              {'$' + floatAmt}
              {tipAdded ? <span> + {floatTipAmount} tip</span> : <span></span>}
              {/* {tip.ask ? <span>ask tip</span> : <span></span>} */}
            </Typography>
          ) : (
            <TextField
              className={classes.textFieldStyle}
              required
              variant="outlined"
              color="secondary"
              margin="normal"
              fullWidth
              id="amount"
              label="Amount"
              name="amount"
              autoFocus
              value={amount}
              onChange={handleAmountChange}
              error={!validAmt}
              helperText={
                validAmt ? '' : 'Enter an amount that is greater than 0'
              }
              style={{ alignSelf: 'center' }}
            />
          )}
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginTop: '16px',
          }}
        >
          {tip.ask ? (
            <div>
              <Typography variant="h6">Add a tip</Typography>
            </div>
          ) : (
            <div></div>
          )}
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginTop: '-16px',
          }}
        >
          {tip.ask ? (
            <ToggleButtonGroup>
              exclusive
              <div>
                {tip.percents[0] ? (
                  <ToggleButton
                    id="0"
                    className={classes.tipButton}
                    onClick={handleTip}
                  >
                    <>
                      <div>
                        <Typography
                          variant="h6"
                          style={{ color: theme.palette.primary.light }}
                        >
                          {tip.percents[0]}%
                        </Typography>
                        <Typography>
                          $
                          {Number.parseFloat(
                            String((tip.percents[0] / 100) * Number(baseAmount))
                          ).toFixed(2)}
                        </Typography>
                      </div>
                    </>
                  </ToggleButton>
                ) : (
                  <div></div>
                )}
                {tip.percents[1] ? (
                  <ToggleButton
                    id="1"
                    className={classes.tipButton}
                    onClick={handleTip}
                  >
                    <>
                      <div>
                        <Typography
                          variant="h6"
                          style={{ color: theme.palette.primary.light }}
                        >
                          {tip.percents[1]}%
                        </Typography>
                        <Typography>
                          $
                          {Number.parseFloat(
                            String((tip.percents[1] / 100) * Number(baseAmount))
                          ).toFixed(2)}
                        </Typography>
                      </div>
                    </>
                  </ToggleButton>
                ) : (
                  <div></div>
                )}
                {tip.percents[2] ? (
                  <ToggleButton
                    id="2"
                    className={classes.tipButton}
                    onClick={handleTip}
                  >
                    <>
                      <div>
                        <Typography
                          variant="h6"
                          style={{ color: theme.palette.primary.light }}
                        >
                          {tip.percents[2]}%
                        </Typography>
                        <Typography>
                          $
                          {Number.parseFloat(
                            String((tip.percents[2] / 100) * Number(amount))
                          ).toFixed(2)}
                        </Typography>
                      </div>
                    </>
                  </ToggleButton>
                ) : (
                  <div></div>
                )}
                {tipAdded ? (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <ToggleButton
                      onClick={handleRemoveTip}
                      style={{ margin: 'auto' }}
                    >
                      <div>
                        <Typography>Remove Tip</Typography>
                      </div>
                    </ToggleButton>
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
            </ToggleButtonGroup>
          ) : (
            <div></div>
          )}
        </div>
        <div
          style={{ display: 'flex', alignItems: 'center', marginTop: '25px' }}
        >
          <FormControl
            variant="outlined"
            margin="normal"
            classes={{
              root: classes.selectZuzForm,
              marginNormal: classes.formControlMargins,
            }}
          >
            <Autocomplete
              id="spend-zuz"
              options={zuzoptions}
              open={open}
              onOpen={() => {
                console.log('onopen');
                setOpen(true);
              }}
              onClose={() => {
                console.log('onclose');
                setOpen(false);
              }}
              defaultValue={firstZuz}
              inputValue={inputValue}
              getOptionLabel={(userbolt: IUSERBOLTDATA) =>
                userbolt.bolt.specName
              }
              className={classes.select}
              onInputChange={(event, newInputValue) => {
                console.log(`oninputchange: '${newInputValue}'`);
                if (newInputValue === 'More...') {
                  // if "More..." is selected, reset the input text to be empty
                  setInputValue('');
                  // When select "More...", continue displaying the option list
                  setOpen(true);
                  return;
                }
                setInputValue(newInputValue);
              }}
              onChange={(event, value) => {
                console.log('onchange');
                setSelectedZuz(value === null ? '' : value.bolt.specID);

                //Rerenders component when the user selects More... to see all their zuz
                if (value?.bolt.specName === 'More...') {
                  setShowAllZuz(true);
                }

                //User must select a non-null and not "More..." option
                if (value !== null && value.bolt.specName !== 'More...') {
                  setValidSelectedZuz(true);
                } else {
                  setValidSelectedZuz(false);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Zuz"
                  variant="outlined"
                  margin="normal"
                  error={!validSelectedZuz}
                  helperText={validSelectedZuz ? '' : 'Select a valid zuz'}
                />
              )}
            />
          </FormControl>
        </div>
        <Button
          className={classes.submitButton}
          onClick={handleSpend}
          disabled={!validAmt || !validSelectedZuz}
        >
          Submit
        </Button>
      </DialogContent>
    </Dialog>
  );
}

export default SpendZuz;
