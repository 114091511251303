import React, { useEffect } from 'react';
import {
  makeStyles,
  Theme,
  Typography,
  createStyles,
  useTheme,
  Card,
  Button,
  Grid,
  IconButton,
} from '@material-ui/core';
import { useState } from 'react';
import MintBolt from '../wallet/MintBolt';
import SellBolt from './SellBolt';
import { error } from '../../redux/actions/alertAction';
import { useDispatch, useSelector } from 'react-redux';
import TransferView from '../common/TransferView';
import { IAuthStateData } from '../../redux/reducers/authReducer';
import { UsersState } from '../../redux/reducers/usersReducer';
import { BoltState } from '../../redux/reducers/boltReducer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  selectActingUser,
  selectCanMint,
  selectCanSell,
} from '../../redux/selectors';
import DonateBolt from './DonateBolt';
import boltService from '../../services/boltService';
import { IBOLTDATA, IUSERBOLTDATA } from '../../utils/types';
import { calculateInterestRate } from '../../utils/bolt';
import { asCurrencyString } from '../../utils/money';
import BoltLogo from '../common/BoltLogo';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    boltAmount: {
      display: 'flex',
      marginTop: 5,
      justifyContent: 'center',
    },
    boltDetails: {
      marginTop: 20,
      display: 'flex',
      flexWrap: 'wrap',
      width: '70%',
    },
    boltDetailName: {
      flex: '50%',
      color: theme.palette.secondary.contrastText,
      textTransform: 'uppercase',
      marginBottom: 5,
    },
    boltDetailValue: {
      flex: '50%',
      textAlign: 'right',
      marginBottom: 5,
    },
    boltViewPaper: {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.secondary.main,
      minHeight: '700px',
      minWidth: '400px',
      alignItems: 'center',
      padding: 10,
    },
    boltViewContent: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
    },
    dialogClose: {
      alignSelf: 'flex-end',
    },
    buttonSection: {
      display: 'flex',
      alignItems: 'center',
      position: 'absolute',
      justifyContent: 'center',
      bottom: 0,
      paddingBottom: 20,
      width: '100%',
    },
    actionButton: {
      margin: 5,
      padding: '10px 15px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      transition: 'background-color 0.5s',
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: theme.palette.grey[200],
      },
    },
    boltCard: {
      borderRadius: 20,
      width: '100%',
      padding: theme.spacing(3),
      marginTop: 12,
      textAlign: 'center',
    },
    boltInfo: {
      margin: 18,
    },
    actionButtons: {
      marginBottom: 10,
      borderRadius: 100,
    },
    exitModal: {
      float: 'right',
    },
  })
);

type BoltViewProps = {
  logo?: any;
  boltID: string;
  closeModal: () => void;
};

type REDUXSTATE = {
  authState: IAuthStateData;
  users: UsersState;
  userBolts: BoltState;
};

function defaultBolt(): IUSERBOLTDATA {
  return {
    bolt: {
      version: 1,
      specID: '',
      specName: '',
      interest: [{ start: new Date(), stop: null, rate: 1 }],
      redeem: [{ start: new Date(), fixedfee: 0, discount: 0, kind: 'Fiat' }],
      issueDate: '',
      issuer: 0,
      issuerName: '',
      donation: false,
      createdAt: '',
    },
    amount: 0,
  };
}

//This component is a card that displays information and actions associated with a single BoLT.
// FIXLATER
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function WalletBoltCard(props: BoltViewProps) {
  const actingUser = useSelector(selectActingUser);
  const { boltID, closeModal } = props;
  const [bolt, setBolt] = React.useState<any>(defaultBolt());
  const tempBolt = useSelector((state: REDUXSTATE) => state.userBolts[boltID]);
  const dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const [isTransferView, setIsTransferView] = useState(false);
  const [mintView, setMintView] = useState(false);
  const [donateView, setDonateView] = useState(false);
  const [sellView, setSellView] = useState(false);
  const canMint = useSelector(selectCanMint);
  const canSell = useSelector(selectCanSell);
  const checkMint = (bolt: IBOLTDATA) => {
    console.log('checkmint?');
    console.log(bolt);
    return canMint(bolt);
  };

  useEffect(() => {
    boltService
      .getBoltByID(boltID)
      .then((bolt) => {
        console.log(`${boltID} -> ${JSON.stringify(bolt)}`);
        let amount = 0;
        if (tempBolt !== undefined) {
          amount = tempBolt.amount;
        }
        //console.log(bolt);
        setBolt({ bolt: bolt, amount: amount });
      })
      .catch((err) => {
        console.log('Got err when getting bolt');
        console.log(err);
        dispatch(error(err.message));
      });
    //Added boltID to dependencies to dependencies list so that useEffect is triggered whenever the boltID changed.
  }, [boltID, dispatch, tempBolt]);

  return (
    <Card className={classes.boltCard}>
      <IconButton className={classes.exitModal} onClick={closeModal}>
        <FontAwesomeIcon
          icon={['far', 'times-circle']}
          color={theme.palette.error.main}
          size="sm"
        />
      </IconButton>
      {isTransferView && (
        <TransferView
          onBack={() => setIsTransferView(false)}
          boltID={bolt.bolt.specID}
        />
      )}
      {!isTransferView && mintView && (
        <MintBolt
          setDialogOpen={setMintView}
          handleBack={() => setMintView(false)}
          specID={bolt.bolt.specID}
        ></MintBolt>
      )}
      {!isTransferView && !mintView && donateView && (
        <DonateBolt
          setDialogOpen={setDonateView}
          handleBack={() => setDonateView(false)}
          specID={bolt.bolt.specID}
        ></DonateBolt>
      )}
      {!isTransferView && !mintView && !donateView && sellView && (
        <SellBolt
          setDialogOpen={setSellView}
          handleBack={() => setSellView(false)}
          specID={bolt.bolt.specID}
        ></SellBolt>
      )}
      <Grid
        container
        alignItems="center"
        justify="center"
        spacing={1}
        direction="row"
      >
        <Grid item xs={6}>
          <Typography align="left">
            <b>{bolt.bolt.specName}</b>
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography align="right">
            {' '}
            <b> {asCurrencyString(bolt.amount)}</b>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <BoltLogo specID={boltID} />
        </Grid>
        <Grid item xs={12} className={classes.boltInfo}>
          <Typography align="left">
            Interest Rate:{' '}
            <b>{calculateInterestRate(bolt.bolt, bolt.bolt.interest)}%</b>
          </Typography>
          <Typography align="left">
            Maturity Date:{' '}
            <b>{new Date(bolt.bolt.redeem[0].start).toDateString()}</b>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {actingUser && bolt.bolt.issuer === actingUser.id.toString() && (
            <Button
              fullWidth
              className={classes.actionButtons}
              variant="contained"
              size="large"
              color="primary"
              onClick={() => setMintView(true)}
              disabled={!checkMint(bolt.bolt)}
            >
              Mint
            </Button>
          )}
        </Grid>
        <Grid item xs={6}>
          <Button
            fullWidth
            className={classes.actionButtons}
            variant="contained"
            size="large"
            color="primary"
            onClick={() => setIsTransferView(true)}
            disabled={!canSell(bolt.bolt)}
          >
            Spend
          </Button>
        </Grid>
        {actingUser && bolt.bolt.issuer === actingUser.id.toString() && (
          <Grid item xs={6}>
            <Button
              fullWidth
              className={classes.actionButtons}
              variant="contained"
              size="large"
              color="primary"
              onClick={() => setSellView(true)}
              disabled={!canSell(bolt.bolt)}
            >
              Sell
            </Button>
          </Grid>
        )}
        {bolt.bolt.donation &&
          actingUser &&
          bolt.bolt.issuer !== actingUser.id.toString() && (
            <Grid item xs={6}>
              <Button
                fullWidth
                className={classes.actionButtons}
                variant="contained"
                size="large"
                color="primary"
                onClick={() => setDonateView(true)}
              >
                Donate
              </Button>
            </Grid>
          )}
      </Grid>
    </Card>
  );
}

export default WalletBoltCard;
