import React, { useState } from 'react';
import { TextField } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
  link: {
    color: theme.palette.secondary.main,
    fontSize: 22,
    textDecoration: 'none',
  },
  checkIcon: {
    color: theme.palette.secondary.main,
  },
  timesIcon: {
    color: theme.palette.error.main,
  },
}));

type DoublePasswordProps = {
  handlePassword: (password: string) => void;
  handleResponse: (isValid: boolean) => void;
};

// FIXLATER
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function DoublePassword(props: DoublePasswordProps) {
  const handlePassword = props.handlePassword;
  const handleResponse = props.handleResponse;
  const [password, setPassword] = useState('');
  const [passwordCopy, setPasswordCopy] = useState('');

  //constants for password validation
  const minPasswordLength = 4;
  const minCapitalLetters = 1;

  //individual checks for password validation
  const confirmPassword = () => {
    return password === passwordCopy || passwordCopy.length === 0;
  };

  //checking that password length is at least 4
  const lengthCheck = (): boolean => {
    return password.length >= minPasswordLength;
  };

  //checking that password has at least 1 capital letter
  const capitalLetterCheck = (): boolean => {
    const capitalLettersRegex = /[A-Z]/g;
    const capitalLetters = password.match(capitalLettersRegex);
    return capitalLetters != null && capitalLetters.length >= minCapitalLetters;
  };

  // function to update password field onChange
  const handleChangePassword = (value: string) => {
    setPassword(value);
    validatePassword(value);
    handlePassword(value);
  };
  // function to update passwordCopy field onChange
  const handleChangePasswordCopy = (value: string) => {
    setPasswordCopy(value);
    validatePasswordCopy(value);
  };

  //performs all checks for password validation
  const validatePassword = (value: string) => {
    if (
      value === passwordCopy &&
      lengthCheck() &&
      capitalLetterCheck() &&
      passwordCopy.length > 0
    ) {
      handleResponse(true);
    } else {
      handleResponse(false);
    }
  };

  //performs all checks for passwordCopy validation
  const validatePasswordCopy = (value: string) => {
    if (
      password === value &&
      lengthCheck() &&
      capitalLetterCheck() &&
      passwordCopy.length > 0
    ) {
      handleResponse(true);
    } else {
      handleResponse(false);
    }
  };

  type passwordCheckProps = {
    isValid: boolean;
    description: string;
  };

  //Component that shows if a password validation check was met or not
  const PasswordCheck = (props: passwordCheckProps) => {
    const classes = useStyles();

    return (
      <div>
        {props.isValid ? (
          <FontAwesomeIcon
            icon={['far', 'check-circle']}
            className={classes.checkIcon}
          />
        ) : (
          <FontAwesomeIcon
            icon={['far', 'times-circle']}
            className={classes.timesIcon}
          />
        )}
        &nbsp; {props.description}
      </div>
    );
  };

  return (
    <div>
      <TextField
        margin="normal"
        color="secondary"
        required
        fullWidth
        name="password"
        label="Password"
        type="password"
        id="password"
        value={password}
        onChange={(e) => handleChangePassword(e.target.value)}
      />
      {confirmPassword() ? (
        <TextField
          margin="normal"
          color="secondary"
          required
          fullWidth
          id="passwordCopy"
          placeholder="Confirm password"
          name="passwordCopy"
          type="password"
          value={passwordCopy}
          onChange={(e) => handleChangePasswordCopy(e.target.value)}
        />
      ) : (
        <TextField
          margin="normal"
          color="secondary"
          error
          required
          fullWidth
          id="passwordCopy"
          placeholder="Confirm password"
          name="passwordCopy"
          type="password"
          autoFocus
          value={passwordCopy}
          onChange={(e) => handleChangePasswordCopy(e.target.value)}
          helperText="Passwords do not match."
        />
      )}
      <div style={{ textAlign: 'left' }}>
        <span>A password should</span>
        <br />
        <PasswordCheck
          isValid={lengthCheck()}
          description={`contain at least ${minPasswordLength} letters`}
        />
        <PasswordCheck
          isValid={capitalLetterCheck()}
          description={`have at least ${minCapitalLetters} capitalized letter`}
        />
      </div>
    </div>
  );
}

export default DoublePassword;
