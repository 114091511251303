// Page rendered when a issuer card is clicked on in the marketplace home page.

import React, {
  useEffect,
  useState /* eslint-disable-line @typescript-eslint/no-unused-vars */ /* FIXLATER */,
} from 'react';
import {
  createStyles,
  makeStyles,
  Container,
  Grid,
  CircularProgress,
} from '@material-ui/core';
import { RouteComponentProps } from 'react-router-dom';
import userSearchService from '../../services/userSearchService';
import { error } from '../../redux/actions/alertAction';
import { useDispatch, useSelector } from 'react-redux';
import { UsersState } from '../../redux/reducers/usersReducer';
import { BoltState } from '../../redux/reducers/boltReducer';
import Campaigns from './Campaigns';
import boltService from '../../services/boltService';
import { CAMPAIGNDATA, IssuerData } from '../../utils/types';
import IssuerInfo from './IssuerInfo';

const useStyles = makeStyles((/*theme: Theme*/) =>
  createStyles({
    primary: {
      marginTop: 20,
    },
    loader: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: '80vh',
    },
  }));

type VendorProps = {
  issuerId: string;
};

type REDUXSTATE = {
  userBolts: BoltState;
  users: UsersState;
};

// FIXLATER
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function Issuer(props: RouteComponentProps<VendorProps>) {
  const isAuthenticated = localStorage.getItem('token') !== null;

  const userBolts = useSelector((state: REDUXSTATE) =>
    Object.values(state.userBolts)
  );
  const classes = useStyles();
  const issuerId = props.match.params.issuerId;
  const [issuerInfo, setIssuerInfo] = React.useState<IssuerData>();
  const [buyZuzURL, setBuyZuzURL] = React.useState<string>('');
  const [issuerBolts, setIssuerBolts] = React.useState<any>([]);
  const [campaigns, setCampaigns] = React.useState<Array<CAMPAIGNDATA>>([]);
  const [loading, setLoading] = React.useState<boolean>(true);
  const dispatch = useDispatch();

  useEffect(() => {
    //gets issuerInfo, campaigns etc.
    userSearchService
      .getIssuer(issuerId)
      .then((issuer: IssuerData) => {
        console.log('Issuer: ', issuer);
        setIssuerInfo(issuer);
        if (issuer.campaigns) {
          setCampaigns(issuer.campaigns);
          if (issuer.buyurl?.length) setBuyZuzURL(issuer.buyurl as string);
          else {
            // look at each campaign to see if there is a buyurl
            for (const campaign of issuer.campaigns) {
              if (campaign.buyzuz?.length) {
                setBuyZuzURL(campaign.buyzuz);
                break;
              }
            }
          }
        }
        setLoading(false);
        console.log(campaigns);
      })
      .catch((err) => {
        // some kind of error from backend
        console.log('Got err when getting issuer');
        console.log(err);
        dispatch(error(err.message));
      });
    if (issuerInfo) {
      if (issuerInfo.bolts) {
        console.log('Getting bolts');
        console.log(issuerInfo.bolts);
        boltService
          .getBoltsByIDs([...issuerInfo.bolts, ...issuerInfo.acceptedZuz])
          .then((bolts) => {
            setIssuerBolts(bolts);
          })
          .catch((err) => {
            console.log('Got err when getting bolts');
            console.log(err);
            dispatch(error(err.message));
          });
      }
    }
  }, [issuerInfo, isAuthenticated]);

  function zuzAmount(issuerInfo: IssuerData) {
    let total = 0;

    //calculates the amount of ZUZ the current user has from a single vendor
    total = userBolts.reduce(
      (acc, next) =>
        next.bolt.issuer === issuerInfo.id ? next.amount + acc : acc,
      0
    );

    for (const val of issuerInfo.acceptedZuz) {
      total += userBolts.reduce(
        (acc, next) => (next.bolt.specID === val ? next.amount + acc : acc),
        0
      );
    }

    return total;
  }

  // *** final returned component ***
  return loading || !issuerInfo ? (
    <div className={classes.loader}>
      <CircularProgress />
    </div>
  ) : (
    <Container maxWidth="md" className={classes.primary}>
      <Grid
        container
        alignItems="center"
        justify="center"
        direction="row"
        spacing={3}
      >
        <IssuerInfo
          buyZuzUrl={buyZuzURL}
          logo={issuerInfo.logo}
          slogan={issuerInfo.slogan}
          businessName={issuerInfo.businessName ?? 'Business Name'}
          handle={issuerInfo.username ?? 'businesshandle'}
          isPublicMarketplace={false}
          bio={issuerInfo.description ?? ''}
          zuzOwned={zuzAmount(issuerInfo)}
          canSellZUZ={issuerInfo.bolts && issuerInfo.bolts.length > 0}
          issuerBolts={issuerBolts}
        />
        {campaigns.length > 0 && <Campaigns campaigns={campaigns} />}
      </Grid>
    </Container>
  );
}

export default Issuer;
