import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import React, { FC } from 'react';

type Props = {
  error: Error;
  resetErrorBoundary: () => void;
};

const ErrorFallback: FC<Props> = ({ error, resetErrorBoundary }) => {
  return (
    <Dialog open={true} onClose={resetErrorBoundary}>
      <DialogTitle>Whoops. Something went wrong!</DialogTitle>
      <DialogContent>{error.message}</DialogContent>
      <DialogActions>
        <Button onClick={resetErrorBoundary} color="primary">
          Go to Dashboard
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ErrorFallback;
