import {
  makeStyles,
  TablePagination,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import { Fragment, useState, useCallback } from 'react';
import React from 'react';
import Title from '../common/Title';
import StyledTableCell from './StyledTableCell';
import { BoltState } from '../../redux/reducers/boltReducer';
import { useSelector } from 'react-redux';
import { IAuthStateData } from '../../redux/reducers/authReducer';
import { UsersState } from '../../redux/reducers/usersReducer';
import { selectActingUser } from '../../redux/selectors';
import { calculateInterestRate } from '../../utils/bolt';

const useStyles = makeStyles((theme) => ({
  cardsSection: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  table: {
    minWidth: 700,
  },
}));

const rowsPerPageOptions = [5, 10, 25];

type REDUXSTATE = {
  userBolts: BoltState;
  authState: IAuthStateData;
  users: UsersState;
};

// FIXLATER
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function IssuedBolts() {
  const classes = useStyles();
  const user = useSelector(selectActingUser);
  const bolts = useSelector((state: REDUXSTATE) => state.userBolts);
  // const users = useSelector((state: REDUXSTATE) => state.users);

  const issuedBolts = Object.values(bolts)
    //Filter out the bolts that are not issued by the user
    .filter((bolt) => {
      return bolt.bolt.issuer.toString() === user?.id;
    })
    //Formatting the date strings
    .map((bolt) => {
      const maturityDate = new Date(bolt.bolt.redeem[0].start);
      const issueDate = new Date(bolt.bolt.issueDate);
      const interestStart = new Date(); // temporary, was: new Date(bolt.bolt.interest.start);

      return {
        ...bolt,
        bolt: {
          ...bolt.bolt,
          maturity: maturityDate.toDateString(),
          issueDate: issueDate.toDateString(),
          interest: {
            ...bolt.bolt.interest,
            start: interestStart.toDateString(),
          },
        },
      };
    });

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = useCallback(
    (__, page) => {
      setPage(page);
    },
    [setPage]
  );
  const handleChangeRowsPerPage = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newRowsPerPage = parseInt(e.target.value, 10);
    setRowsPerPage(newRowsPerPage);
  };

  return (
    <Fragment>
      <Title>Issued ZUZ</Title>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Issue Date</StyledTableCell>
              <StyledTableCell>Maturity Date</StyledTableCell>
              <StyledTableCell>Interest Rate</StyledTableCell>
              <StyledTableCell>Interest Start Date</StyledTableCell>
              <StyledTableCell>Num of Payments</StyledTableCell>
              <StyledTableCell>Buyback</StyledTableCell>
              <StyledTableCell>Amount</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {issuedBolts
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((bolt) => (
                <TableRow key={bolt.bolt.specID}>
                  <TableCell>{bolt.bolt.issueDate}</TableCell>
                  <TableCell>
                    {/*temp: was bolt.bolt.maturity*/ new Date()}
                  </TableCell>
                  <TableCell>
                    {calculateInterestRate(bolt.bolt, bolt.bolt.interest)}%
                  </TableCell>
                  <TableCell>
                    {/*temp: was bolt.bolt.interest.start*/ new Date()}
                  </TableCell>
                  <TableCell>{bolt.bolt.buyback ? 'Yes' : 'No'}</TableCell>
                  <TableCell>{bolt.amount}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        count={issuedBolts.length}
        rowsPerPage={5}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Fragment>
  );
}

export default IssuedBolts;
