import { IBOLTDATA, InterestSpec } from './types';

// scroll through interest rate specs to calculate current interest
export function calculateInterestRate(
  bolt: IBOLTDATA,
  interest: Array<InterestSpec>
): number {
  console.log('calculating interest rate');
  console.log(bolt);
  console.log(interest);
  let rate = 0;
  const now = new Date();
  for (const spec of interest) {
    if (spec.start <= now && (spec.stop == null || spec.stop >= now)) {
      rate += spec.rate;
    }
  }
  return rate;
}
