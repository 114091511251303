import { Snackbar } from '@material-ui/core';
// Not in use
// import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { error } from '../../redux/actions/alertAction';
import { IAppState } from '../../redux/reducers/reducer';
import { Alert, Color } from '@material-ui/lab';

// FIXLATER
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function AlertMessage() {
  const alert = useSelector((state: IAppState) => state.alertState);
  const dispatch = useDispatch();

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      autoHideDuration={5000}
      onClose={() => dispatch(error(''))}
      open={alert.message !== ''}
    >
      <Alert severity={alert.type as unknown as Color}>{alert.message}</Alert>
    </Snackbar>
  );
}

export default AlertMessage;
