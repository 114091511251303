/* Component to find account/initate forgot password process. Users submit their username here and if their account is found, they will
 * receive a link by email, which they can use to reset their password.
 */
import React, { useState } from 'react';
import {
  Container,
  Typography,
  CssBaseline,
  TextField,
  Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { forgotPassword } from '../../redux/actions/usersAction';
import { useDispatch } from 'react-redux';
import { error } from '../../redux/actions/alertAction';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
}));

// FIXLATER
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function ForgotPassword() {
  const classes = useStyles();
  const dispatch = useDispatch();
  // if okToSend is true, error message will be sent
  // if okToSend is false, email will be sent
  const [okToSend, setOkToSend] = useState(false);
  const [username, setUsername] = useState('');
  const [isSuccess, setIsSucess] = useState<null | boolean>(null);
  const msg = 'Please wait a minute for the message to be delivered';
  const DelayDispatch = () => {
    setTimeout(() => {
      setOkToSend(false);
    }, 60000);
  };
  const onSubmit = () => {
    setIsSucess(null);
    if (okToSend === false) {
      dispatch(forgotPassword(username, setIsSucess));
      setOkToSend(true);
    } else {
      dispatch(error(msg));
      DelayDispatch();
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      {isSuccess ? (
        <div className={classes.paper}>
          <Typography component="h3" variant="h4">
            A reset password email has been sent to your inbox!
          </Typography>
          <div>
            Please follow the instructions in the email to reset your password.
          </div>
          <br />
          <Typography
            component="h5"
            variant="h6"
            style={{ fontStyle: 'italic' }}
          >
            <div>Didn't see the email?</div>
            <div>Click to resend.</div>
          </Typography>
          <Button
            type="submit"
            variant="contained"
            size="large"
            color="primary"
            onClick={onSubmit}
          >
            Resend
          </Button>
        </div>
      ) : (
        <div className={classes.paper}>
          <Typography component="h3" variant="h4">
            Find Your Account
          </Typography>
          <br />
          <div>
            Enter your username and we'll send you a link to get back into your
            account.
          </div>
          <TextField
            margin="normal"
            color="secondary"
            required
            fullWidth
            id="username"
            placeholder="Username"
            name="username"
            autoComplete="username"
            autoFocus
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <br />
          <Button
            type="submit"
            variant="contained"
            size="large"
            color="primary"
            onClick={onSubmit}
          >
            Submit
          </Button>
        </div>
      )}
    </Container>
  );
}

export default ForgotPassword;
