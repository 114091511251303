import React, { useState, ChangeEvent } from 'react';
import {
  makeStyles,
  Theme,
  createStyles,
  CardContent,
  CardActions,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { MintPermissions } from '../../redux/reducers/authReducer';
import { IBOLTDATA, IUSERDATA, SPECUUID } from '../../utils/types';
import { delegatePermissions } from '../../redux/actions/usersAction';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { IAppState } from '../../redux/reducers/reducer';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Card from '@material-ui/core/Card';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Slip from '../common/Slip';
import SlipContent from '../common/SlipContent';
import SlipActions from '../common/SlipActions';
import { selectBoltSpecs } from '../../redux/selectors';
import UserSearchField from '../common/UserSearchField';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    boltViewPaper: {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.secondary.main,
      minHeight: '500px',
      minWidth: '400px',
      alignItems: 'center',
      padding: 10,
    },
    addIcon: {
      margin: theme.spacing(1),
    },
    permissionSection: {
      display: 'block',
    },
    h3: {
      display: 'inline',
    },
  })
);

type PROPS = {
  setDialogOpen: (isOpen: boolean) => void;
};

// FIXLATER
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function DelegationModal(props: PROPS) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const setDialogOpen = props.setDialogOpen;
  //  const users = useSelector((state: IAppState) => state.users);
  // userBolts map
  const userBolts = useSelector((state: IAppState) => state.userBolts);
  // array of bolt specs
  const bolts = useSelector(selectBoltSpecs);

  //The temporary states of each permission by type
  const [delegateUser, setDelegateUser] = useState<IUSERDATA | null>();
  const [userValid, setUserValid] = useState(true);

  const [mint, setMint] = useState(false);
  const [mintPermissions, setMintPermissions] = useState<MintPermissions>({});
  // mini dialog for a single spec mint permission
  const [addMint, setAddMint] = useState(false);
  const [newMintSpec, setNewMintSpec] = useState<IBOLTDATA | null>();
  const [newMintLimit, setNewMintLimit] = useState(0);
  const [
    newMintValid /* eslint-disable-line @typescript-eslint/no-unused-vars */ /* FIXLATER */,
    setNewMintValid,
  ] = useState(true);

  const [spend, setSpend] = useState(false);
  const [spendSpecs, setSpendSpecs] = useState<Array<IBOLTDATA>>([]);
  const [spendLimit, setSpendLimit] = useState(0);

  const [accept, setAccept] = useState(false);

  // Closes the dialog box
  const handleClose = () => {
    setDialogOpen(false);
  };

  // ensure some user is set
  const handleUserChange = (user: IUSERDATA | null) => {
    setDelegateUser(user);
    console.log('set user to: ' + user);
    if (!user) {
      console.log('invalid user');
      setUserValid(false);
    } else {
      console.log('valid user');
      setUserValid(true);
    }
  };

  // link on/off checkbox statuses to variables
  const handleMintChange = (e: ChangeEvent<HTMLInputElement>) => {
    setMint(e.target.checked);
  };
  const handleSpendChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSpend(e.target.checked);
  };
  const handleAcceptChange = (e: ChangeEvent<HTMLInputElement>) => {
    setAccept(e.target.checked);
  };

  const handleMintLimitChange = (input: string) => {
    const limit = parseFloat(input);
    if (limit < 0) {
      setNewMintLimit(0);
    } else {
      setNewMintLimit(limit);
    }
  };

  // add mint permission with specID and limit to running list
  const addMintPermission = () => {
    if (!newMintSpec || !newMintLimit || newMintLimit <= 0) {
      setNewMintValid(false);
    } else {
      setNewMintValid(true);
      const specID = newMintSpec.specID;
      setMintPermissions({
        ...mintPermissions,
        [specID]: {
          specID: specID,
          maxLimit: newMintLimit,
        },
      });

      // clear temporary variables
      setNewMintSpec(null);
      setNewMintLimit(0);
      setAddMint(false);
    }
  };
  // delete mint permission from the list for the current delegate
  const removeMint = (specID: SPECUUID) => {
    const { [specID]: permission, ...rest } = mintPermissions; // eslint-disable-line @typescript-eslint/no-unused-vars
    setMintPermissions(rest);
  };

  // only submit if a user is set
  const handleSubmit = () => {
    if (!delegateUser) {
      setUserValid(false);
    } else {
      handleClose();
      dispatch(
        delegatePermissions(
          delegateUser.id,
          mintPermissions,
          {
            specIDs: spendSpecs.map((spec) => spec.specID),
            maxLimit: spendLimit,
          },
          accept
        )
      );
    }
  };

  return (
    <Dialog
      classes={{
        paper: classes.boltViewPaper,
      }}
      open={true}
      onClose={handleClose}
    >
      <DialogTitle id="form-dialog-title">Assign Delegates</DialogTitle>
      <DialogContent>
        <form>
          {/* delegate user field */}
          <h3>Username </h3>
          <UserSearchField onSelect={handleUserChange} />
          {/* <Autocomplete // UserSearchField
            id="user-search-field"
            aria-required
            options={Object.values(users)}
            getOptionLabel={(user: IUSERDATA) => user.username}
            style={{ width: 300 }}
            onChange={(event, value) => handleUserChange(value)}
            renderInput={(params) => (
             
              <TextField
                {...params}
                label="Delegate username"
                variant="outlined"
                margin="normal"
                error={!userValid}
                helperText={userValid ? "" : "A user must be set to assign permissions"}
              />
              
            )}
          /> */}

          <h3>Abilities</h3>
          {/* mint permissions */}
          <FormControlLabel
            control={
              <Checkbox
                id="mint"
                checked={mint}
                onChange={handleMintChange}
                className={classes.permissionSection}
              />
            }
            label="Mint"
          />
          {mint && (
            <div>
              {Object.values(mintPermissions).map((permission) => (
                <Slip key={permission.specID}>
                  <SlipContent>
                    {userBolts[permission.specID].bolt.specName}
                    <br />
                    limit: {permission.maxLimit}
                  </SlipContent>
                  <SlipActions>
                    <Button onClick={() => removeMint(permission.specID)}>
                      Remove
                    </Button>
                  </SlipActions>
                </Slip>
              ))}

              {/* new mint permission for temporary list */}
              <Fab
                size="small"
                color="primary"
                aria-label="add mint permission"
                className={classes.addIcon}
                variant="extended"
                onClick={() => setAddMint(true)}
              >
                <AddIcon />
                mint permission
              </Fab>
              {addMint && (
                <Card>
                  <CardContent>
                    <Autocomplete
                      id="mint-bolt-autocomplete"
                      aria-required
                      options={Object.values(bolts)}
                      getOptionLabel={(bolt: IBOLTDATA) => bolt.specName}
                      filterOptions={(options, state) =>
                        options.filter(
                          (option) =>
                            !Object.keys(mintPermissions).includes(
                              option.specID
                            ) && option.specName.includes(state.inputValue)
                        )
                      }
                      style={{ width: 300 }}
                      value={newMintSpec}
                      onChange={(event, bolt) => setNewMintSpec(bolt)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="ZUZ spec"
                          variant="outlined"
                          margin="normal"
                        />
                      )}
                    />
                    <TextField
                      id="mint limit"
                      label="Limit"
                      type="number"
                      value={newMintLimit}
                      onChange={(event) =>
                        handleMintLimitChange(event.target.value)
                      }
                    />
                  </CardContent>
                  <CardActions>
                    <Button onClick={() => addMintPermission()}>Add</Button>
                  </CardActions>
                </Card>
              )}
            </div>
          )}
          <br></br>
          {/* spend permissions */}
          <FormControlLabel
            control={
              <Checkbox
                id="spend"
                checked={spend}
                onChange={handleSpendChange}
                className={classes.permissionSection}
              />
            }
            label="Spend"
          />
          {spend && (
            <div>
              <Autocomplete
                id="mint-bolt-autocomplete"
                aria-required
                options={Object.values(bolts)}
                multiple
                getOptionLabel={(bolt: IBOLTDATA) => bolt.specName}
                filterSelectedOptions
                style={{ width: 300 }}
                value={spendSpecs}
                onChange={(event, specs) => setSpendSpecs(specs)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="ZUZ specs"
                    variant="outlined"
                    margin="normal"
                  />
                )}
              />
              <TextField
                id="spend limit"
                label="Limit"
                type="number"
                value={spendLimit}
                onChange={(event) =>
                  setSpendLimit(parseFloat(event.target.value))
                }
              />
            </div>
          )}
          <br></br>
          {/* accept permissions */}
          <FormControlLabel
            control={
              <Checkbox
                id="accept"
                checked={accept}
                onChange={handleAcceptChange}
                className={classes.permissionSection}
              />
            }
            label="Accept"
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary" disabled={!userValid}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DelegationModal;
