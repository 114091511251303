import React from 'react';
import { Provider } from 'react-redux';
import OuterThemeProvider from './context/ThemeContext';
import CssBaseline from '@material-ui/core/CssBaseline/CssBaseline';
import { Switch, Route, Router, Redirect } from 'react-router-dom';
import { history } from './utils/history';
import PrivateRouter from './components/auth/PrivateRouter';
import './App.css';
import store from './redux';
import { loadAuthState } from './redux/actions/authAction';
import WebSocketProvider from './context/WebSocketContext';
import AlertMessage from './components/common/AlertMessage';
import Confirmation from './components/auth/Confirmation';
import Header from './components/Header';
import About from './components/About';
import Home from './components/Home';
import Login from './components/auth/Login';
import Vendors from './components/marketplace/Vendors';
import Issuer from './components/marketplace/Issuer';
import Profile from './components/profile/Profile';
import Register from './components/auth/Register';
import Wallet from './components/wallet/Wallet';
import MerchantPos from './components/merchantPos/MerchantPos';
import ExpiredSessionModal from './components/auth/ExpiredSessionModal';
import Settings from './components/settings/Settings';
import DelegatedPermissions from './components/settings/DelegatedPermissions';
import ForgotPassword from './components/auth/ForgotPassword';
import ResetPassword from './components/auth/ResetPassword';
import Preferences from './components/settings/Preferences';
import ManageAccounts from './components/settings/ManageAccounts';
import Help from './components/help';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faUser,
  faUserEdit,
  faUserCog,
  faCog,
  faWallet,
  faStore,
  faQrcode,
  faSearch,
  faBell,
  faCheckCircle,
  faLongArrowRight,
  faRepeat,
  faTimesCircle,
  faExpand,
  faPlus,
  faTrashAlt,
  faTachometerAlt,
  faImage,
  faEdit,
  faCashRegister,
  faGlobe,
  faInfoCircle,
  faSignOut,
  faBarcodeRead,
  faThermometerEmpty,
  faThermometerQuarter,
  faThermometerHalf,
  faThermometerThreeQuarters,
  faThermometerFull,
} from '@fortawesome/pro-regular-svg-icons';
import EditProfile from './components/profile/EditProfile';
import PaymentModal from './components/PaymentModal';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from './components/ErrorFallback';
import { logerrBackend } from './utils/logger';
import QR from './components/QR';
import ScrollToTop from './components/ScrollToTop';

// import all FontAwesome icons used in the app
library.add(
  faUser,
  faUserEdit,
  faUserCog,
  faCog,
  faWallet,
  faStore,
  faQrcode,
  faSearch,
  faBell,
  faCheckCircle,
  faLongArrowRight,
  faRepeat,
  faTimesCircle,
  faExpand,
  faPlus,
  faTrashAlt,
  faTachometerAlt,
  faImage,
  faEdit,
  faCashRegister,
  faGlobe,
  faInfoCircle,
  faSignOut,
  faBarcodeRead,
  faThermometerEmpty,
  faThermometerQuarter,
  faThermometerHalf,
  faThermometerThreeQuarters,
  faThermometerFull
);

// Check for token and update application state if required
store.dispatch<any>(loadAuthState());

const { REACT_APP_WEBSITE_URL } = process.env;

console.log(`${REACT_APP_WEBSITE_URL} is base url`);

const now = new Date();

// specifies only app context to avoid context rerendering
// FIXLATER
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function App() {
  return (
    <div>
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onReset={() => history.push('/wallet')}
        onError={(error) => logerrBackend(error.message, false)}
      >
        <OuterThemeProvider>
          <Provider store={store}>
            <WebSocketProvider>
              <AlertMessage />
              <PaymentModal neverBefore={now} />
              <Router history={history}>
                <ScrollToTop />
                <ExpiredSessionModal />
                <CssBaseline />
                <Header>
                  <Switch>
                    {/* The login page can be accessed with userRole as a parameter ("/login/merchant")
                     * or without any parameter ("/login") */}
                    <Route path="/login/:userRole" component={Login} />
                    <Route path="/login" component={Login} />
                    <Route path="/register" component={Register} />
                    <Route
                      path="/email-verification"
                      component={Confirmation}
                    />
                    <Route path="/forgot-password" component={ForgotPassword} />
                    <Route
                      path="/reset-password/:username"
                      component={ResetPassword}
                    />
                    {/* Routes to external links in usezuz */}
                    <Route path="/about" component={About} />
                    <Route path="/help" component={Help} />
                    {/* Users need to login before going to the other paths*/}
                    <PrivateRouter path="/home" component={Home} />
                    <PrivateRouter path="/profile" component={Profile} />
                    <PrivateRouter path="/settings" component={Settings} />
                    <PrivateRouter
                      path="/delegate"
                      component={DelegatedPermissions}
                    />
                    <PrivateRouter
                      path="/editprofile"
                      component={EditProfile}
                    />
                    <PrivateRouter
                      path="/preferences"
                      component={Preferences}
                    />
                    <PrivateRouter
                      path="/manage-accounts"
                      component={ManageAccounts}
                    />
                    <PrivateRouter path="/wallet" component={Wallet} />
                    <Route path="/marketplace" component={Vendors} />
                    <Route path="/vendor/:issuerId" component={Issuer} />
                    <PrivateRouter path="/pos" component={MerchantPos} />
                    <PrivateRouter
                      path="/qr/:amount/:issuerId/:code"
                      component={QR}
                    />
                    {/* essentially, 404 goes to wallet page */}
                    <Redirect from="/*" to="/wallet" />
                  </Switch>
                </Header>
              </Router>
            </WebSocketProvider>
          </Provider>
        </OuterThemeProvider>
      </ErrorBoundary>
    </div>
  );
}

export { App };
