import React from 'react';
import { Grid } from '@material-ui/core';
import QRCodeCreator from './QRCodeCreater';

// FIXLATER
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function MerchantPos() {
  return (
    <Grid container alignItems="center" justify="center">
      <QRCodeCreator />
    </Grid>
  );
}

export default MerchantPos;
