import { Dispatch } from 'redux';
import txnService from '../../services/txnService';
import { ITXNDATA, SPECUUID, USERUUID } from '../../utils/types';
import { TxnActionsTypes } from './actionTypes';
import { error, success } from './alertAction';
import homeService from '../../services/homeService';

//Gets all the user's transactions
// FIXLATER
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getTxnsAction = (transactions: Array<ITXNDATA>) => {
  return {
    type: TxnActionsTypes.GET_TXNS,
    payload: { transactions },
  };
};

//Action for receiving transaction
// FIXLATER
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const receiveTxnAction = (transaction: ITXNDATA, firstTime = false) => {
  return {
    type: TxnActionsTypes.RECEIVE_TXN,
    payload: { transaction, firstTime },
  };
};

// service call to transform square shorturl
// FIXLATER
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const initSqrTxn: (sqrul: string) => any = (sqlurl: string) => {
  return txnService.initSqrTxnService(sqlurl);
};

//Service call and error handling for initiating transactions
export const initTxn = (
  boltID: SPECUUID,
  userID: USERUUID,
  amount: { base: number; tip: number } | number,
  qrCreator: string,
  tip?: number,
  merchantID?: USERUUID,
  usingPOS?: string,
  posTid?: string
) => {
  // FIXLATER
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return (dispatch: Dispatch) => {
    console.log('initTxn in txnAction');
    return txnService
      .initTxnService(
        boltID,
        userID,
        amount,
        qrCreator,
        tip,
        merchantID,
        usingPOS,
        posTid
      )
      .then((res: any) => {
        if (res.status === 'error') {
          return dispatch(error(res.msg));
        } else {
          let msg = 'Successful transaction!';
          if (res.transaction.status === 'confirmed') {
            console.log('confirmed result for txn');
            dispatch(receiveTxnAction(res.transaction));
            dispatch(updateTxnAction(res.transaction));
          } else {
            msg = 'Successfully initiated transaction!';
            dispatch(initTxnAction(res.transaction));
          }
          return dispatch(success(msg));
        }
      })
      .catch(() => {
        return dispatch(error('Error initiating transaction'));
      });
  };
};

//Action for initiating transactions
// FIXLATER
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const initSqrTxnAction = (transaction: ITXNDATA) => {
  return {
    type: TxnActionsTypes.INIT_SQR_TXN,
    payload: { transaction },
  };
};

//Action for initiating transactions
// FIXLATER
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const initTxnAction = (transaction: ITXNDATA) => {
  return {
    type: TxnActionsTypes.INIT_TXN,
    payload: { transaction },
  };
};

//Action for updating transactions
// FIXLATER
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const updateTxnAction = (transaction: ITXNDATA) => {
  return {
    type: TxnActionsTypes.UPDATE_TXN,
    payload: { transaction },
  };
};

//Service call and error handling for accepting transactions
export const acceptTxnAction = (
  txn: ITXNDATA,
  callback: (status: string, txn?: ITXNDATA) => void
) => {
  // FIXLATER
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return (dispatch: Dispatch) => {
    homeService
      .acceptRejectTxns(txn._id, 'confirmed')
      .then((res) => {
        // response for update gives only sender/receiver IDs; will change
        // response bolt giving transaction ID instead of bolt ID at the moment
        const newTxn = {
          ...res.transaction,
          bolt: txn.bolt,
          sender: txn.sender,
          receiver: txn.receiver,
        };
        if (res.status !== 'error') {
          // store dehydrated version
          dispatch(acceptRejectTxnAction(res.transaction));
        }
        // send back hydrated version to the component
        callback(res.status, newTxn);
      })
      .catch(() => {
        callback('error');
        dispatch(error('AJAX error while accepting transaction'));
      });
  };
};

//Service call and error handling for rejecting transactions
export const rejectTxnAction = (txn: ITXNDATA) => {
  // FIXLATER
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return (dispatch: Dispatch) => {
    homeService
      .acceptRejectTxns(txn._id, 'declined')
      .then((res) => {
        if (res.status === 'error') {
          dispatch(error(res.msg));
        } else {
          // response for update gives only sender/receiver IDs; will change
          // response bolt giving transaction ID instead of bolt ID at the moment
          const newTxn = {
            ...res.transaction,
            bolt: txn.bolt.specID,
            sender: txn.sender.id,
            receiver: txn.receiver.id,
          };
          dispatch(acceptRejectTxnAction(newTxn));
          dispatch(success('Successfully rejected transaction!'));
        }
      })
      .catch(() => {
        dispatch(error('Error rejecting transaction'));
      });
  };
};

// cancelling an outgoing transaction behaves the same as declining an
// incoming transaction
export const cancelTxn = rejectTxnAction;

//Action for accepting/rejecting transactions
// FIXLATER
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const acceptRejectTxnAction = (
  transaction: ITXNDATA,
  firstTime = false
) => {
  return {
    type: TxnActionsTypes.ACCEPT_REJECT_TXN,
    payload: { transaction, firstTime },
  };
};
