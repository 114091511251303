import {
  makeStyles,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Paper,
  Button,
  TablePagination,
  Typography,
} from '@material-ui/core';
import Title from '../common/Title';
import React, { Fragment, useState, useCallback } from 'react';
import StyledTableCell from './StyledTableCell';
import { useSelector } from 'react-redux';
import { getOutgoingTransactions } from '../../redux/selectors';

const useStyles = makeStyles((theme) => ({
  cardsSection: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  table: {
    minWidth: 700,
  },
}));

const rowsPerPageOptions = [5, 10, 25];

// FIXLATER
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function OutgoingTransactions() {
  const transactions = useSelector(getOutgoingTransactions);
  const classes = useStyles();

  // Reformatting date strings
  const outstandingTransactions = Object.values(transactions).map(
    (transaction) => {
      const updatedAt = new Date(transaction.updatedAt);
      const createdAt = new Date(transaction.createdAt);
      const formattedUpdatedAt = updatedAt.toDateString();
      const formattedCreatedAt = createdAt.toDateString();
      return {
        ...transaction,
        createdAt: formattedCreatedAt,
        updatedAt: formattedUpdatedAt,
      };
    }
  );

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  //Function for handling changing pages in Outgoing Transactions table
  const handleChangePage = useCallback(
    (__, page) => {
      setPage(page);
    },
    [setPage]
  );

  //Function for handling changes the number of rows in the table
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Fragment>
      <Title>Outgoing Transactions</Title>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Status</StyledTableCell>
              <StyledTableCell>Date Created</StyledTableCell>
              <StyledTableCell>Date Updated</StyledTableCell>
              <StyledTableCell>To</StyledTableCell>
              <StyledTableCell>Bolt ID</StyledTableCell>
              <StyledTableCell>Value</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {outstandingTransactions
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((transaction) => (
                <TableRow key={transaction._id}>
                  <TableCell>
                    <Typography>Pending</Typography>
                    <Button variant="contained" color="primary">
                      Cancel
                    </Button>
                  </TableCell>
                  <TableCell>{transaction.createdAt}</TableCell>
                  <TableCell>{transaction.updatedAt}</TableCell>
                  <TableCell>{transaction.receiver.email}</TableCell>
                  <TableCell>{transaction.bolt.specID}</TableCell>
                  <TableCell>{transaction.amount}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        count={outstandingTransactions.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Fragment>
  );
}

export default OutgoingTransactions;
