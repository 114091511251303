import { makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(() => ({
  transactionActions: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

/**
 * SlipActions, like CardActions, functions as the section of a Slip
 * containing any action buttons. This component includes minimal
 * styling for self-positioning and pushing contents to the end/right
 * of itself for better separation from the Slip content.
 */
const SlipActions: React.FC = (props) => {
  const { children, ...other } = props;
  const classes = useStyles();

  return (
    <div className={classes.transactionActions} {...other}>
      {children}
    </div>
  );
};

export default SlipActions;
