import React, { useState, Fragment } from 'react';
import {
  Dialog,
  DialogContent,
  IconButton,
  Typography,
  Button,
  DialogTitle,
  TextField,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  makeStyles,
  Theme,
  createStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { IUSERBOLTDATA, IUSERDATA } from '../utils/types';
import { BoltState } from '../redux/reducers/boltReducer';
import { useSelector, useDispatch } from 'react-redux';
import { initTxn } from '../redux/actions/txnAction';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { selectActingID, selectIsDelegate } from '../redux/selectors';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    boltViewPaper: {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.secondary.main,
      minHeight: '500px',
      minWidth: '400px',
      alignItems: 'center',
      padding: 10,
    },
    boltViewContent: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
    },
    dialogClose: {
      alignSelf: 'flex-end',
    },
    buttonSection: {
      display: 'flex',
      alignItems: 'center',
      position: 'absolute',
      justifyContent: 'center',
      bottom: 0,
      paddingBottom: 20,
      width: '100%',
    },
    actionButton: {
      margin: 5,
      padding: '10px 15px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      transition: 'background-color 0.5s',
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: theme.palette.grey[200],
      },
    },
    dialogTitle: {
      display: 'block',
      width: '380px',
      textAlign: 'center',
    },
    purchaseDialog: {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.secondary.main,
      height: '500px',
      minWidth: '400px',
      alignItems: 'center',
      padding: 10,
    },
    paymentMethod: {
      alignContent: 'left',
      width: 300,
    },
  })
);

type REDUXSTATE = {
  userBolts: BoltState;
};

type USERSEARCHPROPS = {
  setDialogOpen: (isOpen: boolean) => void;
  selectedUser: IUSERDATA | undefined;
};

// FIXLATER
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function UserSearchDialog(props: USERSEARCHPROPS) {
  const classes = useStyles();
  const { setDialogOpen, selectedUser } = props;
  const [transferView, setTransferView] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const boltFromState = useSelector((state: REDUXSTATE) => state.userBolts);
  const bolts = Object.values(boltFromState);
  const [bolt, setBolt] = useState<IUSERBOLTDATA>();
  const [transferAmount, setTransferAmount] = useState('');
  const dispatch = useDispatch();
  const isDelegate = useSelector(selectIsDelegate);
  const actingID = useSelector(selectActingID);

  //Confirm transanction on Transfer view
  const confirmTransaction = () => {
    console.log('usersearchdialog');
    const amt = parseInt(transferAmount, 10);
    //If selected user and bolt are defined then transfer bolts
    if (selectedUser && bolt) {
      setTransferView(false);
      dispatch(
        initTxn(
          bolt.bolt.specID,
          selectedUser.id,
          amt,
          '',
          undefined,
          isDelegate ? actingID : undefined,
          undefined,
          undefined
        )
      );
    } else {
      console.log('No selected user or bolt');
    }
  };

  return (
    <Fragment>
      {/* Dialog that opens when user clicks on user in search bar */}
      <Dialog
        onClose={() => setDialogOpen(false)}
        open={true}
        fullScreen={fullScreen}
        maxWidth={'md'}
        classes={{
          paper: classes.boltViewPaper,
        }}
      >
        <DialogContent className={classes.boltViewContent}>
          <IconButton
            className={classes.dialogClose}
            onClick={() => setDialogOpen(false)}
          >
            <FontAwesomeIcon icon={['far', 'times-circle']} />
          </IconButton>
          <Typography variant="h5" component="h2">
            <b>
              {selectedUser
                ? selectedUser?.firstName + ' ' + selectedUser?.lastName
                : ''}
            </b>
          </Typography>
          <div className={classes.buttonSection}>
            <div className={classes.actionButton}>
              <Button onClick={() => setTransferView(true)}>Transfer</Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>

      {/* Dialog for transferring bolts to selected user */}
      <Dialog
        onClose={() => setTransferView(false)}
        open={transferView}
        fullScreen={fullScreen}
        maxWidth={'md'}
        classes={{
          paper: classes.boltViewPaper,
        }}
      >
        <DialogTitle>
          Transfer Bolts to{' '}
          {selectedUser
            ? selectedUser?.firstName + ' ' + selectedUser?.lastName
            : 'User'}
        </DialogTitle>
        <DialogContent className={classes.boltViewContent}>
          <Autocomplete
            id="bolt-search-field"
            options={bolts}
            // Will be displaying bolt name instead once backend changes get deployed on server
            getOptionLabel={(bolt: IUSERBOLTDATA) =>
              '$' + bolt.amount + ' - ' + bolt.bolt.issuerName
            }
            style={{ width: 300 }}
            onChange={(event, value) => {
              if (value !== null) {
                setBolt(value);
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Search Your ZUZ"
                variant="outlined"
                margin="normal"
              />
            )}
          />
          <FormControl
            margin="normal"
            style={{ width: 300 }}
            variant="outlined"
          >
            <InputLabel htmlFor="outlined-adornment-amount">Amount</InputLabel>
            <OutlinedInput
              id="outlined-adornment-amount"
              value={transferAmount}
              onChange={(e) => setTransferAmount(e.target.value)}
              startAdornment={
                <InputAdornment position="start">$</InputAdornment>
              }
              labelWidth={60}
            />
          </FormControl>
          <div className={classes.buttonSection}>
            <div className={classes.actionButton}>
              <Button onClick={confirmTransaction}>Confirm</Button>
            </div>
            <div className={classes.actionButton}>
              <Button onClick={() => setTransferView(false)}>Back</Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
}

export default UserSearchDialog;
