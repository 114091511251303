import { Dispatch } from 'redux';
import loginService from '../../services/loginService';
import { AuthActionsTypes } from './actionTypes';
import { getTxnsAction } from './txnAction';
import { updateAuthAxios } from '../../services/serviceUtils';
import txnService from '../../services/txnService';
import { error } from './alertAction';
import { history } from '../../utils/history';
import { getUserBoltsAction } from './boltAction';
import boltService from '../../services/boltService';
import { IAppState } from '../reducers/reducer';
import {
  AccessPermission,
  AccessPermissions,
  ILoadAuthAction,
  ISwitchAccountAction,
  MintPermission,
  MintPermissions,
} from '../reducers/authReducer';
import { USERUUID } from '../../utils/types';
import { getSingleUser } from './usersAction';
import { WS } from '../../context/WebSocketContext';

// generate uniq code for this load of application

let codePerLoad: number = undefined as unknown as number;

// switches Redux state to the specified account
export const switchAccounts = (loginID: string, actingID: string, ws?: WS) => {
  // FIXLATER
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return (dispatch: Dispatch) => {
    if (codePerLoad === undefined) {
      codePerLoad = Date.now();
    }
    console.log('switchaccount');
    return Promise.resolve(
      dispatch(switchAccountsAction(loginID, actingID, ws))
    ).then(() => {
      //only redirect to wallet on reload if we are coming from the login page
      if (
        history.location.pathname === '/login' ||
        history.location.pathname === '/login/merchant'
      ) {
        const callbackURL = new URLSearchParams(window.location.search).get(
          'callbackURL'
        );
        if (callbackURL) window.location.href = callbackURL;
        else history.push('/wallet');
      }
    });
  };
};

// action to switch to account
const switchAccountsAction = (
  loginID: string,
  actingID: string,
  ws?: WS
): ISwitchAccountAction => {
  return {
    type: AuthActionsTypes.SWITCH,
    payload: { loginID, actingID, ws },
  };
};

// loads authState from localstorage
export const loadAuthState = () => {
  // FIXLATER
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return (dispatch: Dispatch, getState: () => IAppState) => {
    dispatch(loadAuthStateAction());
    // temporarily need to fetch user email and token
    const effectiveUser = getState().authState.effectiveUser;
    if (effectiveUser) {
      const loginID = effectiveUser.loginID;
      const actingID = effectiveUser.actingID;
      console.log(`LAS: ${loginID} and ${actingID}`);
      const token = getState().authState.accounts[loginID].token;
      if (token) {
        dispatch<any>(authAction(loginID, actingID, token));
      }
    }
  };
};

// action to signal middleware to load authState from localstorage
// called on page load
export const loadAuthStateAction = (): ILoadAuthAction => {
  return {
    type: AuthActionsTypes.LOAD,
  };
};

// indicates we are authorized, and gets transactions for this user
export const authAction = (
  loginID: USERUUID,
  actingID: USERUUID,
  token: string
) => {
  // FIXLATER
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return (dispatch: Dispatch, getState: () => IAppState) => {
    //    console.log('authAction');
    // update our basic authAxios headers to reflect new token
    updateAuthAxios(token, actingID, loginID);

    // need to get authenticated users and current user before loginAuthAction to get the account ID in redux
    const getRequiredAccounts = async () => {
      await dispatch<any>(getSingleUser(loginID));
      await dispatch<any>(getSingleUser(actingID));
      for (const accountId in getState().authState.accounts) {
        await dispatch<any>(getSingleUser(accountId));
        // need user data for all accounts who gives access to "accountId"
        for (const delegationId in getState().authState.accounts[accountId]
          ?.delegatedPermissions) {
          await dispatch<any>(getSingleUser(delegationId));
        }
      }
    };
    return getRequiredAccounts().then(() => {
      dispatch<any>(switchAccounts(loginID, actingID));
    });
  };
};

//Service call and error handling for getting user transactions
export const getTxn = (merchantID?: USERUUID) => {
  // FIXLATER
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return (dispatch: Dispatch) => {
    return txnService
      .getTxnService(merchantID)
      .then((res: any) => {
        if (res.status === 'error') {
          dispatch(error(res.msg));
        } else {
          dispatch(getTxnsAction(res.transactions));
        }
      })
      .catch((/*err*/) => {
        dispatch(error('Error getting user transactions'));
      });
  };
};

//Service call and error handling for getting user bolts
export const getUserBolts = (merchantID?: USERUUID) => {
  // FIXLATER
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return (dispatch: Dispatch) => {
    return boltService
      .getUserBolts(merchantID)
      .then((res) => {
        if (res.status === 'error') {
          dispatch(error(res.msg));
        } else {
          dispatch(getUserBoltsAction(res.bolts));
        }
      })
      .catch((/*err*/) => {
        dispatch(error('Error getting user ZUZ'));
      });
  };
};

// This action is used for user login.
// it will send post request, if respond with error message, dispatch error message and update error state
// If login sucessfully, it will send another post request to get user transactions and update txn state
export const loginAction = (
  username: string,
  password: string,
  rememberMe: boolean,
  ws: WS
) => {
  // FIXLATER
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return (dispatch: Dispatch) => {
    loginService
      .loginNewUser(username, password)
      .then((res: any) => {
        console.log(res);
        if (res.status === 'error') {
          dispatch(error(res.msg));
        } else {
          // connect websocket
          localStorage.setItem('token', res.token);
          ws.connect(res.token, res.id.toString()); // if already connected, then will reconnect with this token and user.id

          // transform delegated permissions into Redux format
          const allPermissions = res.permissions
            ? Object.keys(res.permissions).reduce(
                (permissions, delegatorID) => {
                  return {
                    ...permissions,
                    [delegatorID]: convertResponsePermissions(
                      res.permissions[delegatorID]
                    ),
                  };
                },
                {}
              )
            : {};
          // add account info to authState
          dispatch(
            loginAuthAction(
              res.token,
              res.id,
              allPermissions,
              rememberMe,
              res.roles[0]
            )
          );
          // update current account status/info
          dispatch<any>(authAction(res.id, res.id, res.token));
        }
      })
      .catch((err: any) => {
        console.log(err);
        dispatch(error('Error when logging in'));
      });
  };
};

// Converts an API response set of delegated permissions for a single merchant/user
const convertResponsePermissions = (resPermissions: any): AccessPermission => {
  return resPermissions.reduce(
    (permissions: AccessPermission, permission: any) => {
      switch (permission.type) {
        case 'accept':
          return {
            ...permissions,
            accept: true,
          };
        case 'mint':
          return {
            ...permissions,
            mint: permission.mints.reduce(
              (mintPermissions: MintPermissions, mint: MintPermission) => {
                return {
                  ...mintPermissions,
                  [mint.specID]: mint,
                };
              }
            ),
          };
        case 'sell':
          return {
            ...permissions,
            sell: {
              specIDs: permission.sell.specIDs,
              maxLimit: permission.sell.maxLimit,
            },
          };
      }
      return permissions;
    },
    // default no permissions
    {
      accept: false,
      mint: {},
      sell: {
        specIDs: [],
        maxLimit: 0,
      },
    }
  );
};

// FIXLATER
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const loginAuthAction = (
  token: string,
  loginID: string,
  permissions: AccessPermissions,
  rememberMe: boolean,
  userRole: string
) => {
  return {
    type: AuthActionsTypes.LOGIN,
    payload: { token, loginID, permissions, rememberMe, userRole },
  };
};

// signals server to clean up socket connection and clears local storage
export const logoutAllAccounts = (socket: SocketIOClient.Socket) => {
  // FIXLATER
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return (dispatch: Dispatch) => {
    loginService
      .logoutUser()
      .then((res: any) => {
        if (res.status === 'error') {
          // disconnect socket anyway
          socket.disconnect();
          console.log('Error logging out:', res.msg);
        }
      })
      .catch((err: any) => {
        // disconnect socket anyway
        socket.disconnect();
        console.log('Error logging out:', err);
      })
      .finally(() => {
        // can't fail logout
        dispatch(logoutAllAction());
      });
  };
};

// logout of a specific account (updates redux store, session storage, and local storage)
// switches to another logged-in account, if one exists. otherwise, disconnects from server
export const logoutAccount = (
  accountID: string,
  socket: SocketIOClient.Socket
) => {
  // FIXLATER
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return (dispatch: Dispatch, getState: () => IAppState) => {
    dispatch(logoutAuthAction(accountID));
    const loginID = getState().authState.effectiveUser?.loginID;
    const accounts = getState().authState.accounts;
    if (!loginID) {
      if (Object.keys(accounts).length > 0) {
        const key = Object.keys(accounts)[0];
        const newLoginID = accounts[key].accountID;
        dispatch<any>(switchAccounts(newLoginID, newLoginID));
      } else {
        dispatch<any>(logoutAllAccounts(socket));
      }
    }
  };
};

// clears data about logged-in account from the redux store, session storage, and local storage
// FIXLATER
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const logoutAuthAction = (accountID: string) => {
  return {
    type: AuthActionsTypes.LOGOUT,
    payload: {
      accountID,
    },
  };
};

//clears data about logged-in account(s) from the redux store, session storage, and local storage
// FIXLATER
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const logoutAllAction = () => {
  return {
    type: AuthActionsTypes.LOGOUT_ALL,
  };
};
