import React, { useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { USERUUID } from '../../utils/types';
import AddProfileModal from './addProfileModal';
import { switchAccounts } from '../../redux/actions/authAction';

import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import {
  Box,
  List,
  ListItem,
  Fab,
  Typography,
  makeStyles,
  createStyles,
  Theme,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { selectProfiles } from '../../redux/selectors';
import SwitchIcon from '@material-ui/icons/ExitToApp';
import { WebSocketContext, WS } from '../../context/WebSocketContext';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawerContainer: {
      height: '100%',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
    profilesBox: {
      display: 'flex',
      width: '50%',
      flexDirection: 'column',
      justifyItems: 'center',
    },
    listItemProfiles: {
      '&:hover': {
        background: theme.palette.grey[300],
      },
    },
    profileDetails: {
      fontStyle: 'italic',
    },
    addIconBox: {
      textAlign: 'right',
      marginBottom: 15,
    },
    profileCard: {
      '&hover': {
        background: 'blue',
      },
      minWidth: '50%',
      textAlign: 'center',
    },
    switchButton: {
      position: 'absolute',
      // bottom: theme.spacing(8),
      right: theme.spacing(10),
      marginTop: 25,
      paddingBottom: 25,
    },
  })
);

// FIXLATER
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const SwitchProfile = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const ws = useContext(WebSocketContext) as WS;

  // list of account profiles on this device
  const profiles = useSelector(selectProfiles);

  //State for modal to add users
  const [addProfileModalState, toggleAddProfileModalState] =
    useState<boolean>(false);

  //State of whether drawer should appear or not
  const [active, toggleActive] = useState<boolean>(false);

  //onClick function for card. Changes profiles
  const changeProfile = (loginID: USERUUID, actingID: USERUUID) => {
    dispatch(switchAccounts(loginID, actingID, ws));
  };

  return (
    <Box>
      <Fab
        size="medium"
        color="primary"
        aria-label="add"
        variant="extended"
        className={classes.switchButton}
        onClick={() => toggleActive(true)}
      >
        <SwitchIcon />
        Switch Profile
      </Fab>
      <SwipeableDrawer
        anchor={'bottom'}
        open={active}
        onClose={() => toggleActive(false)}
        onOpen={() => toggleActive(true)}
      >
        {/* Modal for adding users */}
        <Box className={classes.drawerContainer}>
          <AddProfileModal
            open={addProfileModalState}
            closeFunction={() => toggleAddProfileModalState(false)}
          />

          <Box className={classes.profilesBox}>
            {profiles.length > 0 ? (
              <List>
                {/* does NOT yet show or handle delegated accounts */}
                {profiles.map((profile) => {
                  return (
                    <div key={profile.loginID}>
                      <ListItem
                        id={profile.loginID}
                        className={classes.listItemProfiles}
                        button
                        onClick={() =>
                          changeProfile(profile.loginID, profile.loginID)
                        }
                      >
                        <Typography>
                          {profile.profileData?.businessName
                            ? profile.profileData?.businessName
                            : profile.profileData?.username}
                        </Typography>
                      </ListItem>
                      <List key={profile.loginID + 'delegatedaccts'}>
                        {profile.delegatedProfiles.map((delegatedProfile) => {
                          return (
                            <ListItem
                              key={
                                profile.loginID +
                                  ' as delegate for ' +
                                  delegatedProfile && delegatedProfile?.id //for typechecking, see notes in authReducer.ts
                              }
                              className={classes.listItemProfiles}
                              button
                              onClick={() => {
                                if (delegatedProfile) {
                                  //for typechecking, see notes in authReducer.ts
                                  changeProfile(
                                    profile.loginID,
                                    delegatedProfile.id
                                  );
                                }
                              }}
                            >
                              {delegatedProfile && delegatedProfile?.username}{' '}
                              {/* for typechecking, see notes in authReducer.ts */}
                              &nbsp;
                              <span className={classes.profileDetails}>
                                delegated
                              </span>
                            </ListItem>
                          );
                        })}
                      </List>
                    </div>
                  );
                })}
              </List>
            ) : (
              <Typography>No Linked Profiles Yet!</Typography>
            )}

            <Box className={classes.addIconBox}>
              <Fab size="small" color="primary" aria-label="add">
                <AddIcon onClick={() => toggleAddProfileModalState(true)} />
              </Fab>
            </Box>
          </Box>
        </Box>
      </SwipeableDrawer>
    </Box>
  );
};

export default SwitchProfile;
