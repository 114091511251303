import React, { useState, ChangeEvent, useEffect, useCallback } from 'react';
import {
  makeStyles,
  Theme,
  createStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Card,
  Checkbox,
  FormControlLabel,
  CardContent,
  CardActions,
  Fab,
  Grid,
} from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { createBolt } from '../../redux/actions/boltAction';
import { IBOLTDATA } from '../../utils/types';
import { selectActingUser, selectBoltSpecs } from '../../redux/selectors';
import Autocomplete from '@material-ui/lab/Autocomplete';
import AddIcon from '@material-ui/icons/Add';
import Slip from '../common/Slip';
import SlipContent from '../common/SlipContent';
import SlipActions from '../common/SlipActions';
import WaitDialog from './WaitDialog';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    boltViewPaper: {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.secondary.main,
      alignItems: 'center',
      padding: 10,
    },
  })
);

type ADDBOLTS = {
  setDialogOpen: (isOpen: boolean) => void;
};

type BUYBACK = {
  bolt: IBOLTDATA;
  fee: number;
};

// FIXLATER
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function NewBolt(props: ADDBOLTS) {
  const classes = useStyles();
  const setDialogOpen = props.setDialogOpen;
  const user = useSelector(selectActingUser);

  //temporarily using array of user bolts to test this page
  const bolts: Array<IBOLTDATA> = useSelector(selectBoltSpecs);
  //array of bolts from all issuers
  const [issuerBolts, setIssuerBolts] = useState<Array<IBOLTDATA>>([]);

  const today = new Date();
  const tomorrow = new Date();
  tomorrow.setDate(new Date().getDate() + 1);

  //The different states used to keep track of the values and validity of the values for maturity, rate, number, and buyback
  const [name, setName] = useState('');
  const [nameValid, setNameValid] = useState(true);
  const [maturityDate, setMaturityDate] = useState<Date | null>(tomorrow);
  const [interest, setInterest] = useState('');
  const [maturityValid, setMaturityValid] = useState(true);
  const [interestValid, setInterestValid] = useState(false);

  //Shows form for adding another buyback option
  const [buybackForm, setBuybackForm] = useState(false);
  //Selected buyback in form
  const [buybackSpec, setBuybackSpec] = useState<IBOLTDATA | null>(null);
  //Selected buyback fee in form
  const [buybackFee, setBuybackFee] = useState('');
  //Validity of buyback fee in form
  const [buybackFeeValid, setBuybackFeeValid] = useState(true);
  //Dictionary of all buyback options added thus far
  const [buyback, setBuyback] = useState<{ [specID: string]: BUYBACK } | null>(
    null
  );
  const [isLoading, setIsLoading] = React.useState<null | boolean>(null);
  const [donation, setDonation] = useState(false);
  const dispatch = useDispatch();

  //gets all bolts from all issuers
  useEffect(() => {
    //once we are able to get a response from backend, set issuerBolts accordingly (see getAllIssuerBolts function)
    //temporarily using array of user bolts for testing
    setIssuerBolts(bolts);
  }, [bolts]);

  //Hardcoded Number of Payments
  const numPayments = 0;

  //Closes the dialog box
  const handleClose = useCallback(() => {
    setDialogOpen(false);
  }, [setDialogOpen]);

  //When the user types a name
  const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
    if (e.target.value) {
      setNameValid(true);
    } else {
      setNameValid(false);
    }
  };

  //When the user selects the maturity date
  const handleMaturityChange = (date: Date | null) => {
    setMaturityDate(date);

    //checks validity of the date
    if (date === null || date.getTime() < new Date().getTime()) {
      setMaturityValid(false);
    } else {
      setMaturityValid(true);
    }
  };

  //When the user types in the interest rate
  const handleRateChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    setInterest: any,
    setInterestValid: any
  ) => {
    setInterest(e.target.value);
    //checks validity of the interest rate
    if (isNaN(Number(e.target.value)) || Number(e.target.value) < 0) {
      setInterestValid(false);
    } else {
      setInterestValid(true);
    }
  };

  //When the user adds buyback option
  const addBuyback = () => {
    if (buybackSpec?.specID && buybackFee && buybackFeeValid) {
      setBuyback((prevBuyback) => {
        const specID = buybackSpec?.specID;
        const interest = Number(buybackFee);
        return {
          ...prevBuyback,
          [specID]: {
            bolt: buybackSpec,
            fee: interest,
          },
        };
      });
      setBuybackSpec(null);
      setBuybackFee('');
      setBuybackForm(false);
    }
  };

  //When the user removes buyback option
  const removeBuyback = (specID: string) => {
    setBuyback((prevBuyback) => {
      if (prevBuyback && Object.keys(prevBuyback).length > 1) {
        const { [specID]: buyback, ...rest } = prevBuyback; // eslint-disable-line @typescript-eslint/no-unused-vars
        return rest;
      } else {
        return null;
      }
    });
  };

  //When the user submits the form for creating a bolt
  const handleSubmit = () => {
    const maturity =
      maturityDate === null ? '' : maturityDate.getTime().toString();
    const rate = Number(interest);
    const number = Number(numPayments);
    let buybackParam = null;
    //strip off extraneous bolt information used for display
    if (buyback) {
      buybackParam = Object.fromEntries(
        Object.entries(buyback).map(([specID, buyback]) => {
          return [specID, buyback.fee];
        })
      );
    }

    setIsLoading(true);
    dispatch(
      createBolt(
        name,
        maturity,
        rate,
        number,
        donation,
        buybackParam,
        setIsLoading
      )
    );
  };

  useEffect(() => {
    if (isLoading === false) {
      handleClose();
    }
  }, [isLoading, handleClose]);

  if (isLoading) {
    return (
      <WaitDialog
        title="Creating new ZUZ specification..."
        description="Please wait while we create your new ZUZ specification."
      />
    );
  }

  return (
    <Dialog
      classes={{
        paper: classes.boltViewPaper,
      }}
      open={true}
      onClose={handleClose}
    >
      <DialogTitle id="form-dialog-title">
        Create a New ZUZ Specification
      </DialogTitle>
      <DialogContent>
        <form>
          <Grid container spacing={1} justify="center" alignItems="flex-end">
            <Grid item xs={12} sm={12}>
              <TextField
                required
                color="secondary"
                margin="normal"
                fullWidth
                id="name"
                label="Name"
                name="name"
                placeholder={
                  'e.g. ' + user?.username + ' ' + today.toDateString()
                }
                autoFocus
                value={name}
                onChange={handleNameChange}
                error={!nameValid}
                helperText={nameValid ? '' : 'Must provide a name'}
              />
            </Grid>
            <Grid item xs={12} sm={8}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="normal"
                  id="maturityDate"
                  label="Maturity Date"
                  value={maturityDate}
                  onChange={handleMaturityChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  fullWidth
                  error={!maturityValid}
                  helperText={
                    maturityValid
                      ? ''
                      : 'Maturity date must be after the current date'
                  }
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                required
                margin="normal"
                id="interestRate"
                label="Interest Rate"
                name="interestRate"
                type="number"
                placeholder="e.g. 1"
                autoFocus
                value={interest}
                onChange={(event) =>
                  handleRateChange(event, setInterest, setInterestValid)
                }
                error={!interestValid}
                helperText={
                  interestValid
                    ? ''
                    : 'Interest rate must be a number greater than or equal to 0'
                }
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              {' '}
              <FormControlLabel
                value="start"
                control={
                  <Checkbox
                    color="secondary"
                    value={donation}
                    onChange={(e) => {
                      setDonation(e.target.checked);
                    }}
                  />
                }
                label="Allow Donation"
              />
            </Grid>
            <Grid item xs={12} sm={8}>
              <div
                style={{
                  fontSize: 16,
                  alignItems: 'baseline',
                  marginBottom: -1,
                }}
              >
                <span style={{ color: 'gray' }}>Redemption policy: &nbsp;</span>
                <FormControlLabel
                  value="start"
                  control={<Checkbox color="secondary" checked={true} />}
                  label="U.S. Dollars"
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <span style={{ fontSize: 16, color: 'gray' }}>Buyback: </span>
              {buyback &&
                Object.values(buyback).map((bback: BUYBACK) => (
                  <Slip key={bback.bolt.specID}>
                    <SlipContent>
                      {bback.bolt.specName}: {bback.fee}%
                    </SlipContent>
                    <SlipActions>
                      <Button onClick={() => removeBuyback(bback.bolt.specID)}>
                        Remove
                      </Button>
                    </SlipActions>
                  </Slip>
                ))}
              <br />
              {buybackForm ? (
                <Card>
                  <CardContent>
                    <Autocomplete
                      id="mint-bolt-autocomplete"
                      aria-required
                      options={issuerBolts}
                      getOptionLabel={(bolt: IBOLTDATA) => bolt.specName}
                      filterOptions={(options, state) =>
                        options.filter(
                          (option) =>
                            (!buyback ||
                              !Object.keys(buyback).includes(option.specID)) &&
                            option.specName.includes(state.inputValue)
                        )
                      }
                      style={{ width: 300 }}
                      value={buybackSpec}
                      onChange={(event, bolt) => setBuybackSpec(bolt)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="ZUZ spec"
                          variant="outlined"
                          margin="normal"
                        />
                      )}
                    />
                    <TextField
                      id="buyback interest"
                      label="Buyback Fee"
                      type="number"
                      value={buybackFee}
                      onChange={(event) =>
                        handleRateChange(
                          event,
                          setBuybackFee,
                          setBuybackFeeValid
                        )
                      }
                      error={!buybackFeeValid}
                      helperText={
                        buybackFeeValid
                          ? ''
                          : 'Interest rate must be a number greater than or equal to 0'
                      }
                    />
                  </CardContent>
                  <CardActions>
                    <Button onClick={() => addBuyback()}>Add</Button>
                    <Button onClick={() => setBuybackForm(false)}>
                      Cancel
                    </Button>
                  </CardActions>
                </Card>
              ) : (
                <Fab
                  size="small"
                  color="primary"
                  aria-label="add buyback"
                  variant="extended"
                  onClick={() => setBuybackForm(true)}
                >
                  <AddIcon />
                  Add Buyback
                </Fab>
              )}
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" variant="contained">
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          color="primary"
          variant="contained"
          disabled={!maturityValid || !interestValid}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default NewBolt;
