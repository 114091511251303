import { logAxios } from '../services/serviceUtils';

export async function logerrBackend(
  msg: string,
  forceReload: boolean
): Promise<void> {
  await logAxios(msg);
  console.log(`report to @seth on wallet channel: ${msg}.`);
  //alert(`report to @seth on wallet channel: ${msg}.`);
  if (forceReload) window.location.reload();
}
