import { ModalActionsTypes } from './actionTypes';

// action to open modal
// FIXLATER
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const openModal = () => {
  return {
    type: ModalActionsTypes.OPEN_MODAL,
  };
};

// action to open modal
// FIXLATER
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const closeModal = () => {
  return {
    type: ModalActionsTypes.CLOSE_MODAL,
  };
};
