import { noauthAxios } from './serviceUtils';
import {
  checkUsernameURL,
  registerURL,
  CheckUserResponse,
  ApiErrorResponse,
} from './api';

// This function is used to login with a specific user at the backend and tracks the logged in user using cookies

// FIXLATER
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const registerNewUser = async (
  email: string,
  firstName: string,
  lastName: string,
  password: string,
  role: string,
  username: string,
  phone?: string,
  businessName?: string
) => {
  // formulate request data
  const data = {
    'Content-Type': 'application/json;charset=UTF-8',
    email,
    firstName,
    lastName,
    password,
    role,
    username,
  };
  if (phone) {
    Object.assign(data, { phone: phone });
  }
  if (businessName) {
    Object.assign(data, { businessName: businessName });
  }

  //send post request to backend
  const response = await noauthAxios
    .post(registerURL, data, { withCredentials: true })
    .catch((err) => {
      console.log(err);
      return err.response;
    });
  return response.data;
};

// checks if username is available

const checkUsername = async (
  username: string
): Promise<ApiErrorResponse | CheckUserResponse> => {
  const url = checkUsernameURL + username;

  const response = (await noauthAxios.get(url).catch((err) => {
    console.log(err);
    return err.response;
  })) as { data: CheckUserResponse };
  return response.data;
};

const registerService = {
  registerNewUser,
  checkUsername,
};
export default registerService;
