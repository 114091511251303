import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Button,
  Box,
  makeStyles,
  TextField,
  Typography,
  Avatar,
  IconButton,
  CircularProgress,
  Modal,
  Dialog,
  DialogTitle,
  DialogContent,
  Badge,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Edit } from '@material-ui/icons';
import { useStyles as ProfileStyles } from './Profile';
import { editUser, changePassword } from '../../redux/actions/usersAction';
import { getImageURL } from '../../services/api';
import { selectActingUser } from '../../redux/selectors';
import DoublePassword from '../auth/DoublePassword';
import { success } from '../../redux/actions/alertAction';
import { IUSERDATA } from '../../utils/types';

const useStyles = makeStyles((theme) => ({
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
    textAlign: 'center',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    borderRadius: 10,
    textTransform: 'none',
  },
  reset: {
    margin: theme.spacing(3, 0, 2),
    borderRadius: 10,
    textTransform: 'none',
  },
  usernameCheck: {
    fontStyle: 'italic',
    color: 'red',
    fontSize: '0.75rem',
    textAlign: 'left',
  },
  avatarBox: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
    margin: theme.spacing(2),
    maxHeight: '7vh',
    minWidth: '30vh',
  },
  displayNone: {
    display: 'none',
  },
  modalBox: {
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    display: 'flex',
    position: 'absolute',
    top: '50%',
    left: '60%',
    transform: 'translate(-60%, -50%)',
    width: 400,
    backgroundColor: 'rgba(250, 250, 250, 255)',
    p: 4,
  },
  dialogTitle: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  passwordButton: {
    marginTop: '.5rem',
    alignItems: 'left',
    justifyContent: 'center',
    flexDirection: 'column',
    display: 'flex',
  },
}));

/**
 * Top-level component to edit user's profile
 */
// FIXLATER
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const EditProfile = (/*props: RouteComponentProps<any>*/) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const profileClasses = ProfileStyles();
  const user = useSelector(selectActingUser);

  //Form fields
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string | null>(null);
  const [username, setUsername] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [profilePic, setProfilePic] = useState<File | string | null>(null);
  const [modalOpen, setModalOpen] = useState(false);

  // Change Password Modal fields
  const [currentPassword, setCurrentPassword] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [passwordIsValid, setPasswordIsValid] = useState(false);
  const [isSuccess, setIsSucess] = useState<null | boolean>(null);
  const [showPopup, setShowPopup] = useState<boolean>(false);

  useEffect(() => {
    if (user === undefined) return;
    setFirstName(user.firstName || '');
    setLastName(user.lastName || '');
    setUsername(user.username || '');
    setEmail(user.email || '');
    setProfilePic(user.profilePic || null);
  }, [user]);

  // Returns avatar based on whether profile picture exists or returns an avatar with their initials
  // Client can click on the profile picture to update it
  const returnAvatar = () => {
    let res = null;
    if (profilePic) {
      let profileObj: any = null;
      if (typeof profilePic === 'string') {
        profileObj = getImageURL(profilePic);
      } else {
        profileObj = URL.createObjectURL(profilePic);
      }
      res = <Avatar src={profileObj} className={profileClasses.avatar} />;
    } else {
      res = (
        <Avatar className={profileClasses.avatar}>
          {user?.firstName[0]}
          {user?.lastName?.[0]}
        </Avatar>
      );
    }
    //icon-button-file is what allows the avatar to browse
    //their computer for a profile picture
    return (
      <Box>
        <input
          accept="image/*"
          id="icon-button-file"
          type="file"
          className={classes.displayNone}
          onChange={saveImage}
        />
        <label htmlFor="icon-button-file">
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="span"
          >
            <Badge
              overlap="circle"
              color="primary"
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              badgeContent={<Edit />}
            >
              {res}
            </Badge>
          </IconButton>
        </label>
      </Box>
    );
  };

  //Sends dispatch for editing a user's information
  const updateProfile = () => {
    dispatch<any>(
      editUser({
        id: user?.id || '',
        firstName: firstName,
        lastName: lastName,
        profilePic: profilePic,
        email: email,
        username: user?.username || '',
      })
    );
    dispatch(success('Successfully edited profile'));
  };

  const resetProfile = (user: IUSERDATA) => {
    setFirstName(user.firstName || '');
    setLastName(user.lastName || '');
    setUsername(user.username || '');
    setEmail(user.email || '');
    setProfilePic(user.profilePic || null);
    dispatch(success('Successfully reset profile'));
  };

  const changePasswordModalOpen = () => {
    setModalOpen(true);
  };

  const handleCloseChangePass = () => {
    setModalOpen(false);
  };

  //Onchange function for uploading profile pic, saves to profilePic useState
  const saveImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setProfilePic(e.target.files[0]);
      if (user?.profilePic) user.profilePic = profilePic;
    } else {
      setProfilePic(null);
    }
  };

  const onSubmit = async (e: any) => {
    e.preventDefault();
  };

  // from DoublePassword, sets whether or not new password is valid
  const handlePasswordResponse = (response: boolean) => {
    setPasswordIsValid(response);
  };

  // from DoublePassword, updates new password variable
  const sendPassword = (password: string) => {
    setNewPassword(password);
  };

  // changes user's password
  const onSubmitChangePassword = () => {
    setIsSucess(false);
    console.log('oldPwd: ' + currentPassword);
    console.log('newPwd: ' + newPassword);
    // only change password if new password complies
    if (passwordIsValid) {
      dispatch(changePassword(currentPassword, newPassword, setIsSucess));
    } else {
      setShowPopup(true);
    }

    // if successful, updates current password
    if (isSuccess) {
      setCurrentPassword(newPassword);
    } else {
      setShowPopup(true);
    }
  };

  // closes popup
  const handleClosePopup = () => {
    if (isSuccess) {
      setModalOpen(false);
    }
    setShowPopup(false);
  };

  // Popup modal displaying whether or not password was successfully updated
  const popupModal = () => {
    return (
      <Dialog open={showPopup} onClose={handleClosePopup}>
        <DialogTitle disableTypography className={classes.dialogTitle}>
          {isSuccess ? (
            <h3>Successfully Changed Password</h3>
          ) : (
            <h3>Error Changing Password</h3>
          )}
          <IconButton onClick={handleClosePopup}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {isSuccess ? (
            <div>Password successfully updated.</div>
          ) : (
            <div>Password not succesfully updated.</div>
          )}
          {!passwordIsValid ? (
            <div>New passwords must comply with rules</div>
          ) : (
            <div></div>
          )}
        </DialogContent>
      </Dialog>
    );
  };

  // Change password modal
  const changePasswordModal = () => {
    return (
      <Box className={classes.modalBox}>
        <DialogTitle disableTypography className={classes.dialogTitle}>
          <h2 style={{ marginRight: '8rem' }}>Change Password</h2>
          <IconButton onClick={handleCloseChangePass}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <TextField
          margin="normal"
          color="secondary"
          type={'password'}
          id="currPassword"
          label="Current Password"
          name="currPassword"
          autoComplete="current password"
          style={{ width: '83%' }}
          required
          value={currentPassword}
          onChange={(e) => setCurrentPassword(e.target.value)}
          autoFocus
        />
        <DoublePassword
          handleResponse={handlePasswordResponse}
          handlePassword={sendPassword}
        />
        <Button
          type="submit"
          variant="contained"
          size="medium"
          color="primary"
          className={classes.submit}
          onClick={onSubmitChangePassword}
        >
          Change Password
        </Button>
      </Box>
    );
  };

  return user ? (
    <Box maxWidth="lg">
      <h3>Edit Profile</h3>
      <Typography component="h3" variant="h4" gutterBottom>
        <form className={classes.form} onSubmit={onSubmit}>
          <Box className={classes.avatarBox}>
            {user && user.profilePic !== undefined ? returnAvatar() : null}
          </Box>
          <TextField
            margin="normal"
            color="secondary"
            fullWidth
            id="firstname"
            label="First Name"
            name="firstname"
            autoComplete="given-name"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
          <TextField
            margin="normal"
            color="secondary"
            fullWidth
            id="lastname"
            label="Last Name"
            name="lastname"
            autoComplete="family-name"
            value={lastName == null ? ' ' : lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
          <TextField
            margin="normal"
            color="secondary"
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Button
            size="large"
            color="primary"
            onClick={changePasswordModalOpen}
            className={classes.passwordButton}
          >
            Change Password
          </Button>
          <Modal
            open={modalOpen}
            onClose={handleCloseChangePass}
            color="secondary"
          >
            {changePasswordModal()}
          </Modal>
          {popupModal()}
          <div
            style={{ display: 'flex', gap: '1em', justifyContent: 'center' }}
          >
            <Button
              type="submit"
              variant="contained"
              size="large"
              className={classes.reset}
              disabled={
                firstName === user.firstName &&
                lastName === user.lastName &&
                username === user.username &&
                email === user.email &&
                profilePic === user.profilePic
              }
              onClick={() => resetProfile(user)}
            >
              Reset
            </Button>
            <Button
              type="submit"
              variant="contained"
              size="large"
              color="primary"
              className={classes.submit}
              onClick={updateProfile}
            >
              Save
            </Button>
          </div>
        </form>
      </Typography>
    </Box>
  ) : (
    <CircularProgress />
  );
};

export default EditProfile;
