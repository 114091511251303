import { authAxios } from './serviceUtils';
import { backendGitURL, GitResponse } from './api';

//Get git information about the backend

// FIXLATER
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const getBackendGitInfo = async () => {
  const response = (await authAxios.get(backendGitURL).catch((err) => {
    console.log(err);
    return err.response;
  })) as GitResponse;
  return response.data;
};

const gitInfoService = { getBackendGitInfo };
export default gitInfoService;
