// Component to display ZUZ Listings associated with an issuer. This component is rendered at the bottom of the Issuer page.
import React from 'react';
import {
  createStyles,
  makeStyles,
  Theme,
  Grid,
  Card,
  CardContent,
  Typography,
} from '@material-ui/core';
import { IBOLTDATA } from '../../utils/types';
import Title from '../common/Title';
import BoltLogo from '../common/BoltLogo';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: 8,
      border: '2px solid ' + theme.palette.primary.light,
      boxShadow: '0px 10px 10px #CCCCCC',
      backgroundColor: theme.palette.background.default,
      transition: 'background-color .4s, box-shadow .4s',
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: theme.palette.background.paper,
        boxShadow: '0px 4px 4px #CCCCCC',
      },
    },
    boltCard: {
      display: 'flex',
      maxWidth: '100%',
      alignItems: 'center',
    },
    boltCardInfo: {
      display: 'inline-block',
      marginLeft: 12,
      maxWidth: '100%',
    },
    overflowText: {
      maxWidth: '100%',
    },
  })
);

type ZUZListingsProps = {
  bolts: IBOLTDATA[];
};

// FIXLATER
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function ZUZListings(props: ZUZListingsProps) {
  const classes = useStyles();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Title>We Accept</Title>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          {props.bolts.map((bolt) => (
            <Grid item key={bolt.specID}>
              <Card className={classes.root}>
                <CardContent className={classes.boltCard}>
                  <BoltLogo specID={bolt.specID} />
                  <div className={classes.boltCardInfo}>
                    <Typography
                      variant="body1"
                      noWrap
                      className={classes.overflowText}
                    >
                      <b>{bolt.specName}</b>
                    </Typography>
                  </div>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default ZUZListings;
