/* Component to reset passwords - when users click on the reset link in their emails after initiating the forgot password process,
 * they will be redirected here. Users are required to type their new password twice. The username and token from the link are used to make
 * the reset password request.
 */
import React, { useState, useEffect } from 'react';
import { Container, Typography, CssBaseline, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { resetPassword } from '../../redux/actions/usersAction';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import DoublePassword from './DoublePassword';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
  link: {
    color: theme.palette.secondary.main,
    fontSize: 22,
    textDecoration: 'none',
  },
  checkIcon: {
    color: theme.palette.secondary.main,
  },
  timesIcon: {
    color: theme.palette.error.main,
  },
}));

// FIXLATER
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function ResetPassword(props: RouteComponentProps<any>) {
  const classes = useStyles();
  const dispatch = useDispatch();

  //parse username and token from url
  const username = props.match.params
    ? props.match.params.username
    : 'invalid username';
  const queryParams = props.location.search.split('=');
  const token = queryParams.length > 1 ? queryParams[1] : 'invalid token';

  const [password, setPassword] = useState<string>('');
  const [passwordIsValid, setPasswordIsValid] = useState(false);
  const [isSuccess, setIsSucess] = useState<null | boolean>(null);

  // callback function to handle changing password in DoublePassword
  const sendPassword = (password: string) => {
    setPassword(password);
    console.log(password);
  };

  // callback function to check whether the password is valid
  const handlePasswordResponse = (response: boolean) => {
    console.log(response);
    setPasswordIsValid(response);
  };

  useEffect(() => {
    if (isSuccess == null) {
      setPassword('');
    }
  }, [isSuccess]);

  const onSubmit = () => {
    setIsSucess(null);
    dispatch(resetPassword(username, token, password, setIsSucess));
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      {isSuccess ? (
        <div className={classes.paper}>
          <Typography component="h3" variant="h4">
            Successs! Your password has been reset.
          </Typography>
          <br />
          <Link to="/login" className={classes.link}>
            Return to login page
          </Link>
        </div>
      ) : (
        <div className={classes.paper}>
          <Typography component="h3" variant="h4">
            Create a New Password
          </Typography>
          <br />
          <DoublePassword
            handleResponse={handlePasswordResponse}
            handlePassword={sendPassword}
          />
          <br />
          <Button
            type="submit"
            variant="contained"
            size="large"
            color="primary"
            onClick={onSubmit}
            disabled={!passwordIsValid}
          >
            Submit
          </Button>
        </div>
      )}
    </Container>
  );
}

export default ResetPassword;
