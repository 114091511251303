import userSearchService from '../../services/userSearchService';
import {
  editUserService,
  delegateService,
  forgotPasswordService,
  resetPasswordService,
  changePasswordService,
} from '../../services/userService';
import { Dispatch } from 'redux';
import { error, success } from './alertAction';
import { UsersActionsTypes } from './actionTypes';
import { IUSERDATA, USERUUID } from '../../utils/types';
import { MintPermissions, SellPermissions } from '../reducers/authReducer';
import { userSearchById } from '../../services/userSearchStoreService';
import { IAppState } from '../reducers/reducer';

//Contains service call and error handling for getting users
export const getUsers = () => {
  // FIXLATER
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return (dispatch: Dispatch) => {
    return userSearchService
      .userSearchService('')
      .then((res: any) => {
        if (res.status === 'error') {
          dispatch(error(res.msg));
        } else {
          dispatch<any>(getUsersAction(res.users));
        }
      })
      .catch((err: any) => {
        console.log(err);
        dispatch(error('Error getting the users'));
      });
  };
};

//Action for getting users
// FIXLATER
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getUsersAction = (users: Array<IUSERDATA>) => {
  return {
    type: UsersActionsTypes.GET_USERS,
    payload: { users },
  };
};

//Contains service call and error handling for getting a single user by its id
//It will do nothing if the user is already stored in the state
export const getSingleUser = (userId: string) => {
  // FIXLATER
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return (dispatch: Dispatch, getState: () => IAppState) => {
    //console.log(`getSingleUser: ${userId}`);
    if (userId in getState().users) {
      //console.log(`already in state`);
      return;
    }
    //console.log(`using search: ${userId}`);
    return userSearchById(userId)
      .then((res) => {
        // temporal check if res is undefined. See userSearchStoreService.ts
        if (res === undefined) {
          return;
        }
        if (res.status === 'error') {
          dispatch(error(res.msg));
        } else {
          dispatch<any>(getSingleUserAction(res.user));
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(error('Error getting a single user with id: ' + userId));
      });
  };
};

//Action for getting a single user
// FIXLATER
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
// FIXLATER
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getSingleUserAction = (user: any) => {
  return {
    type: UsersActionsTypes.GET_USERS,
    payload: { users: Array(user) },
  };
};

//Contains service call for editing user
export const editUser = ({
  id,
  firstName,
  lastName,
  email,
  profilePic,
  username,
}: IUSERDATA) => {
  // FIXLATER
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return (dispatch: Dispatch) => {
    editUserService({ id, firstName, lastName, email, profilePic, username })
      .then((res: any) => {
        if (res.status === 'error') {
          dispatch(error(res.msg));
        } else {
          console.log(res);
          const { id, firstName, lastName, email, profilePic } = res.user; //replace parameters with updated version from response
          dispatch(
            editUserAction({
              id,
              firstName,
              lastName,
              email,
              profilePic,
              username,
            })
          );
        }
      })
      .catch((err: Error) => {
        console.log(err);
        dispatch(error('Error with editing user'));
      });
  };
};

//Action for editing user
// FIXLATER
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const editUserAction = ({
  id,
  firstName,
  lastName,
  email,
  profilePic,
  username,
}: IUSERDATA) => {
  const users: IUSERDATA = {
    id,
    firstName,
    lastName,
    email,
    profilePic,
    username,
  };
  return {
    type: UsersActionsTypes.EDIT_USER,
    payload: { users: [users] }, //Because of the original way the reducer's payload is defined, this is the way it has to be packed
  };
};

// FIXLATER
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const delegatePermissions = (
  delegate: USERUUID,
  mint: MintPermissions,
  sell: SellPermissions,
  accept: boolean
) => {
  console.log('mint: ' + mint);
  return (dispatch: Dispatch) => {
    delegateService(delegate, mint, sell, accept)
      .then((res: any) => {
        console.log('res: ' + res);
        if (res.status === 'error') {
          dispatch(error(res.msg));
        } else {
          dispatch(success('Successfully delegated permissions'));
          // no action required if delegation is successful
        }
      })
      .catch((err: any) => {
        console.log(err);
        dispatch(error('AJAX error while delegating permissions'));
      });
  };
};

//Initiates forgot password process
export const forgotPassword = (
  username: string,
  setIsSuccess: (state: boolean | null) => void
) => {
  // FIXLATER
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return (dispatch: Dispatch) => {
    return forgotPasswordService(username)
      .then((res: any) => {
        if (res.status === 'error') {
          setIsSuccess(false);
          dispatch(error(res.msg));
        } else {
          setIsSuccess(true);
        }
      })
      .catch((err: any) => {
        console.log(err);
        setIsSuccess(false);
        dispatch(error('Error finding your account'));
      });
  };
};

//Makes service call to reset password
export const resetPassword = (
  username: string,
  token: string,
  password: string,
  setIsSuccess: (state: boolean | null) => void
) => {
  // FIXLATER
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return (dispatch: Dispatch) => {
    return resetPasswordService(username, token, password)
      .then((res: any) => {
        if (res.status === 'error') {
          setIsSuccess(false);
          dispatch(error(res.msg));
        } else {
          setIsSuccess(true);
        }
      })
      .catch((err: any) => {
        console.log(err);
        setIsSuccess(false);
        dispatch(error('Error resetting your password'));
      });
  };
};

export const changePassword = (
  currentPassword: string,
  newPassword: string,
  setIsSuccess: (state: boolean | null) => void
) => {
  // FIXLATER
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return (dispatch: Dispatch) => {
    return changePasswordService(currentPassword, newPassword)
      .then((res: any) => {
        if (res >= 400) {
          setIsSuccess(false);
          dispatch(error('Error changing password'));
        } else {
          setIsSuccess(true);
          dispatch(success('Successfully changed password'));
        }
      })
      .catch((err: any) => {
        console.log(err);
        setIsSuccess(false);
        dispatch(error('Error changing your password'));
      });
  };
};
