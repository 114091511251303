//Modal shown when donate button is pressed. When user enters amount to be donated the donate API is called.
import React, { useState, ChangeEvent } from 'react';
import {
  makeStyles,
  Theme,
  createStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { donateBolt } from '../../redux/actions/boltAction';
import ActionButton from '../common/ActionButton';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    boltViewPaper: {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.secondary.main,
      minHeight: '500px',
      minWidth: '400px',
      alignItems: 'center',
      padding: 10,
    },
    boltViewContent: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
    },
    buttonSection: {
      display: 'flex',
      alignItems: 'center',
      position: 'absolute',
      justifyContent: 'center',
      bottom: 0,
      paddingBottom: 20,
      width: '100%',
    },
    actionButton: {
      margin: 5,
      padding: '10px 15px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      transition: 'background-color 0.5s',
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: theme.palette.grey[200],
      },
    },
    textFieldStyle: {
      width: 300,
      display: 'block',
    },
  })
);

//Type of the props
type DONATEBOLTS = {
  setDialogOpen: (isOpen: boolean) => void;
  handleBack: () => void;
  specID: string;
};

// FIXLATER
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function DonateBolt(props: DONATEBOLTS) {
  const classes = useStyles();
  const [amount, setAmount] = useState('');
  const [amountValid, setAmountValid] = useState(true);
  const setDialogOpen = props.setDialogOpen;
  const handleBack = props.handleBack;
  const specID = props.specID;
  const dispatch = useDispatch();

  //Close the dialog
  const handleClose = () => {
    setDialogOpen(false);
  };

  //Handles input amount
  const handleAmountChange = (e: ChangeEvent<HTMLInputElement>) => {
    setAmount(e.target.value);
    if (isNaN(Number(e.target.value))) {
      setAmountValid(false);
    } else {
      setAmountValid(true);
    }
  };

  // Handles the submit button and calls the service to donate bolt
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    dispatch(donateBolt(specID, Number(amount)));
    handleClose();
  };

  return (
    <Dialog
      classes={{
        paper: classes.boltViewPaper,
      }}
      open={true}
      onClose={handleClose}
      maxWidth={'md'}
    >
      <DialogTitle id="form-dialog-title">Donate</DialogTitle>
      <DialogContent className={classes.boltViewContent}>
        <form onSubmit={handleSubmit}>
          <TextField
            className={classes.textFieldStyle}
            required
            variant="outlined"
            color="secondary"
            margin="normal"
            fullWidth
            id="amount"
            label="Amount"
            name="amount"
            autoFocus
            value={amount}
            onChange={handleAmountChange}
            error={!amountValid}
            helperText={amountValid ? '' : 'Spec ID cannot contain spaces'}
          />
        </form>
      </DialogContent>
      <ActionButton
        handleCallback1={handleBack}
        handleCallback2={handleSubmit}
        name1="Back"
        name2="Submit"
        disabled2={!amountValid}
      />
    </Dialog>
  );
}

export default DonateBolt;
