import { Button, CssBaseline, makeStyles } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 'auto',
    position: 'absolute',
  },
  snackbar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.secondary.main,
  },
}));

// FIXLATER
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function Confirmation() {
  const classes = useStyles();
  const location = useLocation();

  const verified = new URLSearchParams(location.search).get('verified');
  const ifVerified = verified === 'true';

  return (
    <div className={classes.root}>
      <CssBaseline />
      {ifVerified && (
        <div>
          <div>Thank you. Your email is now verfied.</div>
          <Button>
            <Link to="/login">Log in</Link>
          </Button>
        </div>
      )}
      {!ifVerified && (
        <div>
          <div>
            Something went wrong when confirming your email. Please try again.
          </div>
          <Button>
            <Link to="/login">Log in</Link>
          </Button>
        </div>
      )}
    </div>
  );
}

export default Confirmation;
