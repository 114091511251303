import React, { createContext } from 'react';
import io from 'socket.io-client';
import { server } from '../services/api';
import { useDispatch } from 'react-redux';
import {
  receiveTxnAction,
  updateTxnAction,
  acceptRejectTxnAction,
} from '../redux/actions/txnAction';
import { setOpen } from '../redux/actions/qrAction';
import store from '../redux';
import { logerrBackend } from '../utils/logger';

const WebSocketContext = createContext({});

export { WebSocketContext };

function downcode() {
  let x = Date.now();
  const result = [];
  while (x > 0 && result.length < 5) {
    const val = x % 26;
    result.push(String.fromCharCode(65 + val));
    const y = (x - val) / 26;
    x = y;
  }
  return result.join('');
}

function upcode() {
  let x = Math.random();
  const result = [];
  while (x > 0 && result.length < 10) {
    x = x * 26;
    const val = Math.floor(x);
    x = x - val;
    result.push(String.fromCharCode(65 + val));
  }
  return result.join('');
}

export const QrUniqId = `${downcode()}-${upcode()}`;

console.log(`QR IS: ${QrUniqId}`);

const versionString = process.env.REACT_APP_VERSION;

if (versionString === undefined)
  alert('No version defined. Check environment.');

const version = parseInt(versionString as string);

function checkTypeActing(msg: string, actingID: any) {
  console.log(`CTA: ${msg} ${actingID} ${typeof actingID}`);
  if (typeof actingID !== 'string') {
    logerrBackend(
      `actingID is not a string! ${msg} ${actingID} ${typeof actingID}`,
      false
    );
  }
}

// FIXLATER
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const WebSocketProvider = ({ children }: any) => {
  let startingActingId = undefined;
  if (store !== undefined) {
    const state = store.getState();
    const euser = state.authState?.effectiveUser;
    if (euser !== undefined) {
      startingActingId = euser.actingID.toString();
    }
  }
  console.log(`WSP: started with ${startingActingId}`);

  let socket: SocketIOClient.Socket = undefined as any;
  let token;
  // on load, if it is a reload of an already authenticated user,
  // then actingID will be defined in the store, so set it up so the
  // user reconnects.  Not sure what this will do if the token is
  // expired.  SO, we need to investigate that.
  let actingID: string | undefined = startingActingId;

  const dispatch = useDispatch();

  if (!socket) {
    // create single Socket instance per page load
    socket = io.connect(server);
    console.log(`socket created: ${server}`);
    // attach event listeners
    socket
      .on('connect', () => {
        if (actingID !== undefined) checkTypeActing('on connect', actingID);
        // retry authentication on every (re)connection
        token = localStorage.getItem('token');
        if (token && actingID !== undefined) {
          // please put in userid that we are currently acting as here, e.g., redux-state/authState/effectiveUser/actingID
          checkTypeActing('authenticate', actingID);
          socket.emit('authenticate', {
            token: token,
            actingAs: actingID,
            qrcode: QrUniqId,
          });
        }
      })
      .on('authenticated', () => {
        console.log('socket authenticated');
        checkTypeActing('AUTHENTICATED', actingID);
        socket.emit('whoiam', { actingAs: actingID, qrcode: QrUniqId });
      })
      .on('unauthorized', (/*msg: any*/) => {
        console.log('could not connect socket');
      })
      .on('transactionInitiated', (msg: any) => {
        console.log(`initiated: ${JSON.stringify(msg)}`);
        console.log(msg);
        dispatch(receiveTxnAction(msg.socketResp, false));
      })
      .on('transactionCompleted', (msg: any) => {
        console.log(`completed: ${JSON.stringify(msg)}`);
        console.log(msg);
        console.log('dispatching receiveTxnAction');
        // see if this is first time user is getting this kind of bolt
        const state = store.getState();
        const firstTime = !(
          'userBolts' in state && msg.socketResp.bolt in state.userBolts
        );
        if (firstTime) console.log(`First time getting ${msg.socketResp.bolt}`);
        dispatch(receiveTxnAction(msg.socketResp, firstTime));
        checkTypeActing('transcation completed', actingID);
        console.log(
          `transactionCompleted: actingId=${actingID}, ${typeof actingID} receiver=${
            msg.socketResp.receiver
          }, ${typeof msg.socketResp.receiver}`
        );
        if (msg.socketResp.receiver !== actingID) {
          logerrBackend(
            `transactionCompleted: actingId=${actingID} ${typeof actingID} receiver=${
              msg.socketResp.receiver
            }`,
            false
          );
        } else {
          console.log('dispatching: acceptRejectTxnAction');
          dispatch(acceptRejectTxnAction(msg.socketResp, firstTime));
        }
        if (
          'qrCreator' in msg &&
          msg.qrCreator !== '' &&
          msg.qrCreator !== QrUniqId
        )
          console.log(`Bad qrcreator code: ${msg.qrCreator} != ${QrUniqId}`);
        dispatch(setOpen(false));
      })
      .on('activityHappened', (msg: any) => {
        console.log('activity for a different profile than I am in now');
        console.log(msg);
        // create and dispatch an event that will cause an indicator
        //to appear next to the 'activityFor' userid - which should
        //be one of the profiles for this user.
        //dispatch(receiveTxnAction(msg.socketResp));
      })
      .on('transactionUpdated', (msg: any) => {
        console.log(`updated ${JSON.stringify(msg)}`);
        console.log(msg);
        dispatch(updateTxnAction(msg));
      })
      .on('closeqr', (msg: any) => {
        console.log(`closeqr: ${msg}`);
        dispatch(setOpen(false));
      })
      .on('notification', (msg: any) => {
        console.log(msg);
      })
      .on('reload', (msg: any) => {
        console.log(msg);
        if (msg.version > version) {
          alert('An update is needed.  You might need to re-login.');
          window.location.reload();
        }
      });
    // auto-reconnects on disconnect unless forced by server disconnect()
  }

  // retry connect without leaking sockets
  const connect = (token: string, newActingID: string) => {
    console.log(
      `Connecting to socket as ${newActingID} (was ${actingID}) with ${token} when ${socket.connected}`
    );
    //console.log(Error().stack);
    actingID = newActingID;
    checkTypeActing('retry connect', actingID);
    if (!socket.connected) {
      // 'connect' event listener triggers authentication
      socket.connect();
    } else {
      // temporary fix: restarts connection with new authentication token
      socket.disconnect();
      socket.connect();
    }
  };

  const ws = {
    socket: socket,
    connect: connect,
  };

  return (
    <WebSocketContext.Provider value={ws}>{children}</WebSocketContext.Provider>
  );
};

export type WS = {
  socket: SocketIOClient.Socket;
  connect: (token: string, uid: string) => void;
  qrcode: string;
};

export default WebSocketProvider;
