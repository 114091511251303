import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { useDispatch, useSelector } from 'react-redux';
import { IAppState } from '../../redux/reducers/reducer';
import { logoutAllAction } from '../../redux/actions/authAction';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import { closeModal } from '../../redux/actions/modalAction';

// modal to signal expired authorization token and prompt re-login
// FIXLATER
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const ExpiredSessionModal = () => {
  const open = useSelector((state: IAppState) => state.modalState.open);

  const [count, setCount] = useState(10);
  // decrement count or trigger logout when done
  const countdown = (tick: number) => () => {
    if (tick === 0) {
      handleClose();
    } else {
      setCount(tick - 1);
    }
  };
  // decrement count each second
  useEffect(() => {
    if (open) {
      setTimeout(countdown(count), 1000);
    }
  });
  // start countdown
  useEffect(() => {
    if (open) {
      setTimeout(countdown(10), 1000);
    }
  });

  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(closeModal());
    // local-only logout (no API call)
    dispatch(logoutAllAction());
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Your session has expired due to inactivity.</DialogTitle>
      <DialogContent>
        <p>
          You will be automatically redirected to the login screen in {count}{' '}
          seconds.
        </p>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Back to Login
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ExpiredSessionModal;
