import React from 'react';
import { Box, Typography } from '@material-ui/core';

// FIXLATER
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function Preferences() {
  return (
    <Box maxWidth="lg" style={{ marginTop: '40px' }}>
      <Typography component="h3" variant="h4" gutterBottom>
        Preferences
      </Typography>
    </Box>
  );
}

export default Preferences;
