import { authAxios } from './serviceUtils';
import { APIError } from './api';

type MakeListApi = (ids: Array<string>) => string;

type GetIdFunction<T> = (record: T) => string;

// Assumption is getone and getlist both return result under 'result'.
// getone returns the object of type T and getlist an Array<T>.
// if there is a FE error, then returns {'status': error, 'msg': message}

function mylog(x: any): void {
  if (false) console.log(x);
}

export class Cache<T> {
  cache: { [id: string]: T };
  getone: string; // API path to get one by id, concat id to end of string to form final API call
  getlist: MakeListApi; // function to turn list of ids into API call
  hours: number; // number of hours between clearing cache
  name: string; // name of this cache for debugging
  nextclear: number; // number of hours til next clearing
  getID: GetIdFunction<T>; // function to get id of a record as a string
  empty: boolean;

  // list of all cache's being used
  static all: Cache<any>[] = [];

  // function to keep track of call caches and clear when required
  static clearCache(): void {
    for (const c of Cache.all) {
      c.nextclear--;
      if (c.nextclear <= 0) {
        c.clear();
        c.nextclear = c.hours;
      }
    }
  }

  // function to force clearing of all caches
  static forceClearAll(): void {
    for (const c of Cache.all) {
      c.clear();
    }
  }

  constructor(
    name: string,
    hours: number,
    apione: string,
    apilist: MakeListApi,
    getidfunc: GetIdFunction<T>
  ) {
    this.cache = {};
    this.name = name;
    this.hours = hours;
    this.nextclear = hours;
    this.getone = apione;
    this.getlist = apilist;
    this.getID = getidfunc;
    this.empty = true;
    Cache.all.push(this);
  }

  clear(): void {
    this.cache = {};
    this.empty = true;
  }

  // get one record for 'id'
  // on error, throw an APIError which has the response in it
  async get(id: string): Promise<T> {
    mylog(
      `1: ${id} ${typeof id} in ${this.name} against ${Object.keys(this.cache)}`
    );
    // check if in cache
    if (id in this.cache) {
      mylog(`Returning: ${JSON.stringify(this.cache[id])}`);
      return this.cache[id];
    }
    // not in cache, so fetch it
    const response = await authAxios.get(this.getone + id).catch((err) => {
      console.log(err);
      throw new APIError(err.response);
    });
    const message = response.data;
    console.log(`getting ${id} singleton from ${this.name}`);
    mylog(message);
    // Not sure if we have to test status here
    if (message.status != 'success') {
      alert(
        `none success on getone API call: ${this.name}, ${id}.  Let seth know`
      );
      throw new APIError(message.msg);
    }
    // success, cache the entire response
    this.cache[id] = message.result as T;
    this.empty = false;
    return message.result as T;
  }

  // get all the ids.  Will return as many as possible from local cache
  // on error, throw an APIError which has the response in it
  async getList(ids: string[]): Promise<Array<T>> {
    mylog(`${this.name} fetch list: ${JSON.stringify(ids)}`);
    const fetchids: string[] = [];

    for (const id of ids) {
      if (false) {
        console.log(
          `checking ${id} ${typeof id} in ${this.name} against ${Object.keys(
            this.cache
          )}`
        );
      }
      if (id in this.cache) continue;
      fetchids.push(id);
    }
    // fetch any that need to be fetched and put them in the cache.
    // ASSUME BACKEND RETURNS THE RESULTS IN THE SAME ORDER AS THE IDS
    // THAT WERE SPECIFIED.
    if (fetchids.length > 0) {
      const response = await authAxios
        .get(this.getlist(fetchids))
        .catch((err) => {
          console.log(`Failed to get list from ${this.name}`);
          console.log(err);
          throw new APIError(err.response);
        });
      const message = response.data;
      mylog(`response from getting ${ids} list from ${this.name}`);
      mylog(message);
      // Not sure if we have to test status here
      if (message.status != 'success') {
        alert(
          `none success on list API call: ${this.name}, ${fetchids}.  Let seth know`
        );
        throw new APIError(message.msg);
      }
      this.install(message.result);
    }
    // now get entire list from cache
    const result: Array<T> = [];
    for (const id of ids) {
      //console.log(`Checking ${id} ${typeof id}`);
      result.push(this.cache[id]);
    }
    mylog(`Returning from ${this.name}: ${JSON.stringify(result)}`);
    return result;
  }

  // install this array of records into the cache.  Assumption is every record has an 'id' field
  install(data: Array<T>): void {
    if (data.constructor !== Array) data = [data] as unknown as any;
    for (const record of data) {
      const id: string = this.getID(record);
      mylog(
        `installing ${id} ${typeof id} in ${this.name} with ${JSON.stringify(
          record
        )}`
      );
      this.cache[id] = record;
      this.empty = false;
    }
  }

  // return if cache is empty
  isEmpty(): boolean {
    return this.empty;
  }
}

setInterval(Cache.clearCache, 60 * 1000 * 60);

(<any>window).gcache = Cache;
