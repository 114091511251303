import React from 'react';
import {
  makeStyles,
  Theme,
  createStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  CircularProgress,
} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    boltViewPaper: {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.secondary.main,
      alignItems: 'center',
      padding: 10,
    },
  })
);

type WaitDialogProps = {
  title: string;
  description: string;
};

// FIXLATER
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function WaitDialog(props: WaitDialogProps) {
  const classes = useStyles();

  return (
    <Dialog
      classes={{
        paper: classes.boltViewPaper,
      }}
      open={true}
    >
      <DialogTitle id="form-dialog-title">{props.title}</DialogTitle>
      <DialogContent style={{ textAlign: 'center' }}>
        <DialogContentText id="alert-dialog-description">
          {props.description}
        </DialogContentText>
        <CircularProgress />
      </DialogContent>
    </Dialog>
  );
}

export default WaitDialog;
