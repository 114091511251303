import React from 'react';
import { makeStyles } from '@material-ui/core';
import {
  Link,
  BrowserRouter as Router,
  Route,
  Switch,
  useRouteMatch,
  Redirect,
} from 'react-router-dom';
import { MenuList, MenuItem, Grid, Select, Box } from '@material-ui/core';
import Title from '../common/Title';
import { useSelector } from 'react-redux';
import { selectIsDelegate, selectUserRole } from '../../redux/selectors';
import Preferences from './Preferences';
import DelegatedPermissions from './DelegatedPermissions';
import ManageAccounts from './ManageAccounts';
import EditProfile from '../profile/EditProfile';
import PointOfSale from './PointOfSale';

const useStyles = makeStyles((theme) => ({
  boundedContainer: {
    padding: theme.spacing(2),
  },
}));

// FIXLATER
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function Settings() {
  const classes = useStyles();

  const isDelegate = useSelector(selectIsDelegate);
  const isMerchant = useSelector(selectUserRole);

  const { path, url } = useRouteMatch();

  const Values: Record<string, string> = {
    editprofile: 'Edit Profile',
    preferences: 'Preferences',
    delegate: 'Delegations',
    'manage-accounts': 'Manage Accounts',
  };

  const baseName = window.location.pathname.replace(/\//g, '');

  // If settings menu not selected, redirect to default menu "Edit Profile"
  if (baseName === 'settings') return <Redirect to="/settings/editprofile" />;

  return (
    <Router>
      <Title>Settings</Title>
      <Grid container spacing={1} justify="center">
        <Grid component={Box} md={4} item display={{ xs: 'none', md: 'block' }}>
          <div className={classes.boundedContainer}>
            <MenuList>
              <MenuItem component={Link} to={`${url}/editprofile`}>
                Profile
              </MenuItem>
              <MenuItem component={Link} to={`${url}/preferences`}>
                Preferences
              </MenuItem>
              {/* delegates cannot delegate permissions */}
              {!isDelegate && (
                <MenuItem component={Link} to={`${url}/delegate`}>
                  Delegations
                </MenuItem>
              )}
              <MenuItem component={Link} to={`${url}/manage-accounts`}>
                Manage Accounts
              </MenuItem>
              {/* Only merchants can configure point of sale */}
              {isMerchant && (
                <MenuItem component={Link} to={`${url}/point-of-sale`}>
                  Point of Sale
                </MenuItem>
              )}
            </MenuList>
          </div>
        </Grid>
        <Grid
          component={Box}
          xs={12}
          item
          display={{ xs: 'block', md: 'none' }}
        >
          {/* Make select component controlled via setting menu in url */}
          <Select fullWidth defaultValue={Values[baseName]}>
            <MenuItem value={'Edit Profile'}>
              <Link
                to={`${url}/editprofile`}
                style={{
                  textDecoration: 'none',
                  color: 'black',
                  width: '100%',
                }}
              >
                Edit Profile
              </Link>
            </MenuItem>
            <MenuItem value={'Preferences'}>
              <Link
                to={`${url}/preferences`}
                style={{
                  textDecoration: 'none',
                  color: 'black',
                  width: '100%',
                }}
              >
                Preferences
              </Link>
            </MenuItem>
            {/* delegates cannot delegate permissions */}
            {!isDelegate && (
              <MenuItem value={'Delegations'}>
                <Link
                  to={`${url}/delegate`}
                  style={{
                    textDecoration: 'none',
                    color: 'black',
                    width: '100%',
                  }}
                >
                  Delegations
                </Link>
              </MenuItem>
            )}
            <MenuItem value={'Manage Accounts'}>
              <Link
                to={`${url}/manage-accounts`}
                style={{
                  textDecoration: 'none',
                  color: 'black',
                  width: '100%',
                }}
              >
                Manage Accounts
              </Link>
            </MenuItem>
            {/* Only merchants can configure point of sale */}
            {isMerchant && (
              <MenuItem value={'Point of Sale'}>
                <Link
                  to={`${url}/point-of-sale`}
                  style={{ textDecoration: 'none', color: 'black' }}
                >
                  Point of Sale
                </Link>
              </MenuItem>
            )}
          </Select>
        </Grid>
        <Grid item xs={12} md={8}>
          <Switch>
            <Route path={`${path}/editprofile`} component={EditProfile} />
            <Route path={`${path}/delegate`} component={DelegatedPermissions} />
            <Route path={`${path}/preferences`} component={Preferences} />
            <Route
              path={`${path}/manage-accounts`}
              component={ManageAccounts}
            />
            <Route path={`${path}/point-of-sale`} component={PointOfSale} />
          </Switch>
        </Grid>
      </Grid>
    </Router>
  );
}

export default Settings;
