import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  Link,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { CheckCircle } from '@material-ui/icons';
import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { getFinalizedTransactions, selectActingUser } from '../redux/selectors';
import { asCurrencyString } from '../utils/money';
import { ITXNDATA } from '../utils/types';
import BoltLogo from './common/BoltLogo';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    avatar: {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
      width: 64,
      height: 64,
    },
    check: {
      color: theme.palette.success.main,
      width: 96,
      height: 96,
    },
    boltViewPaper: {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.secondary.main,
      padding: 10,
    },
    boltViewContent: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
    },
    transferSuccessContent: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      color: theme.palette.text.primary,
      gap: '8px',
      margin: '16px',
      textAlign: 'center',
    },
  })
);

type RPMprops = {
  neverBefore: Date;
};

// FIXLATER
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function ReceivePaymentModal(props: RPMprops) {
  const { neverBefore } = props;
  const classes = useStyles();
  const transactions = useSelector(getFinalizedTransactions);
  const [receivedTxns, setReceivedTxns] = useState<ITXNDATA[]>([]);
  const [sentTxn, setSentTxn] = useState<ITXNDATA>();
  const user = useSelector(selectActingUser);

  // Avoid rendering the modal on page load
  const notInitialRender = useRef(false);

  useEffect(() => {
    // Ensuring the transactions are received from redux
    if (!Object.values(transactions).length || !user) {
      return;
    }

    if (!notInitialRender.current) {
      notInitialRender.current = true;
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const latestTransaction = Object.values(transactions)
      .sort(
        (a, b) =>
          new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
      )
      .slice(-1)
      .pop()!;

    // Ignore transaction if it is before 'neverBefore'
    if (new Date(latestTransaction.updatedAt) < neverBefore) {
      console.log('before neverbefore!!!');
      return;
    }

    // Check if this is a sent transaction
    if (latestTransaction.sender.id === user.id) {
      setSentTxn(latestTransaction);
      return;
    }

    // Ignore transaction that is not received
    if (latestTransaction.receiver.id !== user.id) return;

    // Ignore copy of latest transaction
    if (latestTransaction._id === sentTxn?._id) return;

    // this seems redundent??
    // Check if this is a sent transaction
    if (latestTransaction.sender.id === user.id) {
      setSentTxn(latestTransaction);
      return;
    }

    // Update the store of received transactions whenever a new transaction is made
    setReceivedTxns([...receivedTxns, latestTransaction]);
  }, [transactions]);

  // If there is a sent transaction, render the payment confirmation modal
  if (!!sentTxn)
    return (
      <Dialog
        classes={{
          paper: classes.boltViewPaper,
        }}
        open={true}
        onClose={() => setSentTxn(undefined)}
      >
        <DialogContent className={classes.transferSuccessContent}>
          <CheckCircle className={classes.check} />
          <div>
            <Typography display="inline" color="secondary" variant="h6">
              @{user?.username}
            </Typography>
            <Typography variant="h6" display="inline">
              , you paid
            </Typography>
          </div>
          <Typography variant="h5" style={{ margin: 4 }}>
            {asCurrencyString(sentTxn.amount)} {sentTxn.bolt.specName}
          </Typography>
          <div>
            <Typography variant="body2" display="inline">
              <strong>to </strong>
            </Typography>
            <Typography variant="body1" display="inline">
              @{sentTxn.receiver.username}
            </Typography>
          </div>
          <hr style={{ width: '100%' }} />
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              gap: '10px',
            }}
          >
            <Typography>
              <strong>Pay from your wallet</strong>
            </Typography>
            <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
              <BoltLogo specID={sentTxn.bolt.specID} />
              <Typography>{sentTxn.bolt.specName}</Typography>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            type="button"
            variant="contained"
            onClick={() => setSentTxn(undefined)}
          >
            Dismiss
          </Button>
        </DialogActions>
      </Dialog>
    );

  // If there are received transactions, render the modal
  return receivedTxns[0] ? (
    <Dialog
      classes={{
        paper: classes.boltViewPaper,
      }}
      open={true}
    >
      <DialogContent className={classes.transferSuccessContent}>
        <CheckCircle className={classes.check} />
        <div>
          <Typography display="inline" color="secondary" variant="h6">
            @{receivedTxns[0].sender.username}
          </Typography>{' '}
          <Typography variant="h6" display="inline">
            paid
          </Typography>
        </div>
        <Typography variant="h5" style={{ margin: 4 }}>
          {asCurrencyString(receivedTxns[0].amount)}{' '}
          {receivedTxns[0].bolt.specName}
        </Typography>
        {Math.floor(receivedTxns.length / 2) - 1 > 0 && (
          <Link href="/profile">
            <Typography>
              {/* Divide transaction count by two, since the sender and received both make a transaction */}
              and {Math.floor(receivedTxns.length / 2) - 1} more...
            </Typography>
          </Link>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          type="button"
          variant="contained"
          onClick={() => setReceivedTxns([])}
        >
          Dismiss
        </Button>
      </DialogActions>
    </Dialog>
  ) : null;
}

export default ReceivePaymentModal;
