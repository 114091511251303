import React, { useContext, useState } from 'react';
import {
  Container,
  Typography,
  CssBaseline,
  TextField,
  FormControlLabel,
  Checkbox,
  Button,
  Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { loginAction } from '../../redux/actions/authAction';
import { WebSocketContext, WS } from '../../context/WebSocketContext';
import { Link, useParams } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    textAlign: 'center',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    borderRadius: 10,
    textTransform: 'none',
  },
  signUp: {
    padding: 20,
  },
  checkbox: {
    width: '100%',
    paddingTop: 10,
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.secondary.main,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

// FIXLATER
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function Login() {
  const classes = useStyles();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const ws = useContext(WebSocketContext) as WS;

  const dispatch = useDispatch();

  const onSubmit = async (e: any) => {
    e.preventDefault();
    dispatch(loginAction(username, password, rememberMe, ws));
    // history.push('/home');
  };

  // handles the userRole parameter when the url is in the form of "/login/:userRole"
  const userRoleParams = useParams();

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Typography component="h1" variant="h2">
          ZUZ
        </Typography>

        <form className={classes.form} onSubmit={onSubmit}>
          <TextField
            color="secondary"
            margin="normal"
            required
            fullWidth
            id="username"
            label="Username"
            name="username"
            autoComplete="username"
            autoFocus
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <TextField
            color="secondary"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <FormControlLabel
            control={
              <Checkbox
                value="remember"
                checked={rememberMe}
                onChange={(e) => setRememberMe(e.target.checked)}
              />
            }
            label="Remember me"
            className={classes.checkbox}
          />
          <br />
          <Button
            type="submit"
            variant="contained"
            size="large"
            color="primary"
            className={classes.submit}
          >
            Login
          </Button>
          <br />

          <Grid container className={classes.signUp} spacing={2}>
            <Grid item xs={6}>
              <Link to="/forgot-password" className={classes.link}>
                <Typography variant="body2" color="secondary">
                  {'Forgot Password?'}
                </Typography>
                <Typography variant="body2" color="secondary">
                  {'Reset your password here'}
                </Typography>
              </Link>
            </Grid>
            <Grid item xs={6}>
              {/* pass the userRole parameter to the register page */}
              <Link
                to={{ pathname: '/register', state: userRoleParams }}
                className={classes.link}
              >
                <Typography variant="body2" color="secondary">
                  {'New to ZUZ?'}
                </Typography>
                <Typography variant="body2" color="secondary">
                  {'Create an account here'}
                </Typography>{' '}
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
}

export default Login;
