import { Middleware } from 'redux';
import { IAppState } from '../reducers/reducer';
import { isTxnActionType } from '../actions/actionTypeGuards';
import {
  AccountDataActionsTypes,
  AuthActionsTypes,
} from '../actions/actionTypes';
import { IAuthStateData } from '../reducers/authReducer';
import { getTxn, getUserBolts } from '../actions/authAction';
import { updateAuthAxios } from '../../services/serviceUtils';
import { selectIsDelegate } from '../selectors';

/**
 * Passes information about the authenticated user to the reducers as necessary
 */
export const authStateMiddleware: Middleware<any, IAppState> =
  (storeAPI) => (next) => (action) => {
    if (isTxnActionType(action.type)) {
      // txnReducer uses actingID to categorize transactions
      action.payload.actingID =
        storeAPI.getState().authState.effectiveUser?.actingID;
    }
    return next(action);
  };

/**
 * Manages multiple state slices and localstorage for login/logout logic
 */
export const authMiddleware: Middleware<any, IAppState> =
  (storeAPI) => (next) => (action) => {
    const userState = storeAPI.getState().users;
    const localStore = localStorage.getItem('authState');
    const sessionStore = sessionStorage.getItem('authState');

    switch (action.type) {
      // Retrieves the full authState slice from localstorage, if valid
      case AuthActionsTypes.LOAD:
        //if a session exists, load authState from session storage
        if (sessionStore) {
          const loadState: IAuthStateData = JSON.parse(sessionStore);
          if (loadState && loadState.accounts) {
            action.payload = loadState;
          }
        }
        //if no session exists, load authState from local storage
        else if (localStore) {
          const loadState: IAuthStateData = JSON.parse(localStore);
          if (loadState && loadState.accounts) {
            //if last active account was not chosen for "remember me", load an account that was supposed to be remembered
            if (
              !loadState.effectiveUser ||
              !(loadState.effectiveUser.loginID in loadState.accounts)
            ) {
              const newAccountID = Object.keys(loadState.accounts)[0];
              loadState.effectiveUser = {
                loginID: newAccountID,
                actingID: newAccountID,
              };
            }
            if (loadState.effectiveUser?.loginID) {
              action.payload = loadState;
            }
          }
        }
        return next(action);

      // Updates localstorage with new authState after performing account switch
      case AuthActionsTypes.SWITCH:
        const newLoginID = action.payload.loginID;

        // save current account's info into Redux, if any
        const prevAuthState = storeAPI.getState().authState;
        const prevLoginID = prevAuthState.effectiveUser?.loginID;
        // on page reload, "previous" account will look the same as newLoginID
        if (prevLoginID !== undefined && prevLoginID !== newLoginID) {
          const prevActingID = prevAuthState.effectiveUser?.actingID;
          const prevTransactions = storeAPI.getState().transactions;
          const prevUserBolts = storeAPI.getState().userBolts;
          storeAPI.dispatch({
            type: AccountDataActionsTypes.STORE,
            payload: {
              accountID: prevLoginID,
              delegatedID: prevActingID ? prevActingID : prevLoginID,
              accountData: {
                transactions: prevTransactions,
                userBolts: prevUserBolts,
              },
            },
          });
        }
        // clear active account data after backup is complete
        storeAPI.dispatch({
          type: AccountDataActionsTypes.CLEAR_ACTIVE,
        });

        // load account data from Redux storage
        const newActingID = action.payload.actingID;
        const accountData =
          storeAPI.getState().accountDataState[newLoginID]?.[newActingID];
        const userBolts = accountData?.userBolts;
        const transactions = accountData?.transactions;
        if (userBolts && transactions) {
          action.payload.userBolts = userBolts;
          action.payload.transactions = transactions;
        }

        // switch accounts
        const switchResult = next(action);
        //update authState in session storage
        const switchAuthState = storeAPI.getState().authState;
        const newSessionAuthState = switchAuthState;
        //add profile data for all accounts (logged in and delegated) to session storage
        //note that this is a temporary fix to be able to get user state data from storage, see authReducer.ts
        for (const accountID in newSessionAuthState.accounts) {
          newSessionAuthState.accounts[accountID].profileData =
            userState[accountID];
          for (const delegateID in newSessionAuthState.accounts[accountID]
            .delegatedPermissions) {
            newSessionAuthState.accounts[accountID].delegatedPermissions[
              delegateID
            ].profileData = userState[delegateID];
          }
        }
        sessionStorage.setItem(
          'authState',
          JSON.stringify(newSessionAuthState)
        );

        // if we are switching to one of the linked accounts in local storage (accounts to remember),
        // then update authState information in local storage
        const effectiveUser = storeAPI.getState().authState.effectiveUser;
        const newAuthState: IAuthStateData = localStore
          ? JSON.parse(localStore)
          : null;
        if (newAuthState) {
          //add profile data for all accounts (logged in and delegated) to local storage
          //note that this is a temporary fix to be able to get user state data from storage, see authReducer.ts
          for (const accountID in newAuthState.accounts) {
            newAuthState.accounts[accountID].profileData = userState[accountID];
            for (const delegateID in newSessionAuthState.accounts[accountID]
              .delegatedPermissions) {
              newSessionAuthState.accounts[accountID].delegatedPermissions[
                delegateID
              ].profileData = userState[delegateID];
            }
          }
          if (effectiveUser && newAuthState.accounts[effectiveUser.loginID]) {
            newAuthState.effectiveUser = effectiveUser;
          }
          localStorage.setItem('authState', JSON.stringify(newAuthState));
        }
        const token = storeAPI.getState().authState.accounts[newLoginID]?.token;
        if (token) {
          updateAuthAxios(token, newActingID, newLoginID);
          localStorage.setItem('token', token);
          //restarts socket connection with new token.  Indicate which user we are acting as
          if (action.payload.connect !== undefined) {
            action.payload.connect(
              token,
              effectiveUser ? effectiveUser.actingID : -1
            );
          }
        }

        // fetch data if not already in store
        if (!userBolts || !transactions) {
          if (selectIsDelegate(storeAPI.getState())) {
            getUserBolts(newActingID)(storeAPI.dispatch);
            const txnPerms =
              storeAPI.getState().authState.accounts[newLoginID]
                .delegatedPermissions[newActingID].accept;
            if (txnPerms) {
              getTxn(newActingID)(storeAPI.dispatch);
            }
          } else {
            getUserBolts()(storeAPI.dispatch);
            getTxn()(storeAPI.dispatch);
          }
        }
        return switchResult;

      // Updates localstorage with new authState after performing login
      case AuthActionsTypes.LOGIN:
        // log in
        const loginResult = next(action);
        //handling remember me
        const authState = storeAPI.getState().authState;
        const localAuthState: IAuthStateData = localStore
          ? JSON.parse(localStore)
          : {};
        if (action.payload.rememberMe) {
          //if remember me checked, add logged-in account information to authState in local storage
          const {
            accounts: { [action.payload.loginID]: loginID },
            ...authStateInfo
          } = authState;
          Object.assign(localAuthState, authStateInfo);
          if (!localAuthState.accounts) {
            localAuthState.accounts = {};
          }
          localAuthState.accounts[action.payload.loginID] = loginID;
          localStorage.setItem('authState', JSON.stringify(localAuthState));
        }
        //update authState in session storage
        const sessionAuthState: IAuthStateData = sessionStore
          ? JSON.parse(sessionStore)
          : {};
        //append new login account to existing accounts (there is modified data in existing accounts)
        if (sessionAuthState.accounts) {
          const {
            accounts: { [action.payload.loginID]: loginID },
          } = authState;
          sessionAuthState.accounts[action.payload.loginID] = loginID;
          sessionStorage.setItem('authState', JSON.stringify(sessionAuthState));
        } else {
          sessionStorage.setItem(
            'authState',
            JSON.stringify(storeAPI.getState().authState)
          );
        }
        return loginResult;

      //Clears data about a specific account
      case AuthActionsTypes.LOGOUT:
        const accountID = action.payload.accountID;
        //clear data about account in accountData slice
        storeAPI.dispatch({
          type: AccountDataActionsTypes.CLEAR,
          payload: {
            accountID,
          },
        });

        const logout = next(action);
        //update authState in session storage
        const logoutAuthState = storeAPI.getState().authState;
        //remove logged out account from exsiting accounts (there is modified data in existing accounts)
        const {
          accounts: { [action.payload.loginID]: loginID, ...rest }, // eslint-disable-line @typescript-eslint/no-unused-vars
        } = logoutAuthState;
        logoutAuthState.accounts = rest;
        sessionStorage.setItem('authState', JSON.stringify(logoutAuthState));

        //remove logged out account from local storage
        if (localStore) {
          const localState: IAuthStateData = JSON.parse(localStore);
          if (localState) {
            if (
              localState.effectiveUser?.loginID === accountID ||
              localState.effectiveUser?.actingID === accountID
            ) {
              delete localState.effectiveUser;
            }
            if (localState.accounts && localState.accounts[accountID]) {
              delete localState.accounts[accountID];
            }
            localStorage.setItem('authState', JSON.stringify(localState));
          }
        }
        return logout;

      // Clears account data from Redux store and updates localstorage auth state
      case AuthActionsTypes.LOGOUT_ALL:
        // Clear active account data
        storeAPI.dispatch({
          type: AccountDataActionsTypes.CLEAR_ACTIVE,
        });
        // Clear any backup storage
        const logoutAccount =
          storeAPI.getState().authState.effectiveUser?.loginID;
        if (logoutAccount) {
          storeAPI.dispatch({
            type: AccountDataActionsTypes.CLEAR,
            payload: {
              accountID: logoutAccount,
            },
          });
        }
        // Clear session and local storage after performing logout
        const logoutResult = next(action);
        sessionStorage.clear();
        localStorage.clear();
        return logoutResult;
      default:
        return next(action);
    }
  };
