import React from 'react';
import { Route, Redirect, RouteComponentProps } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectAuthenticated, selectUserRole } from '../../redux/selectors';

type PRIVROUTER = {
  path: string;
  component: (props: RouteComponentProps<any>) => JSX.Element;
};

//To make users login before they can access the other pages on the website
// FIXLATER
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function PrivateRouter(props: PRIVROUTER) {
  const isAuthenticated = useSelector(selectAuthenticated);
  const isMerchant = useSelector(selectUserRole);
  const path = props.path;
  const component = props.component;

  if (isAuthenticated) {
    if (path !== '/pos' || isMerchant) {
      return <Route path={path} component={component} />;
    } else {
      return <Redirect to="/wallet" />;
    }
  } else {
    const callback = new URLSearchParams();
    callback.append('callbackURL', window.location.href);
    const redirect =
      window.location.pathname === '/wallet' ? '/login' : `/login?${callback}`;
    return <Redirect to={redirect} />;
  }
}

export default PrivateRouter;
