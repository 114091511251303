import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import {
  authMiddleware,
  authStateMiddleware,
} from './middleware/authMiddleware';
import { intercept } from './middleware/axiosInterceptor';
import { hydrationMiddleware } from './middleware/hydrationMiddleware';
import { rootReducer } from './reducers/reducer';

// all middleware applied to store dispatch(), in order
const middleware = [
  thunk,
  authMiddleware,
  authStateMiddleware,
  hydrationMiddleware,
];

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(...middleware))
);
intercept(store);

export default store;

(<any>window).rootstore = store;
