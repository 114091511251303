import { Dispatch } from 'redux';
import { error } from './alertAction';
import registerService from '../../services/registerService';

const minUsernameLength = 4;

// attempt to register a new user
// FIXLATER
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const registerAction = (
  email: string,
  firstName: string,
  lastName: string,
  phone: string,
  password: string,
  setIsShowDialog: (state: boolean) => void,
  setIsSuccess: (state: boolean | null) => void,
  setNeedVerify: (state: boolean | null) => void,
  userRole: null | string,
  username: string,
  businesssName: string
) => {
  const role = userRole || 'customer';
  return (dispatch: Dispatch) => {
    registerService
      .registerNewUser(
        email,
        firstName,
        lastName,
        password,
        role,
        username,
        phone,
        businesssName
      )
      .then((res: any) => {
        console.log(res);
        if (res.status === 'error') {
          setIsShowDialog(false);
          setIsSuccess(false);
          dispatch(error(res.msg));
        } else {
          // success callback for showing Return to Login modal
          console.log(res.needVerify);
          setIsSuccess(true);
          setNeedVerify(res.needVerify);
        }
      })
      .catch((/*err*/) => {
        setIsShowDialog(false);
        setIsSuccess(false);
        dispatch(error('Encountered error when registering'));
      });
  };
};

// checks if username is valid (meets minimum length requirement and is unique)
// takes two callback functions which indicate the progress and result of the request
export const checkUsernameAction = (
  username: string,
  setCheckingUsername: (state: boolean) => void,
  setUsernameIsValid: (state: boolean) => void
) => {
  // FIXLATER
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return (dispatch: Dispatch) => {
    if (checkUsername(username) === false) {
      setCheckingUsername(false);
      setUsernameIsValid(false);
      if (username.length > minUsernameLength) {
        dispatch(
          error(
            'Usernames must be unique, contain a mix of letters, numbers, or dashes, and be at least 4 characters long.'
          )
        );
      }
    } else {
      setCheckingUsername(true);
      registerService
        .checkUsername(username)
        .then((res) => {
          setCheckingUsername(false);
          if (res.status === 'error') {
            setUsernameIsValid(false);
            dispatch(error(res.msg));
          } else {
            setUsernameIsValid(res.username.available);
          }
        })
        .catch((/*err*/) => {
          setCheckingUsername(false);
          setUsernameIsValid(false);
          dispatch(error('Encountered error when checking username'));
        });
    }
  };
};

// Matches all alphabets
const regexAlpha = /[a-zA-Z]/;
// Matches all characters not in the set of alphabets, numbers and dashes
const negRegex = /[^a-zA-Z0-9\-]/;
// Matches one or more dash in every 3 letters
const dashRegex = /-.{0,2}-/;

const checkUsername = (user: string) => {
  console.log('checking');
  // Check for minimum length
  if (user.length < minUsernameLength) {
    return false;
  }
  if (user.includes('@')) {
    return false;
  }
  // Check for at least one alphabet
  if (regexAlpha.test(user) === false) {
    return false;
  }
  // Check for other disallowed characters
  if (negRegex.test(user) === true) {
    return false;
  }
  // Check for more than one dash every 3 letters
  if (dashRegex.test(user) === true) {
    return false;
  }
  return true;
};
