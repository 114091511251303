import React, { useContext } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { logoutAllAccounts } from '../../redux/actions/authAction';
import { WebSocketContext, WS } from '../../context/WebSocketContext';

type LOGOUT = {
  setNavOpen: (open: boolean) => void;
  setDialogOpen: (isOpen: boolean) => void;
};

// FIXLATER
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function ConfirmLogout(props: LOGOUT) {
  const setOpen = props.setNavOpen;
  const setDialogOpen = props.setDialogOpen;

  const ws = useContext(WebSocketContext) as WS;

  const dispatch = useDispatch();

  // Closes the dialog box
  const handleClose = () => {
    setDialogOpen(false);
  };

  // Handles logout and return to login screen
  const handleLogout = () => {
    dispatch(logoutAllAccounts(ws.socket));
    handleClose();
    setOpen(false);
  };

  return (
    <Dialog open={true} onClose={handleClose}>
      <DialogTitle>Logout?</DialogTitle>
      <DialogContent>You will be returned to the login screen.</DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleLogout} color="primary">
          Logout
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmLogout;
