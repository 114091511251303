import React, { useState } from 'react';
import {
  Box,
  Button,
  DialogTitle,
  DialogContent,
  makeStyles,
  createStyles,
  Theme,
  Link,
  Grid,
} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    boltViewPaper: {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.secondary.main,
      minHeight: '500px',
      minWidth: '100%',
      alignItems: 'center',
      padding: 10,
    },
    boltViewContent: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
    },
    dialogClose: {
      alignSelf: 'flex-end',
    },
    buttonSection: {
      display: 'flex',
      justifyContent: 'center',
      gap: '10em',
    },
    actionButton: {
      margin: 5,
      padding: '10px 15px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    boltCard: {
      borderRadius: 20,
      width: '100%',
      padding: theme.spacing(3),
      marginTop: 12,
      textAlign: 'center',
    },
    boltInfo: {
      margin: 18,
    },
    actionButtons: {
      marginBottom: 10,
      borderRadius: 100,
    },
    exitModal: {
      float: 'right',
    },
    actionButton1: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
  })
);

type TermsOfServiceProps = {
  setDialogOpen: (isOpen: boolean) => void;
  handleCallback: (response: string) => void;
  termsContent: string;
  privacyContent: string;
  boxHeight: string;
};

// FIXLATER
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function TermsOfService({
  handleCallback,
  setDialogOpen,
  termsContent,
  privacyContent,
  boxHeight,
}: TermsOfServiceProps) {
  const classes = useStyles();
  const [scrolled, setScrolled] = useState(true);
  const [policy, setPolicy] = useState<'terms' | 'privacy'>('terms');

  // handle the callback response when a user clicks agree/deny buttons
  const handleResponse = (response: string) => {
    handleCallback(response);
    setDialogOpen(false);
  };

  // detect scrolled progress
  const handleScroll = (e: any) => {
    if (
      Math.abs(
        e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight
      ) <= 5.0
    ) {
      setScrolled(false);
    }
  };

  return (
    <div className={classes.boltViewPaper}>
      <DialogTitle id="form-dialog-title">Terms of Service</DialogTitle>
      <Grid container alignItems="center" justify="center" direction="row">
        <DialogContent className={classes.boltViewContent}>
          <Grid item xs={12}>
            <Box
              className={classes.boltViewContent}
              px={2}
              height={boxHeight} // fixed the height
              onScroll={(e) => handleScroll(e)}
              style={{
                border: '2px solid black',
                overflow: 'hidden',
                overflowY: 'scroll', // added scroll
              }}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: policy === 'terms' ? termsContent : privacyContent,
                }}
              />
            </Box>
          </Grid>
          {policy === 'terms' && (
            <Grid item xs={12}>
              <Link component="button" onClick={() => setPolicy('privacy')}>
                Privacy Policy
              </Link>
            </Grid>
          )}
        </DialogContent>
      </Grid>
      <Grid container alignItems="center" justify="center" direction="row">
        {policy === 'terms' ? (
          <div className={classes.buttonSection}>
            <Grid item xs={4}>
              <Button
                className={classes.actionButton}
                onClick={() => handleResponse('deny')}
                variant="outlined"
                color="primary"
              >
                Deny
              </Button>
            </Grid>
            <Grid item xs={4}>
              <Button
                className={classes.actionButton}
                disabled={scrolled}
                onClick={() => handleResponse('agree')}
                variant="outlined"
                color="primary"
              >
                Agree
              </Button>
            </Grid>
          </div>
        ) : (
          <Button
            className={classes.actionButton1}
            variant="outlined"
            color="primary"
            onClick={() => setPolicy('terms')}
          >
            Back to terms of service
          </Button>
        )}
      </Grid>
    </div>
  );
}
export default TermsOfService;
