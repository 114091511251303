//Component for merchant cards grid in the main Marketplace page.

import React from 'react';
import { Typography, Grid, makeStyles, Card } from '@material-ui/core';
import { Store as BusinessIcon } from '@material-ui/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getImageURL } from '../../services/api';
import { history } from '../../utils/history';
import { TileData } from '../../utils/types';
import { asCurrencyString } from '../../utils/money';

type GridListProps = {
  title: string;
  tileData: Array<TileData>;
};

const useStyles = makeStyles((theme) => ({
  tileContainer: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
  },
  tile: {
    width: '100%',
    height: '100%',
    borderRadius: 8,
    border: '1px solid ' + theme.palette.primary.light,
    padding: theme.spacing(2),
    boxShadow: '0px 10px 10px #CCCCCC',
    backgroundColor: theme.palette.background.default,
    transition: 'background-color .4s, box-shadow .4s',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.background.paper,
      boxShadow: '0px 4px 4px #CCCCCC',
    },
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
}));

// FIXLATER
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function GridListContainer(props: GridListProps) {
  const classes = useStyles();
  if (props.tileData.length > 0) {
    return (
      <div>
        <Typography component="h4" variant="h5" style={{ padding: '5% 0' }}>
          {props.title}
        </Typography>
        <Grid
          container
          alignItems="stretch"
          justify="flex-start"
          spacing={3}
          style={{ height: '100%' }}
        >
          {props.tileData.map((tile) => (
            <Grid key={tile.businessName} item lg={4} md={6} sm={12} xs={12}>
              <div
                className={classes.tileContainer}
                onClick={() => {
                  history.push(`/vendor/${tile.userId}`);
                }}
              >
                <Card classes={{ root: classes.tile }}>
                  <Grid container alignItems="center" justify="center">
                    <Grid item xs={4}>
                      <div
                        style={{
                          textAlign: 'center',
                          margin: '4px',
                        }}
                      >
                        {tile.logo ? (
                          <img
                            src={getImageURL(tile.logo)}
                            style={{
                              width: 72,
                              height: 72,
                              objectFit: 'contain',
                              borderRadius: '20px',
                            }}
                          />
                        ) : (
                          <BusinessIcon fontSize="large" color="primary" />
                        )}
                      </div>
                    </Grid>
                    <Grid item xs={8}>
                      <div style={{ textAlign: 'center' }}>
                        <Typography
                          style={{ fontSize: '1rem', fontWeight: 800 }}
                        >
                          {tile.businessName
                            ? tile.businessName
                            : 'Anonymous Issuer'}
                        </Typography>
                        {tile.ZUZOwned > 0 && (
                          <Typography
                            style={{ fontSize: '1rem', fontWeight: 800 }}
                          >
                            {asCurrencyString(tile.ZUZOwned)} ZUZ
                          </Typography>
                        )}
                      </div>
                    </Grid>
                    <Grid item xs={2}>
                      {(tile.raised > 0 || tile.goal > 0) && (
                        <div
                          style={{
                            textAlign: 'center',
                            marginTop: -5,
                            padding: 3,
                          }}
                        >
                          <div>${tile.goal}</div>
                          {tile.raised >= tile.goal ? (
                            <FontAwesomeIcon
                              icon={['far', 'thermometer-full']}
                              size="3x"
                            />
                          ) : tile.raised >= (3 / 4) * tile.goal ? (
                            <FontAwesomeIcon
                              icon={['far', 'thermometer-three-quarters']}
                              size="3x"
                            />
                          ) : tile.raised >= (1 / 2) * tile.goal ? (
                            <FontAwesomeIcon
                              icon={['far', 'thermometer-half']}
                              size="3x"
                            />
                          ) : tile.raised >= (1 / 4) * tile.goal ? (
                            <FontAwesomeIcon
                              icon={['far', 'thermometer-quarter']}
                              size="3x"
                            />
                          ) : (
                            <FontAwesomeIcon
                              icon={['far', 'thermometer-empty']}
                              size="3x"
                            />
                          )}
                        </div>
                      )}
                    </Grid>
                  </Grid>
                </Card>
              </div>
            </Grid>
          ))}
        </Grid>
      </div>
    );
  } else {
    return <></>;
  }
}

export default GridListContainer;
