import React, { Fragment, useState } from 'react';
import Title from '../common/Title';
import GroupedBoltCard from './GroupedBoltCard';
import GroupedBoltView from './GroupedBoltView';
import { UsersState } from '../../redux/reducers/usersReducer';
import { useSelector } from 'react-redux';
import { BoltState } from '../../redux/reducers/boltReducer';
import {
  makeStyles,
  Grid,
  Hidden,
  GridList,
  Dialog,
  Button,
} from '@material-ui/core';
import { GROUPEDBOLTDATA, IUSERBOLTDATA } from '../../utils/types';
import { useHistory } from 'react-router-dom';
import BoltCard from '../common/BoltCard';
import WalletBoltCard from './WalletBoltCard';
import { useEffect } from 'react';
import { CircularProgress } from '@material-ui/core';

import { selectActingUser } from '../../redux/selectors';

const useStyles = makeStyles((theme) => ({
  grid: {
    marginTop: 145,
    width: '100%',
  },
  boltCard: {
    padding: theme.spacing(1.5),
    paddingTop: theme.spacing(1),
  },
  gridList: {
    maxWidth: '100%',
    flexWrap: 'nowrap',
    overflowX: 'visible',
    // accommodate card drop shadows
    paddingLeft: '10px',
    paddingBottom: '20px',
  },
  desktopBoltCard: {
    display: 'flex',
    flexShrink: 0,
    justifyContent: 'center', // horizontal centering
    width: theme.spacing(40),
    height: theme.spacing(20),
    marginRight: theme.spacing(2),
    alignItems: 'center',
  },
  boltViewPaper: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
}));

type REDUXSTATE = {
  userBolts: BoltState;
  users: UsersState;
};

// Takes list of user's bolts and groups BoLTs issued by the same user
// into the same group.

// There are 2 dialogs associated with displaying a user's
// bolts. setDesktopDialogOpen is called only on desktop - when
// desktopDialogOpen is set to true, a single bolt card is displayed
// as a dialog. setMobileDialogOpen is called only on mobile - when
// mobileDialogOpen is set to true, all bolts issued by a single
// merchant is displayed as a dialog.
function groupBoltsByIssuer(
  bolts: IUSERBOLTDATA[],
  user: string | undefined
): { [id: string]: GROUPEDBOLTDATA } {
  const issuersDict: { [id: string]: GROUPEDBOLTDATA } = {};

  for (let i = 0; i < bolts.length; i++) {
    const issuer = bolts[i].bolt.issuer;

    //Only display bolt if amount is not zero or the user is the issuer of the zuz
    if (bolts[i].amount > 0 || bolts[i].bolt.issuer.toString() === user) {
      if (issuer in issuersDict) {
        issuersDict[issuer].bolts.push(bolts[i]);
        issuersDict[issuer].totalAmount += bolts[i].amount;
      } else {
        issuersDict[issuer] = {
          bolts: [bolts[i]],
          totalAmount: bolts[i].amount,
          issuer: issuer,
          issuerName: bolts[i].bolt.issuerName,
        };
      }
    }
  }

  return issuersDict;
}

// type GroupBoltDictionary = { [id: string]: GROUPEDBOLTDATA; };

//This component displays a user's BoLTs grouped by the issuer.
// FIXLATER
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function MyBolt() {
  const classes = useStyles();
  const boltsAllInfo = useSelector((state: REDUXSTATE) => state.userBolts);
  const users = useSelector((state: REDUXSTATE) => state.users);
  const actingUser = useSelector(selectActingUser);
  const history = useHistory();

  const [loaded, setLoaded] = useState(false);

  // Should eventually be removed once all bolt specs are consistent
  // Make sure all the bolts have an issuer name
  // Specifically for when you create new bolt
  const userBolts = Object.values(boltsAllInfo).map((bolt) => {
    if (bolt.bolt.issuerName === '') {
      const userName = users[bolt.bolt.issuer];
      if (userName) {
        return {
          ...bolt,
          bolt: {
            ...bolt.bolt,
            issuerName:
              userName.lastName === null
                ? userName.firstName
                : userName.firstName + ' ' + userName.lastName,
          },
        };
      }
    }
    return bolt;
  });

  const [mobileDialogOpen, setMobileDialogOpen] = useState(false);
  const [desktopDialogOpen, setDesktopDialogOpen] = useState(false);
  const [chosenBoltGroup, setChosenBoltGroup] = useState<GROUPEDBOLTDATA | any>(
    ''
  ); // the bolt card user clicked

  const groupedBolts = groupBoltsByIssuer(userBolts, actingUser?.id.toString());

  useEffect(() => {
    setTimeout(() => {
      setLoaded(true);
    }, 1000);
  });

  // callback passed to each BoltCard; takes in the specID
  const onClickCard = (boltGroup: GROUPEDBOLTDATA) => {
    setChosenBoltGroup(boltGroup);
    setMobileDialogOpen(true);
  };
  const [chosenBoltID, setChosenBoltID] = useState(''); // the bolt card user clicked

  // callback passed to each BoltCard; takes in the specID
  const onClickCardDesktop = (id: string) => {
    setChosenBoltID(id);
    setDesktopDialogOpen(true);
  };

  return (
    <Fragment>
      {/* <CardDeck> */}
      {/* Mobile version of BoLT cards. Hidden on larger screens. */}
      <Hidden smUp>
        <Grid container className={classes.grid}>
          {loaded ? (
            Object.keys(groupedBolts).length ? (
              Object.keys(groupedBolts).map((issuer) => (
                <GroupedBoltCard
                  key={issuer}
                  boltGroup={groupedBolts[issuer]}
                  onClickCard={onClickCard}
                  vertical
                  className={classes.boltCard}
                />
              ))
            ) : (
              <Button
                onClick={() => history.push('/marketplace')}
                color="secondary"
              >
                No ZUZ? Check out the Marketplace.
              </Button>
            )
          ) : (
            <CircularProgress />
          )}
        </Grid>
      </Hidden>
      {/* Desktop version of BoLT cards. Hidden on smaller screens. */}
      <Hidden xsDown>
        <Grid container spacing={2}>
          {loaded ? (
            Object.keys(groupedBolts).length ? (
              Object.keys(groupedBolts).map((issuer) => (
                <Fragment key={issuer}>
                  <Grid item xs={12}>
                    <Title>{groupedBolts[issuer].issuerName}</Title>
                  </Grid>
                  <Grid item xs={12}>
                    <GridList className={classes.gridList}>
                      {groupedBolts[issuer].bolts.map((bolt) => {
                        return (
                          <BoltCard
                            key={bolt.bolt.specID}
                            bolt={bolt}
                            onClickCard={onClickCardDesktop}
                            className={classes.desktopBoltCard}
                          />
                        );
                      })}
                    </GridList>
                  </Grid>
                </Fragment>
              ))
            ) : (
              <Button
                onClick={() => history.push('/marketplace')}
                color="secondary"
              >
                No ZUZ? Check out the Marketplace.
              </Button>
            )
          ) : (
            <CircularProgress />
          )}
        </Grid>
      </Hidden>
      {/* </CardDeck> */}
      {mobileDialogOpen && (
        <GroupedBoltView
          groupedBolt={chosenBoltGroup}
          setMobileDialogOpen={setMobileDialogOpen}
        />
      )}

      {desktopDialogOpen && (
        <Dialog
          open={desktopDialogOpen}
          classes={{
            paper: classes.boltViewPaper,
          }}
          onClose={() => setDesktopDialogOpen(false)}
        >
          <WalletBoltCard
            boltID={chosenBoltID}
            closeModal={() => {
              setDesktopDialogOpen(false);
              setMobileDialogOpen(false);
            }}
          />
        </Dialog>
      )}
    </Fragment>
  );
}

export default MyBolt;
