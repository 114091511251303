import React from 'react';
import {
  makeStyles,
  Theme,
  Typography,
  createStyles,
  Dialog,
  DialogContent,
  IconButton,
  Grid,
} from '@material-ui/core';
import { ArrowLeft, ArrowRight } from '@material-ui/icons';
import { useState } from 'react';
import { GROUPEDBOLTDATA, IUSERBOLTDATA } from '../../utils/types';
import WalletBoltCard from './WalletBoltCard';
import { asCurrencyString } from '../../utils/money';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    boltAmount: {
      display: 'flex',
      marginTop: 5,
      justifyContent: 'center',
    },
    boltDetails: {
      marginTop: 20,
      display: 'flex',
      flexWrap: 'wrap',
      width: '70%',
    },
    boltDetailName: {
      flex: '50%',
      color: theme.palette.secondary.contrastText,
      textTransform: 'uppercase',
      marginBottom: 5,
    },
    boltDetailValue: {
      flex: '50%',
      textAlign: 'right',
      marginBottom: 5,
    },
    boltViewPaper: {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.secondary.main,
      padding: 0,
      minHeight: '85vh',
      minWidth: '100vw',
    },
    boltViewContent: {
      padding: 0,
    },
    dialogClose: {
      alignSelf: 'flex-end',
    },
    buttonSection: {
      display: 'flex',
      alignItems: 'center',
      position: 'absolute',
      justifyContent: 'center',
      bottom: 0,
      paddingBottom: 20,
      width: '100%',
    },
    actionButton: {
      margin: 5,
      padding: '10px 15px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      transition: 'background-color 0.5s',
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: theme.palette.grey[200],
      },
    },
  })
);

type GroupedBoltViewProps = {
  logo?: any;
  groupedBolt: GROUPEDBOLTDATA | any;
  setMobileDialogOpen: (isOpen: boolean) => void;
};

// This component is a modal carousel that displays details associated with each BoLT in a group of bolts issued by the same issuer.
// FIXLATER
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function GroupedBoltView(props: GroupedBoltViewProps) {
  const { groupedBolt, setMobileDialogOpen } = props;
  const classes = useStyles();
  const disableNextAndPrev = groupedBolt.bolts.length === 1;
  const [currentBolt, setCurrentBolt] = useState<IUSERBOLTDATA>(
    groupedBolt.bolts[0]
  );
  const [currentBoltIndex, setCurrentBoltIndex] = useState(0);

  //This function is called when the previous button on the carousel is clicked. It shows the info associated with the previous bolt in the group.
  const onClickPrev = () => {
    const numOfBolts = groupedBolt.bolts.length;

    if (currentBoltIndex === 0) {
      setCurrentBolt(groupedBolt.bolts[numOfBolts - 1]);
      setCurrentBoltIndex(numOfBolts - 1);
    } else {
      setCurrentBolt(groupedBolt.bolts[currentBoltIndex - 1]);
      setCurrentBoltIndex(currentBoltIndex - 1);
    }
  };

  //This function is called when the next button on the carousel is clicked. It shows the info associated with the next bolt in the group.
  const onClickNext = () => {
    const numOfBolts = groupedBolt.bolts.length;
    if (currentBoltIndex + 1 === numOfBolts) {
      setCurrentBolt(groupedBolt.bolts[0]);
      setCurrentBoltIndex(0);
    } else {
      setCurrentBolt(groupedBolt.bolts[currentBoltIndex + 1]);
      setCurrentBoltIndex(currentBoltIndex + 1);
    }
  };

  return (
    // This dialog overlays info on the group of bolts. It is activated whenever this component is present.
    <Dialog
      onClose={() => setMobileDialogOpen(false)}
      fullWidth
      maxWidth="md"
      open={true}
      classes={{
        paper: classes.boltViewPaper,
      }}
    >
      <DialogContent className={classes.boltViewContent}>
        <Grid container alignItems="center" justify="center" direction="row">
          <Grid item xs={1}>
            <IconButton
              disabled={disableNextAndPrev}
              onClick={onClickNext}
              size="small"
            >
              <ArrowLeft fontSize="large" />
            </IconButton>
          </Grid>
          <Grid item xs={10}>
            <Grid container alignItems="center" justify="center">
              <Grid item xs={6} alignItems="center" justify="center">
                <Typography variant="h5" noWrap color="textSecondary">
                  {groupedBolt.issuerName}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h5" color="secondary" noWrap align="right">
                  {asCurrencyString(groupedBolt.totalAmount)} yZUZ
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <WalletBoltCard
                  boltID={currentBolt.bolt.specID}
                  closeModal={() => {
                    setMobileDialogOpen(false);
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={1}>
            <IconButton
              disabled={disableNextAndPrev}
              onClick={onClickPrev}
              size="small"
            >
              <ArrowRight fontSize="large" />
            </IconButton>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default GroupedBoltView;
