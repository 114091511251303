import React, { useEffect, useState } from 'react';
import {
  Container,
  Typography,
  CssBaseline,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Grid,
  CircularProgress,
} from '@material-ui/core';
import { Check, Close } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory, useLocation } from 'react-router-dom';
import {
  registerAction,
  checkUsernameAction,
} from '../../redux/actions/registerAction';
import { useDispatch } from 'react-redux';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import WaitDialog from '../wallet/WaitDialog';
import DoublePassword from './DoublePassword';
import { Link } from 'react-router-dom';
import TermsOfService from './TermsOfService';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    textAlign: 'center',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    borderRadius: 10,
    textTransform: 'none',
  },
  dialog: {
    color: theme.palette.secondary.main,
  },
  logIn: {
    padding: 20,
  },
  userRoleToggleGroup: {
    padding: 25,
    width: '100%',
  },
  userRoleToggle: {
    width: '100%',
    padding: 5,
    borderRadius: 20,
    textTransform: 'none',
    fontStyle: 'italic',
  },
  usernameCheck: {
    fontStyle: 'italic',
    color: 'red',
    fontSize: '0.75rem',
    textAlign: 'left',
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.secondary.main,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

// FIXLATER
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function Register(props: any) {
  //Get vmail param from url
  const search = useLocation().search;
  let vmail = new URLSearchParams(search).get('vmail');

  //if vmail is null then replace with "" else decode
  if (vmail == null) {
    vmail = '';
  } else {
    vmail = atob(vmail);
  }
  const classes = useStyles();
  const [email, setEmail] = useState(vmail);
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [businessName, setBusinessName] = useState('');
  const [username, setUsername] = useState('');
  const [phone, setPhone] = useState('');
  const [isShowDialog, setIsShowDialog] = useState(false);
  const [isSuccess, setIsSuccess] = React.useState<boolean | null>(null);
  const [needVerify, setNeedVerify] = React.useState<boolean | null>(true);
  // If coming from "/login/merchant", the default userRole shoule be "merchant". Otherwise the role is customer
  const [userRole, setUserRole] = React.useState<string | null>(() =>
    props?.location?.state?.userRole === 'merchant' ? 'merchant' : 'customer'
  );
  const [checkingUsername, setCheckingUsername] = useState(false);
  const [usernameIsValid, setUsernameIsValid] = useState(false);
  const [passwordIsValid, setPasswordIsValid] = useState(false);
  const [termsAndServiceView, setTermsAndServiceView] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const onSubmit = async (e: any) => {
    e.preventDefault();
    setTermsAndServiceView(true);
  };

  /*
  callback functions that registers a user if they agreed to the terms of service, 
  or acknowledges that the user denied the terms of service
  */
  const handleTOSResponse = (response: string) => {
    if (response === 'agree') {
      // register a new user
      setIsShowDialog(true); //opens wait dialog
      setIsSuccess(null); //resets value of success
      setNeedVerify(true); //resets value of need verify
      dispatch(
        registerAction(
          email,
          firstName,
          lastName,
          phone, //not required
          password,
          setIsShowDialog, //controls wait dialog
          setIsSuccess, //success callback function
          setNeedVerify, //need verify flag function
          userRole, //specifies if customer or merchant
          username, //required, must be unique
          businessName //required for merchants
        )
      );
      console.log('terms of service accepted');
    } else if (response === 'deny') {
      // acknowledgement that user denied terms of service
      console.log('terms of service denied');
    }
  };

  const userRoleSwitch = (
    event: React.MouseEvent<HTMLElement>,
    newUserRole: string | null
  ) => {
    if (newUserRole !== null) {
      setUserRole(newUserRole);
      console.log(newUserRole);
    }
  };

  const validateUserName = (username: string) => {
    setUsername(username);
    if (username.length > 0) {
      dispatch(
        checkUsernameAction(username, setCheckingUsername, setUsernameIsValid)
      );
    } else {
      setCheckingUsername(false);
      setUsernameIsValid(false);
    }
  };
  // callback function to handle chaning password in DoublePassword
  const sendPassword = (password: string) => {
    setPassword(password);
    console.log(password);
  };

  // callback function to check whether the password is valid
  const handlePasswordResponse = (response: boolean) => {
    console.log(response);
    setPasswordIsValid(response);
  };

  // prefetch the legal documents
  const [TOSContent, setTOSContent] = useState<string>();
  const [privacyContent, setPrivacyContent] = useState<string>();
  const fetchLegalDocuments = async () => {
    try {
      const tos = await fetch('/tos.html');
      setTOSContent(await tos.text());
      const privacy = await fetch('/pp.html');
      setPrivacyContent(await privacy.text());
    } catch (e) {
      setTOSContent('cannot connect to the internet, try again later');
      console.log(e);
    }
  };

  useEffect(() => {
    fetchLegalDocuments();
  }, []);

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Dialog
        open={termsAndServiceView}
        onClose={() => setTermsAndServiceView(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="md"
      >
        {!!TOSContent && !!privacyContent ? (
          <TermsOfService
            setDialogOpen={setTermsAndServiceView}
            handleCallback={handleTOSResponse}
            termsContent={TOSContent}
            privacyContent={privacyContent}
            boxHeight="300px"
          />
        ) : (
          <CircularProgress />
        )}
      </Dialog>
      <Dialog
        open={isShowDialog}
        onClose={() => setIsShowDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {isSuccess ? (
          needVerify ? (
            <div>
              <DialogTitle
                id="alert-dialog-title"
                classes={{ root: classes.dialog }}
              >
                {'Email Verification'}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Confirmation email has been sent to {email} -{' '}
                  <strong>Check it out!</strong>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => history.push('/login')}
                  color="secondary"
                >
                  Back to Login
                </Button>
              </DialogActions>
            </div>
          ) : (
            <div>
              <DialogTitle
                id="alert-dialog-title"
                classes={{ root: classes.dialog }}
              >
                {'Registered Successfully!'}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  You can now use your ZUZ account - {email}. Check it out by
                  logging in!
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => history.push('/login')}
                  color="secondary"
                >
                  Back to Login
                </Button>
              </DialogActions>
            </div>
          )
        ) : (
          <WaitDialog
            title="Registering new user..."
            description="Please wait while we register your new user"
          />
        )}
      </Dialog>
      <div className={classes.paper}>
        <Typography component="h1" variant="h2">
          ZUZ
        </Typography>
        <ToggleButtonGroup
          value={userRole}
          exclusive
          onChange={userRoleSwitch}
          aria-label="user type"
          className={classes.userRoleToggleGroup}
        >
          <ToggleButton
            value="customer"
            aria-label="customer account"
            className={classes.userRoleToggle}
          >
            user
          </ToggleButton>
          <ToggleButton
            value="merchant"
            aria-label="merchant account"
            className={classes.userRoleToggle}
          >
            merchant
          </ToggleButton>
        </ToggleButtonGroup>
        <form className={classes.form} onSubmit={onSubmit}>
          <TextField
            margin="normal"
            color="secondary"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            autoFocus
            disabled={vmail !== ''}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            margin="normal"
            color="secondary"
            required
            fullWidth
            id="username"
            label="Username"
            name="username"
            autoComplete="username"
            value={username}
            onChange={(e) => validateUserName(e.target.value)}
            InputProps={{
              endAdornment: (
                <React.Fragment>
                  {checkingUsername ? (
                    <CircularProgress color="secondary" size={20} />
                  ) : usernameIsValid ? (
                    <Check style={{ color: 'green' }} />
                  ) : (
                    username.length > 0 && (
                      <>
                        <Close
                          style={{ color: 'red' }}
                          onClick={(/*e*/) => setUsername('')}
                        />
                      </>
                    )
                  )}
                </React.Fragment>
              ),
            }}
          />
          {!checkingUsername && !usernameIsValid && username.length > 0 ? (
            <Typography className={classes.usernameCheck}>
              Usernames must be unique, contain a mix of letters, numbers, or
              dashes, and be at least 4 characters long.
            </Typography>
          ) : (
            <></>
          )}
          <TextField
            margin="normal"
            color="secondary"
            required
            fullWidth
            id="firstname"
            label="First Name"
            name="firstname"
            autoComplete="given-name"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
          <TextField
            margin="normal"
            color="secondary"
            fullWidth
            id="lastname"
            label="Last Name"
            name="lastname"
            autoComplete="family-name"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
          {userRole === 'merchant' && (
            <TextField
              margin="normal"
              color="secondary"
              required
              fullWidth
              id="businessname"
              label="Business Name"
              name="businessname"
              autoComplete="business-name"
              value={businessName}
              onChange={(e) => setBusinessName(e.target.value)}
            />
          )}
          <TextField
            margin="normal"
            color="secondary"
            fullWidth
            id="phone"
            label="Phone"
            name="phone"
            autoComplete="phone"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
          <DoublePassword
            handleResponse={handlePasswordResponse}
            handlePassword={sendPassword}
          />
          <Button
            type="submit"
            variant="contained"
            size="large"
            color="primary"
            className={classes.submit}
            disabled={!usernameIsValid || !passwordIsValid}
          >
            Sign Up
          </Button>
          <Grid container className={classes.logIn}>
            <Grid item xs={12}>
              <Link to="/login" className={classes.link}>
                <Typography variant="body2" color="secondary">
                  {'Already have an account?'}
                </Typography>
                <Typography variant="body2" color="secondary">
                  {'Login Here'}
                </Typography>{' '}
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
}

export default Register;
