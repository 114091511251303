// Grid not in use
// import { makeStyles, Avatar, Grid, useTheme } from '@material-ui/core';
import { makeStyles, Avatar, Grid } from '@material-ui/core';
import React from 'react';
import { ITXNDATA, IUSERDATA, TXNKINDS } from '../../utils/types';
import { ACCTXNDIALOG } from '../dashboard/AcceptTransaction';
import Typography from '@material-ui/core/Typography';
import Slip from './Slip';
import SlipActionArea from './SlipActionArea';
import { getImageURL } from '../../services/api';
import { useSelector } from 'react-redux';
import { asCurrencyString } from '../../utils/money';
// Not in use
// import { useDispatch, useSelector } from 'react-redux';
import { selectLoginUser } from '../../redux/selectors';

const useStyles = makeStyles((theme) => ({
  avatar: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    width: 80,
    height: 80,
  },
  transactionInfo: {
    display: 'inline-block',
    flexGrow: 1,
    flexShrink: 1,
    minWidth: 0,
    marginLeft: theme.spacing(2),
  },
  // align money symbol with amount value
  transactionAmount: {
    display: 'flex',
    alignItems: 'center',
  },
  // force text ellipses when too long
  overflowText: {
    maxWidth: '100%',
  },
  acceptButton: {
    color: theme.palette.secondary.main,
  },
  rejectButton: {
    color: theme.palette.error.main,
  },
  slipContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  transactionBoxDesktop: {
    display: 'flex',
    marginLeft: 'auto',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  transactionBoxMobile: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },

  acceptButtonDesktop: {
    color: theme.palette.secondary.main,
    borderRadius: 15,
  },
  rejectButtonDesktop: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
    borderRadius: 15,
  },
  large: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    maxHeight: '100%',
    objectFit: 'scale-down',
  },
}));

export type TransactionLineProps = {
  variant?:
    | 'incoming' // pending, current user is recipient
    | 'outgoing' // pending, initiated by current user
    | 'finalized';

  // consider taking in just the ID and directly selecting the content
  transaction: ITXNDATA;

  // Callback for accept dialog; should be provided for ALL "incoming" variants
  setAcceptDialogState?: (state: ACCTXNDIALOG) => void;

  // Callbacks for transaction detail view
  setTxnDialogOpen: (state: boolean) => void;
  setChosenBoltID: (state: string) => void;
};

const returnAvatar = (user: IUSERDATA, classes: any) => {
  if (!user.profilePic) {
    return (
      <Avatar variant="rounded" className={classes.avatar}>
        <strong>
          {user?.firstName[0]?.toLocaleUpperCase()}.
          {user?.lastName?.[0]?.toLocaleUpperCase()}.
        </strong>
      </Avatar>
    );
  }

  const profileObj =
    typeof user.profilePic === 'string'
      ? getImageURL(user.profilePic)
      : URL.createObjectURL(user.profilePic);
  return (
    <Avatar variant="rounded" src={profileObj} className={classes.large} />
  );
};

/**
 * This component displays a summary line of a transaction, possibly with
 * actions depending on the variant.
 *
 * In order to be able to display transaction details, parent components
 * are expected to display a transaction detail modal corresponding to the
 * setTxnDialogOpen and setChosenBoltID callbacks. "incoming" variants are
 * also expected to provide user feedback regarding accepted transactions.
 *
 * TODO:
 * "incoming" variants handleAccept flow should be improved.
 * Callbacks can probably be somewhat less restrictive.
 */
const TransactionLine: React.FC<TransactionLineProps> = (props) => {
  const { transaction, setTxnDialogOpen, setChosenBoltID } = props;
  const classes = useStyles();
  const currentUser = useSelector(selectLoginUser);

  const viewTransactionDetail /* eslint-disable-line @typescript-eslint/no-unused-vars */ /* FIXLATER */ =
    (id: string) => {
      setChosenBoltID(id);
      setTxnDialogOpen(true);
    };

  //Returns transaction detail text based on transaction kind
  const getTransactionText = () => {
    switch (transaction.kind) {
      case TXNKINDS.MINT:
        return <>Mint</>;
      case TXNKINDS.TRANSFER:
        return (
          <>
            From: <b>@{transaction.sender.username}</b>
            <br />
            To: <b>@{transaction.receiver.username}</b>
          </>
        );
      case TXNKINDS.DESTROY:
        //If the bolt spec allows for donation then display it as a donation
        if (transaction.bolt.donation) {
          return (
            <>
              Donatee: <b>{transaction.bolt.issuerName}</b>
            </>
          );
        } else {
          return <>Destroyed</>;
        }

      case TXNKINDS.BUYBACK:
        return (
          <>
            Buyback from <b>{transaction.sender.username}</b>
          </>
        );
    }
  };

  return (
    <Slip className={classes.slipContainer}>
      <SlipActionArea
        onClick={() => viewTransactionDetail(transaction.bolt.specID)}
      >
        <Grid
          container
          spacing={2}
          alignItems="center"
          justify="center"
          style={{ maxWidth: '676px', width: '100%', margin: 0 }}
        >
          <Grid item xs={5} sm={2}>
            {currentUser &&
            currentUser.username !== transaction.receiver.username
              ? returnAvatar(transaction.receiver, classes)
              : returnAvatar(transaction.sender, classes)}
          </Grid>
          <Grid item xs={7} sm={5}>
            <Typography variant="h4">
              <b>{asCurrencyString(transaction.amount)}</b>
            </Typography>
            <Typography variant="subtitle2">
              <b>{transaction.bolt.specName}</b>
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={5}
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <div>
              <Typography
                variant="caption"
                color="textSecondary"
                style={{ lineHeight: '10px' }}
              >
                {getTransactionText()}
                <br />
                {new Date(transaction.updatedAt).toLocaleDateString('en-us', {
                  weekday: 'long',
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric',
                })}
              </Typography>
            </div>
          </Grid>
        </Grid>
      </SlipActionArea>
    </Slip>
  );
};

TransactionLine.defaultProps = {
  variant: 'finalized',
  setAcceptDialogState: (/*state: ACCTXNDIALOG*/) => undefined,
};

export default TransactionLine;
