import React, {
  Fragment,
  useEffect,
  useRef,
  useState,
  useContext,
  useCallback,
} from 'react';
import clsx from 'clsx';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import { Link, LinkProps, NavLink, useLocation } from 'react-router-dom';
import ConfirmLogout from './auth/ConfirmLogout';
import { useSelector, useDispatch } from 'react-redux';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import Popper from '@material-ui/core/Popper';
import Hidden from '@material-ui/core/Hidden';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import Container from '@material-ui/core/Container';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconPrefix, IconName } from '@fortawesome/fontawesome-common-types';
import { BottomNavigationAction } from '@material-ui/core';
import { ReactComponent as LogotypeWhite } from '../images/zuz_logotype_white.svg';
import QRScanner from './QRScanner';
import {
  selectAuthenticated,
  selectLoginUser,
  selectProfiles,
  selectUserRole,
} from '../redux/selectors';
import {
  switchAccounts,
  logoutAccount,
  logoutAllAccounts,
} from '../redux/actions/authAction';
import { WebSocketContext, WS } from '../context/WebSocketContext';
import { USERUUID } from '../utils/types';
import Grid from '@material-ui/core/Grid';

// Npt in use
// const { REACT_APP_ABOUT_URL } = process.env;

// Width of nav drawer; used to push content in Router container
const drawerWidth = 220;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    width: '100vw',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundImage: `linear-gradient(to right, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
  },
  appBarShift: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
  },
  // enable logo centering
  toolbarMenu: {
    flexBasis: '33%',
  },
  logotype: {
    height: '4em',
    flexBasis: '33%',
    float: 'left',
    cursor: 'pointer',
    padding: '14px',
  },
  dropdown: {
    width: '14em',
  },
  toolbarButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    flexBasis: '67%',
  },
  contrastIcon: {
    color: theme.palette.primary.contrastText,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    backgroundImage: `linear-gradient(to bottom, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  navIcon: {
    fontSize: '1.5em',
    color: 'inherit',
  },
  // inactive/active route styling
  unselected: {
    color: theme.palette.primary.contrastText,
    fontFamily: 'Montserrat',
    fontWeight: 700,
    textDecoration: 'none',

    '&:hover': {
      textDecoration: 'underline',
    },
  },
  selected: {
    textDecoration: 'underline',
  },
  hide: {
    // want to keep item in DOM for alignment
    visibility: 'hidden',
  },
  content: {
    minWidth: 0,
    flexGrow: 1,
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10), // accommodate bottom nav bar
    [theme.breakpoints.up('sm')]: {
      paddingBottom: theme.spacing(4),
    },
  },
  // disable default Popper outlining
  popperPaper: {
    '& > *': {
      '&:focus': {
        outline: 'none',
      },
    },
  },
  // for user menu callout arrow
  arrow: {
    position: 'absolute',
    '&::before': {
      content: '""',
      backgroundColor: 'white',
      display: 'block',
      marginTop: '-0.4em',
      width: '1em',
      height: '1em',
      transform: 'rotate(45deg)',
    },
  },
  bottomNav: {
    zIndex: theme.zIndex.drawer,
    position: 'fixed',
    width: '100%',
    padding: theme.spacing(3),
    bottom: 0,
  },
  logoutButton: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    marginLeft: 5,
  },
}));

//Type to store navbar link targets
type NavTarget = {
  route: string; //Route that the navbar item links to
  name: string; //Name displayed in the navbar
  prefix: IconPrefix; //fa icon prefix for mobile bottom navbar
  icon: IconName; //fa icon name for mobile bottom navbar
};
// list of navigation targets (want to use tachometer-alt)
const navTargets: Array<NavTarget> = [
  {
    route: '/wallet',
    name: 'WALLET',
    prefix: 'far',
    icon: 'wallet',
  },
  {
    route: '/marketplace',
    name: 'MARKETPLACE',
    prefix: 'far',
    icon: 'store',
  },
  {
    route: '/pos',
    name: 'POS',
    prefix: 'far',
    icon: 'cash-register',
  },
];
//List of navtargets displayed to unauthenticated users
const unauthenticatedNavTargets: Array<NavTarget> = [
  {
    route: '/about',
    name: 'ABOUT',
    prefix: 'far',
    icon: 'info-circle',
  },

  {
    route: '/marketplace',
    name: 'MARKETPLACE',
    prefix: 'far',
    icon: 'store',
  },
  {
    route: '/help',
    name: 'HELP',
    prefix: 'far',
    icon: 'cash-register',
  },
  {
    route: '/login',
    name: 'LOGIN',
    prefix: 'far',
    icon: 'user',
  },
];

type HeaderProps = {
  children?: React.ReactNode;
};

// FIXLATER
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function Header({ children }: HeaderProps) {
  const classes = useStyles();
  const theme = useTheme();
  const [navOpen, setNavOpen] = useState(false);

  // track authentication to manage visible elements in menu/app bar
  const isAuthenticated = useSelector(selectAuthenticated);
  const merchant = useSelector(selectUserRole);
  const loginUser = useSelector(selectLoginUser);
  const showPOS = (target: NavTarget) => target.name !== 'POS' || merchant;

  // for dropdown menu below logo
  const [generalInfoOpen, setGeneralInfoOpen] = useState(false);
  const logoRef = useRef(null);
  const [generalArrowRef, setGeneralArrowRef] =
    useState<HTMLSpanElement | null>(null);

  // for user info menu at account circle
  const [userInfoOpen, setUserInfoOpen] = useState(false);
  const linkedProfiles = useSelector(selectProfiles);
  const accountRef = useRef(null);
  const [arrowRef, setArrowRef] = useState<HTMLSpanElement | null>(null);
  // for logout confirmation dialog
  const [logoutDialogOpen, setLogoutDialogOpen] = useState(false);

  // for qr scanner
  const [qrScannerOpen, setQRScannerOpen] = useState(false);

  // handle click of account circle to open user menu
  const toggleMenu = () => {
    setUserInfoOpen(!userInfoOpen);
  };

  const handleMenuClose = useCallback(() => {
    setUserInfoOpen(false);
  }, [setUserInfoOpen]);

  // handle click of logo to open menu
  const toggleLogoMenu = () => setGeneralInfoOpen(!generalInfoOpen);

  // handle click of logout option in user menu
  const dispatch = useDispatch();
  const ws = useContext(WebSocketContext) as WS;
  const switchProfile = (loginID: USERUUID) => {
    dispatch(switchAccounts(loginID, loginID, ws));
  };
  const logoutProfile = (accountID: string) => {
    handleMenuClose();
    dispatch(logoutAccount(accountID, ws.socket));
  };
  const logoutAllProfiles = () => {
    handleMenuClose();
    dispatch(logoutAllAccounts(ws.socket));
  };

  // enable closing menu with Esc key

  const handleEscape = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        handleMenuClose();
      }
    },
    [handleMenuClose]
  );
  useEffect(() => {
    document.addEventListener('keydown', handleEscape);
    // Remove event listeners on cleanup
    return () => {
      document.removeEventListener('keydown', handleEscape);
    };
  }, [handleEscape]);

  // open qr scanner
  const handleQRScanner = () => {
    setQRScannerOpen(true);
  };

  // for showing which top-level navigation route is active
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState('/wallet');
  // necessary for additional BottomNavigation active styling
  useEffect(() => {
    setCurrentPage(location.pathname);
  }, [location]);

  // wrapped NavLink component with active link styling
  const CustomNavLink = React.forwardRef<HTMLAnchorElement, LinkProps>(
    (linkProps, ref) => (
      <NavLink ref={ref} activeClassName={classes.selected} {...linkProps} />
    )
  );

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, { [classes.appBarShift]: navOpen })}
      >
        <Toolbar>
          <Grid container spacing={1} alignItems="center" justify="center">
            {/* ZUZ logo on navbar icon */}
            <Grid item xs={2}>
              <LogotypeWhite
                ref={logoRef}
                onClick={toggleLogoMenu}
                className={classes.logotype}
              />
            </Grid>

            <Popper
              open={generalInfoOpen}
              anchorEl={logoRef.current}
              placement="bottom-start"
              disablePortal
              className={classes.dropdown}
              modifiers={{ arrow: { element: generalArrowRef } }}
            >
              <span className={classes.arrow} ref={setGeneralArrowRef} />
              <Paper className={classes.popperPaper}>
                <ClickAwayListener
                  onClickAway={() => setGeneralInfoOpen(false)}
                >
                  <MenuList>
                    <MenuItem
                      onClick={() => setGeneralInfoOpen(false)}
                      component={Link}
                      to={'/about'}
                    >
                      About
                    </MenuItem>
                    <MenuItem
                      onClick={() => setGeneralInfoOpen(false)}
                      component={Link}
                      to={'/help'}
                    >
                      Help
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Popper>

            <Grid item xs={10}>
              {isAuthenticated ? (
                <div className={classes.toolbarButtons}>
                  {/* Authenticated Desktop navbar items */}
                  <Hidden smDown>
                    {/* Display authenticated navbar items */}
                    {navTargets.map((target) => {
                      return (
                        showPOS(target) && (
                          <MenuItem
                            key={target.name}
                            to={target.route}
                            component={CustomNavLink}
                            className={classes.unselected}
                          >
                            {target.name}
                          </MenuItem>
                        )
                      );
                    })}
                  </Hidden>

                  {/* Hide QR code scanner on desktop */}
                  <Hidden mdUp>
                    <IconButton
                      className={classes.contrastIcon}
                      onClick={handleQRScanner}
                    >
                      <FontAwesomeIcon icon={['far', 'barcode-read']} />
                    </IconButton>
                  </Hidden>

                  <IconButton
                    ref={accountRef}
                    edge="end"
                    aria-label="account of current user"
                    // aria-controls={menuId}
                    aria-haspopup="true"
                    onClick={toggleMenu}
                    className={classes.contrastIcon}
                  >
                    {/* Display merchant icon if user is a merchant and profile icon otherwise */}
                    {merchant ? (
                      <FontAwesomeIcon icon={['far', 'store']} />
                    ) : (
                      <FontAwesomeIcon icon={['far', 'user']} />
                    )}
                  </IconButton>

                  {/* user menu callout */}

                  <Popper
                    open={userInfoOpen}
                    anchorEl={accountRef.current}
                    placement="bottom-end"
                    disablePortal
                    className={classes.dropdown}
                    modifiers={{ arrow: { element: arrowRef } }}
                  >
                    <span className={classes.arrow} ref={setArrowRef} />
                    <Paper className={classes.popperPaper}>
                      <ClickAwayListener onClickAway={handleMenuClose}>
                        <MenuList>
                          {/* display current user info */}
                          {linkedProfiles.map((profile, i) => (
                            <div key={i}>
                              {profile && profile.profileData && (
                                // Switch profile when an account is clicked
                                <MenuItem
                                  onClick={() => {
                                    //loginID and loginUser.id are different types
                                    // eslint-disable-next-line eqeqeq
                                    if (profile.loginID != loginUser?.id) {
                                      switchProfile(profile.loginID);
                                    }
                                  }}
                                >
                                  <div
                                    style={{
                                      display: 'flex',
                                      flex: 1,
                                      alignItems: 'baseline',
                                      justifyContent: 'space-between',
                                    }}
                                  >
                                    {/* Style profile based on if it's selected */}
                                    <div
                                      style={{
                                        marginRight: 50,
                                        color:
                                          //loginID and loginUser.id are different types
                                          // eslint-disable-next-line eqeqeq
                                          profile.loginID == loginUser?.id
                                            ? theme.palette.secondary.main
                                            : theme.palette.secondary
                                                .contrastText,
                                      }}
                                    >
                                      {profile.profileData.username}
                                    </div>
                                    {/* Log out icon */}
                                    <div>
                                      <IconButton
                                        onClick={() =>
                                          logoutProfile(profile.loginID)
                                        }
                                        disabled={
                                          //loginID and loginUser.id are different types
                                          // eslint-disable-next-line eqeqeq
                                          profile.loginID != loginUser?.id
                                        }
                                        size="small"
                                        color="secondary"
                                      >
                                        <FontAwesomeIcon
                                          icon={['far', 'sign-out']}
                                        />
                                      </IconButton>
                                    </div>
                                  </div>
                                </MenuItem>
                              )}
                            </div>
                          ))}
                          <Divider tabIndex={-1} />

                          <MenuItem
                            onClick={handleMenuClose}
                            component={Link}
                            to={'/profile'}
                          >
                            Transactions
                          </MenuItem>
                          <MenuItem
                            onClick={handleMenuClose}
                            component={Link}
                            to={'/settings'}
                          >
                            Settings
                          </MenuItem>
                          <MenuItem onClick={() => logoutAllProfiles()}>
                            Logout of all Accounts
                          </MenuItem>
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Popper>
                </div>
              ) : (
                <div className={classes.toolbarButtons}>
                  {/* Unauthenticated Desktop navbar items */}
                  <Hidden smDown>
                    {/* unauthenticated navbar items*/}
                    {unauthenticatedNavTargets.map((target) => {
                      return (
                        showPOS(target) && (
                          <MenuItem
                            key={target.name}
                            to={target.route}
                            component={CustomNavLink}
                            className={classes.unselected}
                          >
                            {target.name}
                          </MenuItem>
                        )
                      );
                    })}
                  </Hidden>
                  {/* Unauthenticated Mobile Login Icon */}
                  <Hidden mdUp>
                    {unauthenticatedNavTargets
                      .filter((target) => target.name === 'LOGIN')
                      .map((target) => (
                        <MenuItem
                          key={target.name}
                          to={target.route}
                          component={CustomNavLink}
                          className={classes.unselected}
                        >
                          <FontAwesomeIcon
                            icon={[target.prefix, target.icon]}
                          />
                        </MenuItem>
                      ))}
                  </Hidden>
                </div>
              )}
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Fragment>
        {logoutDialogOpen && (
          <ConfirmLogout
            setNavOpen={setNavOpen}
            setDialogOpen={setLogoutDialogOpen}
          />
        )}
        {/* bottom navigation for mobile - hidden on desktop */}
        <Hidden mdUp>
          <BottomNavigation
            value={currentPage}
            className={clsx(classes.bottomNav, classes.unselected)}
          >
            {isAuthenticated
              ? navTargets.map((target) => {
                  return (
                    showPOS(target) && (
                      <BottomNavigationAction
                        key={target.name}
                        label={target.name}
                        value={target.route}
                        icon={
                          <FontAwesomeIcon
                            icon={[target.prefix, target.icon]}
                          />
                        }
                        component={CustomNavLink}
                        to={target.route}
                        className={classes.navIcon}
                      />
                    )
                  );
                })
              : unauthenticatedNavTargets
                  .filter(
                    (target) =>
                      !['LOGIN', 'ABOUT', 'HELP'].includes(target.name)
                  )
                  .map((target) => {
                    return (
                      showPOS(target) && (
                        <BottomNavigationAction
                          key={target.name}
                          label={target.name}
                          value={target.route}
                          icon={
                            <FontAwesomeIcon
                              icon={[target.prefix, target.icon]}
                            />
                          }
                          component={CustomNavLink}
                          to={target.route}
                          className={classes.navIcon}
                        />
                      )
                    );
                  })}
          </BottomNavigation>
        </Hidden>
      </Fragment>
      <div className={classes.content}>
        <Container maxWidth="lg">
          <Fragment>{children}</Fragment>
        </Container>
      </div>
      {qrScannerOpen && <QRScanner openCloseQR={setQRScannerOpen} />}
    </div>
  );
}

export default Header;
