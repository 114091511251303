import { Dispatch } from 'redux';
import { authAxios } from '../services/serviceUtils';
import { IAppState } from '../redux/reducers/reducer';
import { getSingleUserAction } from '../redux/actions/usersAction';
import { error } from '../redux/actions/alertAction';
import { searchUserByIdURL } from './api';

// sample usage:
// getState = useStore().getState
// dispatch = useDispatch()
// const getUserByIdCurrent = getUserById(dispatch, getState)
// getUserByIdCurrent("4")
// The function returns the information of a user given its unique id.
// If the information is not currently stored in the state, the function will
// fetch the data from the server and store it to the state.

export const getUserById: (
  dispatch: Dispatch,
  getState: () => IAppState
) => (uid: string) => Promise<Record<string, any>> =
  (dispatch: Dispatch, getState: () => IAppState) => async (userId: string) => {
    if (userId in getState().users) {
      // if the wanted user is already in the state and has its businessName stored,
      // just return it
      return getState().users[userId];
    }

    // fetch user info from the server
    const response = await authAxios
      .get(searchUserByIdURL(userId))
      .catch((err) => {
        console.log(err);
        return err.response;
      });

    const res = response.data;

    if (res.status === 'error') {
      dispatch(error(res.msg));
      return null;
    } else {
      // store the fetched info to redux state
      dispatch(getSingleUserAction(res.user));
      return res.user;
    }
  };

// The API call to get user data from server according to its id

// FIXLATER
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const userSearchById = async (userId: string) => {
  // send post request to backend
  const response = await authAxios
    .get(searchUserByIdURL(userId))
    .catch((err) => {
      console.log(err);
      return err.response;
    });

  // the response should not be undefned
  if (response === undefined) {
    console.log(
      'alert: response is undefined for get request to /user/' + userId
    );
  }
  return response?.data;
};
