import Title from '../common/Title';
import React, { Fragment, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import AcceptTransaction, {
  ACCTXNDIALOG,
} from '../dashboard/AcceptTransaction';
import { getIncomingTransactionsAsArray } from '../../redux/selectors';
import BoltView from '../common/BoltView';
import TransactionLine from '../common/TransactionLine';
import { IconButton, Grid, Divider } from '@material-ui/core';
import { ExpandMore, ExpandLess } from '@material-ui/icons';

// FIXLATER
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function PendingTransactions() {
  const incomingTransactions = useSelector(getIncomingTransactionsAsArray);

  const [acceptDialogState, setAcceptDialogState] = useState<ACCTXNDIALOG>({
    isOpen: false,
  });

  // Callbacks for transaction detail view
  const [txnDialogOpen, setTxnDialogOpen] = useState(false);
  const [chosenBoltID, setChosenBoltID] = useState(''); // the bolt card user clicked
  const [showAll, setShowAll] = useState(false);
  const [transactionsList, setTransactionsList] =
    useState(incomingTransactions);

  useEffect(() => {
    if (incomingTransactions.length > 2) {
      setTransactionsList(incomingTransactions.slice(0, 2));
    } else {
      setTransactionsList(incomingTransactions);
    }
  }, [incomingTransactions]);

  const expand = () => {
    if (showAll) {
      setTransactionsList(incomingTransactions.slice(0, 2));
    } else {
      setTransactionsList(incomingTransactions);
    }
    setShowAll(!showAll);
  };
  if (incomingTransactions.length > 0) {
    return (
      <Fragment>
        <Title>Incoming Transactions</Title>
        <Grid container justify="center">
          <Grid item xs={12}>
            {transactionsList.map((transaction) => (
              <TransactionLine
                key={transaction._id}
                variant="incoming"
                transaction={transaction}
                setAcceptDialogState={setAcceptDialogState}
                setTxnDialogOpen={setTxnDialogOpen}
                setChosenBoltID={setChosenBoltID}
              />
            ))}
          </Grid>
          {incomingTransactions.length > 2 && (
            <Grid item xs={12} style={{ textAlign: 'center' }}>
              <IconButton size="small" onClick={expand}>
                {showAll ? (
                  <ExpandLess fontSize="large" />
                ) : (
                  <ExpandMore fontSize="large" />
                )}
              </IconButton>
            </Grid>
          )}
        </Grid>

        {acceptDialogState.isOpen && (
          <AcceptTransaction
            dialogState={acceptDialogState}
            setDialogState={setAcceptDialogState}
          />
        )}
        {txnDialogOpen && (
          <BoltView boltID={chosenBoltID} onCloseView={setTxnDialogOpen} />
        )}
        <Divider />
      </Fragment>
    );
  } else {
    return <></>;
  }
}

export default PendingTransactions;
