import { AlertActionsTypes } from './actionTypes';

// action for a success alert
// FIXLATER
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function success(message: string) {
  return {
    type: AlertActionsTypes.SUCCESS,
    payload: { message },
  };
}

// action for an error alert
// FIXLATER
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function error(message: string) {
  return {
    type: AlertActionsTypes.ERROR,
    payload: { message },
  };
}

// action for clearing alert
// FIXLATER
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function clear() {
  return { type: AlertActionsTypes.CLEAR };
}
