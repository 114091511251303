/* @brief Landing page for the marketplace
 *
 * There are two tabs: "merchants" and "active campaigns"
 * The "merchants" tab contains two grids - "merchants that you have zuz for" and "all zuz merchants". The "active campaigns" tab consists of one grid for "active campaigns".
 * Each grid consists of cards with information about the business, zuz that you own (if any), and active campaign (if any). When a card is clicked, the app navigates to a new page with more details about the business and its campaign.
 * Can filter results (all three grids) by category.
 */
import React, { useEffect, useState } from 'react';
import {
  Container,
  Typography,
  useTheme,
  useMediaQuery,
  CircularProgress,
  makeStyles,
  Grid,
} from '@material-ui/core/';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import userSearchService from '../../services/userSearchService';
import { useDispatch } from 'react-redux';
import { error } from '../../redux/actions/alertAction';
import { useSelector } from 'react-redux';
import { IAppState } from '../../redux/reducers/reducer';
import { selectActingUser } from '../../redux/selectors';
import FilterContainer from './FilterContainer';
import GridListContainer from './GridListContainer';
import { IssuerData, TileData, CAMPAIGNDATA } from '../../utils/types';

const useStyles = makeStyles((theme) => ({
  body: {
    display: 'flex',
    flexDirection: 'row',
  },
  filterContainer: {
    flexDirection: 'column',
    flex: 1,
    paddingLeft: '1%',
  },
  content: {
    flexDirection: 'column',
    width: '100%',
    flex: 4,
  },
  tile: {
    width: '100%',
    height: '100%',
    borderRadius: 8,
    border: '1px solid ' + theme.palette.primary.light,
    padding: theme.spacing(2),
    boxShadow: '0px 10px 10px #CCCCCC',
    backgroundColor: theme.palette.background.default,
    transition: 'background-color .4s, box-shadow .4s',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.background.paper,
      boxShadow: '0px 4px 4px #CCCCCC',
    },
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  viewToggleGroup: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  viewToggle: {
    padding: '0 1%',
    borderRadius: 20,
    textTransform: 'none',
    fontStyle: 'italic',
  },
}));

type Merchant = IssuerData;

/*
{
  userId: string;
  businessName: string;
  logo: string;
  ZUZOwned: number;
  acceptedZuz: string;
  goal: number;
  raised: number;
  categoryId: number;
  campaigns: Array<string>;
};
*/

//landing page for web and mobile
// FIXLATER
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function Vendors() {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const isMobile = !useMediaQuery(theme.breakpoints.up('sm'));

  //parameters for filter
  const [categoryId, setCategoryId] = React.useState<number>(-1);
  const categories = [
    { id: 1, name: 'Cafe/Bakery' },
    { id: 2, name: 'Grocery' },
    { id: 3, name: 'Bookstore' },
    { id: 4, name: 'Restaurant' },
    { id: 5, name: 'Hardware stores' },
  ];

  //get all bolts in wallet that are not your own bolts
  let bolts = useSelector((state: IAppState) => Object.values(state.userBolts));

  const actingUser = useSelector(selectActingUser);

  //keys are userIds, values are data objects for "merchants you have zuz for" grid
  const [walletData, setWalletData] = React.useState<{
    [id: string]: TileData;
  }>({});
  //data objects for "all zuz merchants" grid
  const [merchantData, setMerchantData] = React.useState<Array<TileData>>([]);
  //keys are userIds, values are data for "active campaigns" grid
  const [campaignData, setCampaignData] = React.useState<{
    [id: string]: TileData;
  }>({});

  //loading status for page
  const [isLoading, setIsLoading] = useState(true);

  //toggles between "merchants" and "active campaigns"
  const [viewToggle, setViewToggle] = React.useState<string | null>(
    'merchants'
  );
  const switchView = (
    event: React.MouseEvent<HTMLElement>,
    newView: string | null
  ) => {
    if (newView !== null) {
      setViewToggle(newView);
    }
  };
  const isCampaign = viewToggle && viewToggle === 'active campaigns';

  //initializes data for three grids
  useEffect(() => {
    //groups bolts by merchant
    const boltsMap: { [id: string]: TileData } = {};

    bolts = bolts.filter(
      (bolt) => bolt.bolt.issuer.toString() !== actingUser?.id
    );

    bolts.forEach((bolt) => {
      if (boltsMap[bolt.bolt.issuer]) {
        boltsMap[bolt.bolt.issuer].ZUZOwned += bolt.amount;
      } else {
        boltsMap[bolt.bolt.issuer] = {
          userId: bolt.bolt.issuer,
          businessName: '',
          logo: '',
          ZUZOwned: bolt.amount,
          goal: 0,
          raised: 0,
          categoryId: 0,
        };
      }
    });

    //gets list of all merchant information, including ids for active campaigns
    const params = { campaign: true };
    if (categoryId > -1) {
      return;
    }

    userSearchService
      .issuerSearchService(params)
      .then((merchants: Array<IssuerData>) => {
        const campaignData: { [id: string]: TileData } = {};
        merchants.forEach((merchant: Merchant) => {
          //updates "merchants you own zuz for" with business information and updates "all zuz merchants" with zuz owned information
          if (boltsMap[merchant.userId]) {
            bolts.forEach((bolt) => {
              if (merchant.acceptedZuz[0] === bolt.bolt.specID) {
                boltsMap[merchant.userId].ZUZOwned += bolt.amount;
              }
            });
            boltsMap[merchant.userId].businessName = merchant.businessName;
            boltsMap[merchant.userId].logo = merchant.logo as string;
            boltsMap[merchant.userId].categoryId = merchant.categoryId;

            merchant.ZUZOwned = boltsMap[merchant.userId].ZUZOwned;
          }

          //get details about all active merchant campaigns
          if (merchant.campaigns) {
            //console.log(merchant.campaigns);
            merchant.campaigns.forEach((campaign: CAMPAIGNDATA) => {
              if (campaign.videos === undefined) campaign.videos = [];
              const campaignId = campaign.code;
              //updates "merchants you own zuz for" and "active campaigns" with campaign information
              if (boltsMap[merchant.userId]) {
                boltsMap[merchant.userId].goal = campaign.goal.amount;
                boltsMap[merchant.userId].raised =
                  campaign.goal.completed === undefined
                    ? 0
                    : campaign.goal.completed;
                campaignData[campaignId] = boltsMap[merchant.userId];
              } else {
                campaignData[campaignId] = {
                  userId: merchant.userId,
                  businessName: merchant.businessName,
                  logo: campaign.logo,
                  ZUZOwned: 0,
                  goal: campaign.goal.amount,
                  raised:
                    campaign.goal.completed === undefined
                      ? 0
                      : campaign.goal.completed,
                  categoryId: merchant.categoryId,
                };
              }
              //updates "all zuz merchants" with campaign information
              merchant.goal = campaign.goal.amount;
              merchant.raised = campaign.goal.completed as number;
            });
          }

          //filters "all zuz merchants" data grid based on categoryId
          if (walletData[merchant.userId]) {
            merchant.ZUZOwned = walletData[merchant.userId].ZUZOwned;
            merchant.goal = walletData[merchant.userId].goal;
            merchant.raised = walletData[merchant.userId].raised;
          } else {
            const filterAmnts = bolts.filter(
              (bolt) => bolt.bolt.issuer === merchant.userId
            );
            const mapAmnts = filterAmnts.map((bolt) => bolt.amount);
            merchant.ZUZOwned = mapAmnts.reduce((sum, x) => sum + x, 0);

            // TODO: think about this.  should this be sum.  since we
            // include campaign data with merchant, we can construct
            // this differently.
            merchant.campaigns.forEach((campaign) => {
              merchant.goal = campaign.goal.amount;
              merchant.raised =
                campaign.goal.completed === undefined
                  ? 0
                  : campaign.goal.completed;
            });
          }

          // If user is not logged in, clear zuz owned
          if (!actingUser) {
            if (merchant) merchant.ZUZOwned = 0;
            if (walletData[merchant.userId])
              walletData[merchant.userId].ZUZOwned = 0;
            if (boltsMap[merchant.userId])
              boltsMap[merchant.userId].ZUZOwned = 0;
          }
        });

        setWalletData(boltsMap);
        setMerchantData(merchants as Array<TileData>);
        setCampaignData(campaignData);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(`1 ${err}`);
        dispatch(error(`Error getting issuers: ${err}`));
      });
  }, [categoryId, actingUser]);

  return (
    <Container maxWidth="lg" style={{ marginTop: '40px' }}>
      <Typography component="h3" variant="h4" gutterBottom>
        Marketplace
      </Typography>
      {isMobile ? (
        <div>
          <ToggleButtonGroup
            value={viewToggle}
            exclusive
            onChange={switchView}
            className={classes.viewToggleGroup}
          >
            <ToggleButton value="merchants" className={classes.viewToggle}>
              Merchants
            </ToggleButton>
            <ToggleButton
              value="active campaigns"
              className={classes.viewToggle}
            >
              Active Campaigns
            </ToggleButton>
          </ToggleButtonGroup>
          {isLoading ? (
            <CircularProgress />
          ) : isCampaign ? (
            <GridListContainer
              title="Active Campaigns"
              tileData={
                categoryId > -1
                  ? Object.values(campaignData).filter(
                      (tile) => tile.categoryId === categoryId
                    )
                  : Object.values(campaignData)
              }
            />
          ) : (
            <div>
              {actingUser && (
                <GridListContainer
                  title="Merchants you have ZUZ for"
                  tileData={
                    categoryId > -1
                      ? Object.values(walletData).filter(
                          (tile) => tile.categoryId === categoryId
                        )
                      : Object.values(walletData)
                  }
                />
              )}
              <GridListContainer
                title="All ZUZ merchants"
                tileData={merchantData}
              />
            </div>
          )}
        </div>
      ) : (
        <Grid container spacing={2}>
          <Grid item sm={4} md={2}>
            <FilterContainer
              categoryId={categoryId}
              setCategoryId={setCategoryId}
              categories={categories}
            />
          </Grid>
          <Grid item sm={8} md={10}>
            <div className={classes.content}>
              <ToggleButtonGroup
                value={viewToggle}
                exclusive
                onChange={switchView}
                className={classes.viewToggleGroup}
              >
                <ToggleButton value="merchants" className={classes.viewToggle}>
                  Merchants
                </ToggleButton>
                <ToggleButton
                  value="active campaigns"
                  className={classes.viewToggle}
                >
                  Active Campaigns
                </ToggleButton>
              </ToggleButtonGroup>
              {isLoading ? (
                <CircularProgress />
              ) : isCampaign ? (
                <GridListContainer
                  title="Active Campaigns"
                  tileData={
                    categoryId > -1
                      ? Object.values(campaignData).filter(
                          (tile) => tile.categoryId === categoryId
                        )
                      : Object.values(campaignData)
                  }
                />
              ) : (
                <div>
                  {actingUser && (
                    <GridListContainer
                      title="Merchants you have ZUZ for"
                      tileData={
                        categoryId > -1
                          ? Object.values(walletData).filter(
                              (tile) => tile.categoryId === categoryId
                            )
                          : Object.values(walletData)
                      }
                    />
                  )}
                  <GridListContainer
                    title="All ZUZ merchants"
                    tileData={merchantData}
                  />
                </div>
              )}
            </div>
          </Grid>
        </Grid>
      )}
    </Container>
  );
}

export default Vendors;
