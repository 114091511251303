import React from 'react';
import {
  makeStyles,
  Theme,
  Typography,
  createStyles,
  Dialog,
  DialogContent,
  IconButton,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { IAuthStateData } from '../../redux/reducers/authReducer';
import { UsersState } from '../../redux/reducers/usersReducer';
import { BoltState } from '../../redux/reducers/boltReducer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BoltLogo from './BoltLogo';
import { asCurrencyString } from '../../utils/money';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    boltAmount: {
      display: 'flex',
      marginTop: 5,
      justifyContent: 'center',
    },
    boltDetails: {
      marginTop: 20,
      display: 'flex',
      flexWrap: 'wrap',
      width: '70%',
    },
    boltDetailName: {
      flex: '50%',
      color: theme.palette.secondary.contrastText,
      textTransform: 'uppercase',
      marginBottom: 5,
    },
    boltDetailValue: {
      flex: '50%',
      textAlign: 'right',
      marginBottom: 5,
    },
    boltViewPaper: {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.secondary.main,
      alignItems: 'center',
      padding: 10,
    },
    boltViewContent: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
    },
    dialogClose: {
      alignSelf: 'flex-end',
    },
    buttonSection: {
      display: 'flex',
      alignItems: 'center',
      position: 'absolute',
      justifyContent: 'center',
      bottom: 0,
      paddingBottom: 20,
      width: '100%',
    },
    actionButton: {
      margin: 5,
      padding: '10px 15px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      transition: 'background-color 0.5s',
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: theme.palette.grey[200],
      },
    },
  })
);

type BoltViewProps = {
  logo?: any;
  boltID: string;
  onCloseView: (isOpen: boolean) => void;
};

type REDUXSTATE = {
  authState: IAuthStateData;
  users: UsersState;
  userBolts: BoltState;
};

// FIXLATER
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function BoltView(props: BoltViewProps) {
  const { boltID, onCloseView } = props;
  const bolt = useSelector((state: REDUXSTATE) => state.userBolts[boltID]);
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog
      onClose={() => onCloseView(false)}
      open={true}
      fullScreen={fullScreen}
      maxWidth={'md'}
      classes={{
        paper: classes.boltViewPaper,
      }}
    >
      <DialogContent className={classes.boltViewContent}>
        <IconButton
          className={classes.dialogClose}
          onClick={() => onCloseView(false)}
        >
          <FontAwesomeIcon
            icon={['far', 'times-circle']}
            color={theme.palette.error.main}
            size="1x"
          />
        </IconButton>
        <BoltLogo specID={bolt.bolt.specID} />
        <br></br>
        <Typography variant="h5" component="h2">
          <b>{bolt.bolt.specName}</b>
        </Typography>
        <div className={classes.boltAmount}>
          <Typography variant="body1" component="p">
            <b>{asCurrencyString(bolt.amount)}</b>
          </Typography>
        </div>
        <div className={classes.boltDetails}>
          <Typography component="h6" className={classes.boltDetailName}>
            ZUZ ID
          </Typography>
          <Typography component="h6" className={classes.boltDetailValue}>
            {bolt.bolt.specID.slice(bolt.bolt.specID.length - 10)}
          </Typography>
          <Typography component="h6" className={classes.boltDetailName}>
            Issuer Name
          </Typography>
          <Typography component="h6" className={classes.boltDetailValue}>
            {bolt.bolt.issuerName}
          </Typography>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default BoltView;
