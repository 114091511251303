import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, useTheme } from '@material-ui/core';
import React, { FC, useState, useEffect } from 'react';
import { getImageURL } from '../../services/api';
import { getBoltLogo } from '../../services/campaignService';

type BoltLogoProps = {
  specID: string;
};

const BoltLogo: FC<BoltLogoProps> = ({ specID }) => {
  const theme = useTheme();

  const [logo, setLogo] = useState<string>('');

  useEffect(() => {
    getBoltLogo(specID).then((path) => {
      setLogo(path);
    });
  }, []);

  return logo ? (
    <Avatar variant="rounded" src={getImageURL(logo)} />
  ) : (
    <FontAwesomeIcon
      icon={['far', 'store']}
      color={theme.palette.primary.main}
      size="3x"
    />
  );
};

export default BoltLogo;
