import axios, { AxiosError as NativeAxiosError } from 'axios';
import { endpoint } from './api';

export const noauthAxios = axios.create({
  baseURL: endpoint,
});

export const authAxios = axios.create({
  baseURL: endpoint,
  headers: {
    // this is whatever token we happen to have at this time, good
    // for reload, but not if they login again.  If so, make sure
    // when token is updated, that updateAuthAxios is called.
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  },
});

let currentIDs = { actingID: '?', loginID: '?' };

// we got a new token, so update the header
export const updateAuthAxios = (
  token: string,
  actingID: string,
  loginID: string
): void => {
  console.log(`uaa: acting:${actingID} login:${loginID}`);
  currentIDs = { actingID, loginID };
  authAxios.defaults.headers['Authorization'] = `Bearer ${token}`;
};

//Same as auth call as of April 26 2021, only diff is content-type header
export const formAxios = axios.create({
  baseURL: endpoint,
  headers: {
    // this is whatever token we happen to have at this time, good
    // for reload, but not if they login again.  If so, make sure
    // when token is updated, that updateAuthAxios is called.
    Authorization: `Bearer ${localStorage.getItem('token')}`,
    'Content-Type': 'multipart/form-data',
  },
});

// function to log errors.  Calls an unauthenticated endpoint, but
// includes user information if available.
export async function logAxios(msg: string): Promise<void> {
  const data = { ...currentIDs, msg };
  await noauthAxios.post('/general/logFEerror', data).catch((err) => {
    console.log(err);
  });
}

export type AxiosError = NativeAxiosError;
