import { DRYTXNDATA, ITXNDATA, TXNSTATUS } from '../../utils/types';
import {
  AccountDataActionsTypes,
  AuthActionsTypes,
  TxnActionsTypes,
} from '../actions/actionTypes';
import { AccountDataClearActiveAction } from './accountDataReducer';
import { ISwitchAccountAction } from './authReducer';
import { logerrBackend } from '../../utils/logger';

// types in store
export interface TxnDrySubState {
  [key: string]: DRYTXNDATA;
}
export interface TxnDryState {
  incoming: TxnDrySubState;
  outgoing: TxnDrySubState;
  finalized: TxnDrySubState;
}

// hydrated types
export interface TxnSubState {
  [key: string]: ITXNDATA;
}
export interface TxnState {
  // initiated transactions waiting on currently authenticated user for confirmation
  incoming: TxnSubState;
  // non-finalized transactions initiated by currently authenticated user
  outgoing: TxnSubState;
  // finalized (accepted or declined) transactions
  finalized: TxnSubState;
}

const initialState: TxnDryState = {
  incoming: {},
  outgoing: {},
  finalized: {},
};

interface TransactionBatchAction {
  type: TxnActionsTypes.GET_TXNS;
  payload: {
    actingID: string;
    transactions: Array<DRYTXNDATA>;
  };
}

interface TransactionSingleAction {
  type:
    | TxnActionsTypes.INIT_TXN
    | TxnActionsTypes.RECEIVE_TXN
    | TxnActionsTypes.UPDATE_TXN
    | TxnActionsTypes.ACCEPT_REJECT_TXN
    | TxnActionsTypes.INIT_SQR_TXN;
  payload: {
    transaction: DRYTXNDATA;
    firstTime?: boolean;
  };
}

type TransactionAction =
  | TransactionBatchAction
  | TransactionSingleAction
  | ISwitchAccountAction
  | AccountDataClearActiveAction;

// FIXLATER
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function txnReducer(
  state: TxnDryState = initialState,
  action: TransactionAction
) {
  switch (action.type) {
    //Reducer for getting transactions
    case TxnActionsTypes.INIT_SQR_TXN:
      return state;
    case TxnActionsTypes.GET_TXNS:
      const txns = action.payload.transactions.reduce(
        (transactions: TxnDryState, txn: DRYTXNDATA) => {
          if (txn.status === TXNSTATUS.INITIATED) {
            // incoming transaction
            if (
              txn.receiver.toString() === action.payload.actingID.toString()
            ) {
              transactions.incoming[txn._id] = txn;
            }
            // outgoing transaction
            if (txn.sender.toString() === action.payload.actingID.toString()) {
              transactions.outgoing[txn._id] = txn;
            }
          }
          // finalized transaction
          else {
            transactions.finalized[txn._id] = txn;
          }
          return transactions;
        },
        state
      );
      // need new values for incoming/outgoing/finalized to force rerender
      return {
        incoming: { ...txns.incoming },
        outgoing: { ...txns.outgoing },
        finalized: { ...txns.finalized },
      };
    //Reducer for receiving transaction
    case TxnActionsTypes.RECEIVE_TXN:
      const newTxn = {
        status: action.payload.transaction.status,
        amount: action.payload.transaction.amount,
        sender: action.payload.transaction.sender,
        receiver: action.payload.transaction.receiver,
        bolt: action.payload.transaction.bolt,
        createdAt: action.payload.transaction.createdAt,
        updatedAt: action.payload.transaction.updatedAt,
        _id: action.payload.transaction._id,
        kind: action.payload.transaction.kind,
      };
      if (newTxn.status === 'confirmed') {
        // this is coming in because it is a new onestep transaction
        if (!action.payload.firstTime) {
          if (newTxn._id in state.incoming) {
            console.log(state.incoming);
            logerrBackend(
              `ERROR - confirmed ${newTxn._id}  is already in incoming!`,
              false
            );
          } else if (newTxn._id in state.finalized) {
            console.log(state.finalized);
            logerrBackend(
              `ERROR - confirmed ${newTxn._id}  is already in finalized!`,
              false
            );
          }
        }
        console.log(
          `Adding ${action.payload.transaction.bolt} of ${action.payload.transaction.amount}`
        );
        return {
          ...state,
          finalized: {
            ...state.finalized,
            [newTxn._id]: newTxn,
          },
        };
      } else {
        // this is old method requiring confirmation
        return {
          ...state,
          incoming: {
            ...state.incoming,
            [newTxn._id]: newTxn,
          },
        };
      }
    // Reducer for updating transaction from outgoing to finalized
    // (accepted or declined)
    case TxnActionsTypes.UPDATE_TXN:
      const updateTxn = action.payload.transaction;
      // delete from outgoing transactions
      const { [updateTxn._id]: prevTxn, ...newOutgoing } = state.outgoing;
      console.log(prevTxn);
      //if transaction exists in outgoing then move it, else add directly to finalized
      if (prevTxn !== undefined) {
        return {
          ...state,
          outgoing: newOutgoing,
          finalized: {
            ...state.finalized,
            [updateTxn._id]: {
              ...updateTxn,
              // TEMPORARY to address backend sending transaction ID
              // instead of bolt ID
              bolt: prevTxn.bolt,
            },
          },
        };
      } else {
        return {
          ...state,
          finalized: {
            ...state.finalized,
            [updateTxn._id]: {
              ...updateTxn,
              // TEMPORARY to address backend sending transaction ID
              // instead of bolt ID
              bolt: updateTxn.bolt,
            },
          },
        };
      }

    // Reducer for initializing transaction
    case TxnActionsTypes.INIT_TXN:
      const newInitTxn = {
        status: action.payload.transaction.status,
        amount: action.payload.transaction.amount,
        sender: action.payload.transaction.sender,
        receiver: action.payload.transaction.receiver,
        bolt: action.payload.transaction.bolt,
        createdAt: action.payload.transaction.createdAt,
        updatedAt: action.payload.transaction.updatedAt,
        _id: action.payload.transaction._id,
        kind: action.payload.transaction.kind,
      };
      return {
        ...state,
        outgoing: {
          ...state.outgoing,
          [newInitTxn._id]: newInitTxn,
        },
      };
    //Reducer for when the user accepts/rejects a transaction
    case TxnActionsTypes.ACCEPT_REJECT_TXN:
      const acceptedTxn = {
        status: action.payload.transaction.status,
        amount: action.payload.transaction.amount,
        sender: action.payload.transaction.sender,
        receiver: action.payload.transaction.receiver,
        bolt: action.payload.transaction.bolt,
        createdAt: action.payload.transaction.createdAt,
        updatedAt: action.payload.transaction.updatedAt,
        _id: action.payload.transaction._id,
        kind: action.payload.transaction.kind,
      };
      // delete from incoming transactions
      const { [acceptedTxn._id]: _, ...newIncoming } = state.incoming; // eslint-disable-line @typescript-eslint/no-unused-vars
      console.log(
        `ACCEPT_REJECT_TXN: ${action.payload.transaction._id} with ${action.payload.transaction.amount}`
      );
      return {
        ...state,
        incoming: newIncoming,
        finalized: {
          ...state.finalized,
          [acceptedTxn._id]: acceptedTxn,
        },
      };
    // Reducer to load saved account state
    case AuthActionsTypes.SWITCH:
      if (action.payload.transactions) {
        return action.payload.transactions;
      }
      return state;
    // Reducer to clear active transaction state
    case AccountDataActionsTypes.CLEAR_ACTIVE:
      return {
        incoming: {},
        outgoing: {},
        finalized: {},
      };
    default:
      return state;
  }
}

export default txnReducer;
