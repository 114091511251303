import React, { useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { CircularProgress, Grid, Typography, Avatar } from '@material-ui/core';
import { TextField } from '@material-ui/core';
import { IUSERDATA } from '../../utils/types';
import userSearchService from '../../services/userSearchService';

/*
function showkeys(x: any) {
  return JSON.stringify({
    id: x.id,
    disabled: x.disabled,
    fullWidth: x.fullwidth,
    size: x.size,
    InputLabelProps: Object.keys(x.InputLabelProps),
    InputProps: Object.keys(x.InputProps),
    inputProps: Object.keys(x.inputProps),
  });
}
*/

type UserSearchProps = {
  onSelect: (user: IUSERDATA) => void;
};

// User search field component with autocomplete support
// The component queries the user search endpoint when the input string has length >3
// The onSelect prop is called on the selected user object
// FIXLATER
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function UserSearchField(props: UserSearchProps) {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<IUSERDATA[]>([]);
  const loading = open && options?.length === 0;

  // if query is > 3 strip potential @ from name and search backend
  // for list of possible users.  If query has initial @, then strip
  // it and return stripped result, otherwise null
  const onChangeHandle = async (query: string): Promise<string | null> => {
    const oq = query;
    if (query.length >= 3 && query[0] === '@') {
      // remove leading @
      query = query.substring(1);
    }
    //console.log(`${oq} => ${query}`);
    if (query.length < 3) {
      setOptions([]);
    } else {
      const result = await userSearchService.userSearchService(query);
      const users = result.users;
      if (false) {
        if (oq != query) {
          for (const user of users) {
            user.username = '@' + user.username;
          }
        }
      }
      //console.log(users);
      setOptions(users);
      if (oq === query) return null;
      else return query;
    }
    return null;
  };

  return (
    <Autocomplete
      fullWidth
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      onChange={(event, value) => {
        //console.log(`onChange: ${value}`);
        if (value != null) {
          props.onSelect(value);
        }
      }} // calls onSelect on the selected value
      getOptionSelected={(option, value) => {
        //console.log(`${option.username}, ${value.username}`);
        return option.username === value.username;
      }}
      getOptionLabel={(option) => {
        //console.log(`gol: ${option.username}`);
        return `${option.username}`;
      }}
      filterOptions={(x) => {
        //console.log(`filterOptions: ${x}`);
        return x;
      }}
      options={options}
      loading={loading}
      renderOption={(user: IUSERDATA) => {
        //console.log(`render: ${user.username}`);
        return (
          <Grid container>
            <Grid item xs={2}>
              <Avatar
                src={user.profilePic?.toString()}
                style={{ width: 30, height: 30 }}
              />
            </Grid>
            <Grid item xs={10}>
              <Typography align="left">{`@${user.username}`}</Typography>
            </Grid>
          </Grid>
        );
      }}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            label="Payee Username"
            color="secondary"
            variant="outlined"
            onChange={(ev) => {
              // dont call the API if the user deletes or doesn't enter anything
              if (ev.target.value !== '' || ev.target.value !== null) {
                onChangeHandle(ev.target.value);
              }
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        );
      }}
    />
  );
}
