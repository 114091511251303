import { authAxios } from './serviceUtils';
import {
  delegateURL,
  userURL,
  forgotPasswordURL,
  resetPasswordURL,
  changePasswordURL,
} from './api';
import { IUSERDATA, USERUUID } from '../utils/types';
import {
  MintPermissions,
  SellPermissions,
} from '../redux/reducers/authReducer';
//Service for editing users
type eusType = (user: IUSERDATA) => any;
const editUserService: eusType = async (user: IUSERDATA) => {
  //Must be submitted in form
  const form = new FormData();
  form.append('id', user.id);
  form.append('firstName', user.firstName);
  form.append('email', user.email);
  if (user.lastName) {
    form.append('lastName', user.lastName);
  }
  if (user.profilePic) {
    form.append('profilePic', user.profilePic);
  }

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };
  console.log(user.profilePic);
  const response = await authAxios.put(userURL, form, config).catch((err) => {
    console.log(err);
    return err.response;
  });
  return response.data;
};

// Delegate permissions for current account to other users

// FIXLATER
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const delegateService = async (
  delegateUser: USERUUID,
  mintPermissions: MintPermissions,
  sellPermissions: SellPermissions,
  acceptPermissions: boolean
) => {
  const data = {
    'Content-Type': 'application/json;charset=UTF-8',
    toUserId: delegateUser,
    permissions: [
      ...(mintPermissions && Object.keys(mintPermissions).length > 0
        ? [
            {
              type: 'mint',
              mints: Object.values(mintPermissions),
            },
          ]
        : []),
      ...(sellPermissions &&
      sellPermissions.specIDs.length > 0 &&
      sellPermissions.maxLimit > 0
        ? [
            {
              type: 'sell',
              sell: sellPermissions,
            },
          ]
        : []),
      ...(acceptPermissions ? [{ type: 'accept' }] : []),
    ],
  };

  //send post request to backend
  const response = await authAxios.post(delegateURL, data).catch((err) => {
    console.log(err);
    return err.response;
  });
  return response.data;
};

//Service for forgot password functionality

// FIXLATER
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const forgotPasswordService = async (username: string) => {
  const body = {
    username: username.toLowerCase(),
  };

  const response = await authAxios
    .post(forgotPasswordURL, body)
    .catch((err) => {
      console.log(err);
      return err.response;
    });

  return response.data;
};

//Service for resetting password

// FIXLATER
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const resetPasswordService = async (
  username: string,
  token: string,
  password: string
) => {
  const body = {
    token: token,
    newPassword: password,
  };

  const response = await authAxios
    .put(resetPasswordURL(username.toLowerCase()), body)
    .catch((err) => {
      console.log(err);
      return err.response;
    });

  return response.data;
};

// FIXLATER
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getUserDelegation = async () => {
  // send get request to backend
  const response = await authAxios.get(`/user/delegations`).catch((err) => {
    console.log(err);
    return err.response;
  });
  // return json data
  return response.data;
};

// FIXLATER
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const changePasswordService = async (
  currentPassword: string,
  newPassword: string
) => {
  const body = {
    oldPwd: currentPassword,
    newPwd: newPassword,
  };

  const response = await authAxios
    .post(changePasswordURL, body)
    .catch((err) => {
      return err.response.status;
    });

  return response;
};

export {
  editUserService,
  delegateService,
  forgotPasswordService,
  resetPasswordService,
  changePasswordService,
};
