import {
  makeStyles,
  Theme,
  createStyles,
  DialogContent,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  Dialog,
  DialogTitle,
  IconButton,
  Typography,
} from '@material-ui/core';
import { IUSERDATA } from '../../utils/types';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { initTxn } from '../../redux/actions/txnAction';
import {
  selectActingID,
  selectIsDelegate,
  selectActingUser,
} from '../../redux/selectors';
import { BoltState } from '../../redux/reducers/boltReducer';
import ActionButton from './ActionButton';
import UserSearchField from './UserSearchField';
import { error } from '../../redux/actions/alertAction';
import { Close } from '@material-ui/icons';
import { asCurrencyString } from '../../utils/money';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    check: {
      color: theme.palette.success.main,
      width: 96,
      height: 96,
    },
    avatar: {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
      width: 64,
      height: 64,
    },
    boltViewPaper: {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.secondary.main,
      minHeight: '500px',
      minWidth: '400px',
      padding: 10,
    },
    boltViewContent: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
    },
    transferSuccessContent: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      color: theme.palette.text.primary,
      gap: '8px',
      margin: '16px',
    },
    buttonSection: {
      display: 'flex',
      alignItems: 'center',
      position: 'absolute',
      justifyContent: 'center',
      bottom: 0,
      paddingBottom: 20,
      width: '100%',
    },
    actionButton: {
      margin: 5,
      padding: '10px 15px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      transition: 'background-color 0.5s',
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: theme.palette.grey[200],
      },
    },
  })
);

type REDUXSTATE = {
  userBolts: BoltState;
};

type TransferProps = {
  boltID: string;
  onBack: () => void;
};

// FIXLATER
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function TransferView(props: TransferProps) {
  const classes = useStyles();
  const { onBack, boltID } = props;
  const [amount, setAmount] = useState('');
  const [transferToUser, setTransferToUser] = useState<IUSERDATA | null>();
  const dispatch = useDispatch();
  const isDelegate = useSelector(selectIsDelegate);
  const actingID = useSelector(selectActingID);
  const actingUser = useSelector(selectActingUser);
  const bolt = useSelector((state: REDUXSTATE) => state.userBolts[boltID]);

  // called when user clicks confirm button
  const onConfirm = async () => {
    console.log('transferview confirm');
    const amt = parseFloat(amount);
    if (transferToUser == null) {
      dispatch(error('Please re-enter the user'));
      return;
    }

    if (transferToUser.id === actingUser?.id) {
      dispatch(error('ZUZ cannot be transferred to the same wallet'));
      return;
    }

    // dispatch the transaction
    dispatch(
      initTxn(
        boltID,
        transferToUser.id,
        amt,
        '',
        0,
        isDelegate ? actingID : undefined
      )
    );

    // Exit the modal
    onBack();
  };

  return (
    <Dialog
      classes={{
        paper: classes.boltViewPaper,
      }}
      open={true}
      onClose={onBack}
      maxWidth={'md'}
    >
      <DialogTitle>
        Spend
        <IconButton
          aria-label="close"
          style={{ float: 'right' }}
          onClick={onBack}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.boltViewContent}>
        <UserSearchField onSelect={setTransferToUser} />
        <FormControl margin="normal" fullWidth variant="outlined">
          <InputLabel htmlFor="outlined-adornment-amount">Amount</InputLabel>
          <OutlinedInput
            id="outlined-adornment-amount"
            value={amount}
            fullWidth
            onChange={(e) => setAmount(e.target.value)}
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
            labelWidth={60}
          />
        </FormControl>
        {amount && transferToUser && (
          <Typography>
            You, @{actingUser?.username}, are sending{' '}
            {asCurrencyString(parseFloat(amount))} of {bolt.bolt.specName} to @
            {transferToUser?.username}.
          </Typography>
        )}
      </DialogContent>
      <ActionButton
        handleCallback1={onBack}
        handleCallback2={onConfirm}
        name1="Cancel"
        name2="Confirm"
        disabled2={!amount || !transferToUser}
      />
    </Dialog>
  );
}

export default TransferView;
