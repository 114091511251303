import { authAxios } from './serviceUtils';
import { acceptAndRejectTxn } from './api';

//Contains axios call for accepting/rejecting transactions
// FIXLATER
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const acceptRejectTxns = async (txnId: string, action: string) => {
  const url = acceptAndRejectTxn + `${txnId}`;
  const body = {
    action: action,
  };
  const response = await authAxios.put(url, body).catch((err) => {
    console.log(err);
    return err.response;
  });
  return response.data;
};

// FIXLATER
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const getIssuedBolts = async () => {
  return [];
};

const homeService = {
  getIssuedBolts,
  acceptRejectTxns,
};
export default homeService;
