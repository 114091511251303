import { CircularProgress } from '@material-ui/core';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { error } from '../redux/actions/alertAction';
import userSearchServiceService from '../services/userSearchService';
import { IssuerData } from '../utils/types';
import SpendZuz from './SpendZuz';

type QrProps = {
  amount: string;
  issuerId: string;
  code: string;
};

// FIXLATER
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function QR(props: RouteComponentProps<QrProps>) {
  const [spendZuzDialog, setSpendZuzDialog] = React.useState(true);
  const [issuer, setIssuer] = React.useState<IssuerData>();
  const [isError, setIsError] = React.useState(false);
  const dispatch = useDispatch();

  React.useEffect(() => {
    (async () => {
      try {
        const issuer = await userSearchServiceService.getIssuer(
          props.match.params.issuerId
        );
        setIssuer(issuer);
      } catch {
        dispatch(error('Invalid QR redirect'));
        setIsError(true);
      }
    })();
  }, []);

  return !spendZuzDialog ? (
    <Redirect to="/wallet" />
  ) : issuer ? (
    <SpendZuz
      openCloseSpendDialog={setSpendZuzDialog}
      storeName={issuer.businessName}
      storeId={String(issuer.id)}
      amt={parseFloat(props.match.params.amount)}
      extraZuz={[]}
      qrCreator={props.match.params.code}
      posTid={''}
    />
  ) : isError ? (
    <Redirect to="/wallet" />
  ) : (
    <CircularProgress />
  );
}

export default QR;
