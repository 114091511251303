// information about bolts that comes from campaigns

import boltService from './boltService';
import userSearchService from './userSearchService';

// return path to logo or '' if none is available
export const getBoltLogo = async (specID: string): Promise<string> => {
  const boltInfo = await boltService.getBoltByID(specID);
  const issuerid = boltInfo.issuer;
  const issuerInfo = await userSearchService.getIssuer(issuerid.toString());
  for (const campaign of issuerInfo.campaigns) {
    if (campaign.bolt == specID && campaign.logo && campaign.logo.length > 0)
      return campaign.logo;
  }
  // we never found a logo for the specific bolt, so use issuer's if there is one
  if (issuerInfo.logo && issuerInfo.logo.length > 0) return issuerInfo.logo;

  // never found it at all
  return '';
};
