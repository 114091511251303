import React from 'react';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles, Theme, Typography, createStyles } from '@material-ui/core';
import { IUSERBOLTDATA } from '../../utils/types';
import { asCurrencyString } from '../../utils/money';
import BoltLogo from './BoltLogo';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: 8,
      border: '2px solid ' + theme.palette.primary.light,
      boxShadow: '0px 10px 10px #CCCCCC',
      backgroundColor: theme.palette.background.default,
      transition: 'background-color .4s, box-shadow .4s',
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: theme.palette.background.paper,
        boxShadow: '0px 4px 4px #CCCCCC',
      },
    },
    // vertical alignment
    boltAmount: {
      display: 'flex',
      alignItems: 'center',
    },
    // right padding to balance svg size
    boltAmountNumber: {
      paddingRight: '5px',
    },
    boltCard: {
      display: 'flex',
      maxWidth: '100%',
      alignItems: 'center',
    },
    columnBoltCard: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: '100',
      alignItems: 'center',
      justifyContent: 'center',
    },
    boltCardInfo: {
      display: 'inline-block',
      marginLeft: 12,
      maxWidth: '100%',
    },
    // alternate positioning for vertical-variant BoltCards
    columnBoltCardInfo: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      maxWidth: '100%',
    },
    // force text ellipses when too long
    overflowText: {
      maxWidth: '100%',
    },
  })
);

type CardProps = {
  bolt: IUSERBOLTDATA;
  onClickCard: (id: string) => void;
  logo?: any;
  vertical?: boolean; // alternate card orientation
  className?: string;
};

// FIXLATER
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function BoltCard(props: CardProps) {
  const classes = useStyles();
  const bolt = props.bolt;
  const onClickCard = props.onClickCard;
  const onClick = () => {
    if (onClickCard) {
      onClickCard(bolt.bolt.specID);
    } else {
      console.log('On click card function is empty');
    }
  };

  return (
    <Card className={clsx(classes.root, props.className)} onClick={onClick}>
      <CardContent
        className={clsx({
          [classes.boltCard]: !props.vertical,
          [classes.columnBoltCard]: props.vertical,
        })}
      >
        <BoltLogo specID={bolt.bolt.specID} />
        <div
          className={clsx({
            [classes.boltCardInfo]: !props.vertical,
            [classes.columnBoltCardInfo]: props.vertical,
          })}
        >
          <Typography variant="body1" noWrap className={classes.overflowText}>
            <b>{bolt.bolt.specName}</b>
          </Typography>
          <div className={classes.boltAmount}>
            <Typography
              variant="body2"
              component="p"
              color="secondary"
              noWrap
              className={clsx(classes.boltAmountNumber, classes.overflowText)}
            >
              <b>{asCurrencyString(bolt.amount)}</b>
            </Typography>
          </div>
        </div>
      </CardContent>
    </Card>
  );
}

export default BoltCard;
