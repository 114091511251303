import { createSelector, OutputSelector } from 'reselect';
import { IBOLTDATA } from '../utils/types';
import { AccessPermission, IAuthStateData } from './reducers/authReducer';
import { BoltState } from './reducers/boltReducer';
import { IAppState } from './reducers/reducer';
import { TxnDryState, TxnSubState } from './reducers/txnReducer';

const selectAuthState = (state: IAppState) => state.authState;
const selectUsers = (state: IAppState) => state.users;
const selectDryBolts = (state: IAppState) => state.userBolts;
const selectDryTransactions = (state: IAppState) => state.transactions;

// Hydrating everything on every change is very wasteful and should be changed.
// See issue: https://github.com/monatized/wallet/issues/188
// selector creator for getting hydrated transactions
const getHydratedTxnSubstate = (substate: string) =>
  createSelector(
    [selectDryTransactions, selectDryBolts, selectUsers],
    (dryTransactions, dryBolts, users) => {
      return Object.values(
        dryTransactions[substate as keyof TxnDryState]
      ).reduce((transactions: TxnSubState, dryTxn) => {
        // need check since async dispatch of bolt may not be fulfilled at this time
        if (!(dryTxn.bolt in dryBolts)) {
          return transactions;
        }
        transactions[dryTxn._id] = {
          ...dryTxn,
          // These accesses should be guaranteed by the hydrationMiddleware checks,
          // but implementation is still buggy and additional checks should
          // probably also be added here
          receiver: users[dryTxn.receiver],
          sender: users[dryTxn.sender],
          bolt: dryBolts[dryTxn.bolt as keyof BoltState].bolt,
        };
        return transactions;
      }, {});
    }
  );

export const getIncomingTransactions = getHydratedTxnSubstate('incoming');
export const getOutgoingTransactions = getHydratedTxnSubstate('outgoing');
export const getFinalizedTransactions = getHydratedTxnSubstate('finalized');

// memoize array conversion
export const getIncomingTransactionsAsArray = createSelector(
  [getIncomingTransactions],
  (incomingTransactions) => Object.values(incomingTransactions)
);

// checks if the currently active account has an auth token.
// the token is not guaranteed to be valid
// FIXLATER
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const selectAuthenticated = (state: IAppState) => {
  const effectiveUser = state.authState.effectiveUser;
  if (effectiveUser) {
    const account = state.authState.accounts[effectiveUser.loginID];
    return account && account.token;
  }
  return false;
};

// checks if the login and acting user are the same, especially for
// API calls
// FIXLATER
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const selectIsDelegate = (state: IAppState) => {
  const effectiveUser = state.authState.effectiveUser;
  return effectiveUser?.loginID !== effectiveUser?.actingID;
};

// fetches info for the account the user is currently logged in as
// or undefined if none
// FIXLATER
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const selectLoginUser = (state: IAppState) => {
  const effectiveUser = state.authState.effectiveUser;
  if (effectiveUser) {
    return state.users[effectiveUser.loginID];
  }
  return undefined;
};
// FIXLATER
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const selectLoginID = (state: IAppState) => {
  return state.authState.effectiveUser?.loginID;
};

// fetches info for the account the user is currently acting as
// or undefined if none
// FIXLATER
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const selectActingUser = (state: IAppState) => {
  const effectiveUser = state.authState.effectiveUser;
  if (effectiveUser) {
    return state.users[effectiveUser.actingID];
  }
  return undefined;
};
// FIXLATER
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const selectActingID = (state: IAppState) => {
  return state.authState.effectiveUser?.actingID;
};

//returns true if the active user is a merchant
// FIXLATER
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const selectUserRole = (state: IAppState) => {
  const actingID = state.authState.effectiveUser?.actingID;
  if (actingID) {
    const account = state.authState.accounts[actingID];
    return account && account.userRole === 'merchant';
  }
  return false;
};

// Gets a list of accounts on this device with basic profile info
// FIXLATER
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const selectProfiles = (state: IAppState) => {
  return Object.entries(state.authState.accounts).map(
    ([loginID, linkedAccount]) => {
      return {
        loginID: loginID,
        token: linkedAccount.token,
        profileData: state.users[loginID],
        delegatedProfiles: Object.values(
          linkedAccount.delegatedPermissions
        ).map((delegateAcc: AccessPermission) => delegateAcc.profileData),
      };
    }
  );
};

// get an array of bolt specs only
// FIXLATER
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const selectBoltSpecs = (state: IAppState) => {
  return Object.values(state.userBolts).map((userBolt) => userBolt.bolt);
};

export const selectPermissions: OutputSelector<
  IAppState,
  AccessPermission,
  (res: IAuthStateData) => AccessPermission
> = createSelector([selectAuthState], (authState: IAuthStateData) => {
  const effectiveUser = authState.effectiveUser;
  if (effectiveUser) {
    if (effectiveUser.actingID === effectiveUser.loginID) {
      return {
        accept: true,
        mint: 'all',
        sell: 'all',
      };
    }
    return authState.accounts[effectiveUser.loginID].delegatedPermissions[
      effectiveUser.actingID
    ];
  }
  // not logged in
  return {
    accept: false,
    mint: {},
    sell: {
      specIDs: [],
      maxLimit: 0,
    },
  };
});

export const selectCanAccept = createSelector(
  [selectPermissions],
  (permissions) => permissions.accept
);

// returns a function that checks if the bolt ID can be minted by the
// current acting user; does not check maxLimit
export const selectCanMint = createSelector(
  [selectPermissions, selectActingID],
  (permissions, actingID) => (bolt: IBOLTDATA) => {
    if (permissions.mint === 'all') {
      // if "all", login user has full access to acting user permissions
      // can mint if the current user owns the bolt/ZUZ
      return bolt.issuer.toString() === actingID?.toString();
    }
    return (
      bolt.specID in permissions.mint &&
      permissions.mint[bolt.specID].maxLimit > 0
    );
  }
);

// returns a function that checks if the bolt ID can be sold by the
// current acting user; does not check maxLimit
export const selectCanSell = createSelector(
  [selectPermissions],
  (permissions) => (bolt: IBOLTDATA) => {
    if (permissions.sell === 'all') {
      return true;
    }
    return (
      permissions.sell.specIDs.some((specID) => specID === bolt.specID) &&
      permissions.sell.maxLimit > 0
    );
  }
);
