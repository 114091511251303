import React from 'react';
import { Button, makeStyles, createStyles, Theme } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonSection: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: 'auto',
      marginRight: 'auto',
      justifyContent: 'center',
      bottom: 0,
      paddingBottom: 20,
      gap: '1em',
    },
  })
);

//Type of the props
type ActionButtonProps = {
  handleCallback1: (input: any) => void; // function for left button
  handleCallback2: (input: any) => void; // function for right button
  name1: string; // name of the left button
  name2: string; // name of the right button
  disabled1?: boolean; // if button1 needs to be disabled
  disabled2?: boolean; // if button2 needs to be disabled
};

/*
ActionButton is a component for the group of buttons on a dialog. 
Each button has a prop for it's name, callback function, and a boolean determining whether the button should be disabled.
*/
// FIXLATER
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function ActionButton(props: ActionButtonProps) {
  const classes = useStyles();
  const handleCallback1 = props.handleCallback1;
  const handleCallback2 = props.handleCallback2;
  const name1 = props.name1;
  const name2 = props.name2;
  const disabled1 = props.disabled1;
  const disabled2 = props.disabled2;

  return (
    <div className={classes.buttonSection}>
      <Button
        onClick={handleCallback1}
        disabled={disabled1}
        color="secondary"
        variant="outlined"
      >
        {name1}
      </Button>
      <Button
        onClick={handleCallback2}
        disabled={disabled2}
        color="primary"
        variant="contained"
      >
        {name2}
      </Button>
    </div>
  );
}
export default ActionButton;
