import React, { useState, useEffect, Fragment } from 'react';
import { useSelector } from 'react-redux';

import {
  getIncomingTransactions,
  getOutgoingTransactions,
  getFinalizedTransactions,
  selectActingUser,
} from '../../redux/selectors';
import SwitchProfile from './SwitchProfile';
import TransactionLine from '../common/TransactionLine';
import AcceptTransaction, {
  ACCTXNDIALOG,
} from '../dashboard/AcceptTransaction';
import BoltView from '../common/BoltView';
import { getImageURL } from '../../services/api';

import { createStyles, makeStyles, Paper, Theme } from '@material-ui/core';
import Title from '../common/Title';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import { ITXNDATA } from '../../utils/types';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    avatar: {
      width: theme.spacing(10),
      height: theme.spacing(10),
      border: '2px solid black',
      backgroundColor: '#1849B8',
      color: '#00FFFF',
    },
    name: {
      fontSize: '2.17rem',
      color: '#0071BC',
    },
    username: {
      fontSize: '1.25rem',
      color: '#00A99D',
    },
    userInfoBox: {
      display: 'flex',
      justifyContent: 'center',
    },
    nameBox: {
      marginLeft: '15px',
      // display:'flex',
    },
    buttonsBox: {
      marginTop: '3rem',
      width: '100%',
      justifyContent: 'center',
      display: 'flex',
    },
    iconBox: {
      marginLeft: theme.spacing(1),
    },
    button: {
      borderRadius: 50,
      marginBottom: 15,
    },
    buttonActive: {
      backgroundColor: '#0071BC',
      color: 'white',
      '&:hover': {
        backgroundColor: '#99FFFF',
      },
    },
    paper: {
      padding: theme.spacing(2),
    },
    cardsSection: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    table: {
      minWidth: 700,
    },
    root: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    transactionCard: {
      display: 'flex',
      alignItems: 'center',
    },
    transactionsBox: {
      marginTop: '3rem',
      width: '100%',
    },
    transactionContent: {
      display: 'flex',
      alignItems: 'center',
      flexGrow: 1,
      flexShrink: 1,
      minWidth: '150px',
      borderRadius: 8,
      padding: theme.spacing(2),
    },
    transactionInfo: {
      display: 'inline-block',
      flexGrow: 1,
      flexShrink: 1,
      minWidth: 0,
      marginLeft: theme.spacing(2),
    },
    transactionAmount: {
      // align money symbol with amount value
      display: 'flex',
      alignItems: 'center',
    },
    // force text ellipses when too long
    overflowText: {
      maxWidth: '100%',
    },
    transactionActions: {
      display: 'flex',
      justifyContent: 'flex-end',
      // width: 'fit-content',
    },
    acceptButton: {
      color: theme.palette.secondary.main,
      marginLeft: theme.spacing(2),
    },
    rejectButton: {
      color: theme.palette.error.main,
      marginLeft: theme.spacing(2),
    },
    userRoleToggleGroup: {
      padding: 25,
      width: '100%',
    },
    userRoleToggle: {
      width: '100%',
      padding: 5,
      borderRadius: 20,
      textTransform: 'none',
      fontStyle: 'italic',
    },
  })
);

// FIXLATER
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function Profile() {
  const classes = useStyles();

  const user = useSelector(selectActingUser);
  const finalizedTransactions = useSelector(getFinalizedTransactions);
  const incomingTransactions = useSelector(getIncomingTransactions);
  const outgoingTransactions = useSelector(getOutgoingTransactions);

  const [sortedIOTransactions, setSortedIOTransactions] = useState<ITXNDATA[]>(
    []
  );
  const [profilePic, setProfilePic] = useState<File | string | null>(null);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [txnDialogOpen, setTxnDialogOpen] = useState(false);
  const [chosenBoltID, setChosenBoltID] = useState(''); // the bolt card user clicked
  const [acceptDialogState, setAcceptDialogState] = useState<ACCTXNDIALOG>({
    isOpen: false,
  });

  const [viewToggle, setViewToggle] = React.useState<string | null>('pending');

  /* eslint-disable-next-line @typescript-eslint/no-unused-vars */ /* FIXLATER */
  const switchView = (
    event: React.MouseEvent<HTMLElement>,
    newView: string | null
  ) => {
    if (newView !== null) {
      console.log(newView);
      setViewToggle(newView);
    }
  };

  //Sort incoming and outgoing transactions
  useEffect(() => {
    if (!incomingTransactions || !outgoingTransactions) return;

    const incomingCopy: any = [...Object.values(incomingTransactions)];
    const outgoingCopy: any = [...Object.values(outgoingTransactions)];
    let tempHolder: any[] = [];

    while (incomingCopy.length || outgoingCopy.length) {
      if (!incomingCopy.length) {
        tempHolder.push(outgoingCopy.shift());
      } else if (!outgoingCopy.length) {
        tempHolder.push(incomingCopy.shift());
      } else {
        if (incomingCopy[0].updatedAt < outgoingCopy[0].updatedAt) {
          tempHolder.push(incomingCopy.shift());
        } else {
          tempHolder.push(outgoingCopy.shift());
        }
      }
    }

    tempHolder = Object.values(tempHolder).map((transaction) => {
      const updatedAt = new Date(transaction.updatedAt);
      const createdAt = new Date(transaction.createdAt);
      const formattedUpdatedAt = updatedAt.toDateString();
      const formattedCreatedAt = createdAt.toDateString();
      return {
        ...transaction,
        createdAt: formattedCreatedAt,
        updatedAt: formattedUpdatedAt,
      };
    });

    setSortedIOTransactions([...tempHolder]);
  }, [incomingTransactions, outgoingTransactions]);

  useEffect(() => {
    if (!user) return;
    setProfilePic(user.profilePic);
  }, [user]);

  //Returns an avatar conditional upon whether the user has a uploaded picture or not. Else, it's their initials
  const returnAvatar = () => {
    if (profilePic) {
      let profileObj: any = null;

      if (typeof profilePic === 'string') {
        profileObj = getImageURL(profilePic);
      } else {
        profileObj = URL.createObjectURL(profilePic);
      }
      return <Avatar src={profileObj} className={classes.avatar} />;
    } else {
      if (!user?.lastName) {
        return <Avatar className={classes.avatar}>{user?.firstName[0]}</Avatar>;
      } else {
        return (
          <Avatar className={classes.avatar}>
            {user?.firstName[0]}
            {user?.lastName[0]}
          </Avatar>
        );
      }
    }
  };

  //Opens and closes modal for editing profile. Onclick for edit button and passed into EditProfile component
  /* eslint-disable-next-line @typescript-eslint/no-unused-vars */ /* FIXLATER */
  const changeEditModalState = () => {
    setEditModalOpen(!editModalOpen);
  };

  ////////////////////////////////////////////////////////////////
  // Anuda: do we need the following for the future?

  //	  //To be completed soon, should set setTXnDialogOpen to true and open a modal with a Zuz's information
  //	  const viewTransactionDetail = (id: string) => {
  //	    setChosenBoltID(id);
  //	    setTxnDialogOpen(true);
  //	  };
  //
  //	  const transactionItemFinalized = (transaction: any) => {
  //	    let senderUser = Object.values(users).filter(
  //	      (user) => user.id === transaction.sender
  //	    )[0];
  //	    let receiverUser = Object.values(users).filter(
  //	      (user) => user.id === transaction.receiver
  //	    )[0];
  //
  //	    let sender = senderUser.firstName + " " + senderUser.lastName;
  //	    let receiver = receiverUser.firstName + " " + receiverUser.lastName;
  //
  //	    return (
  //	      <div key={transaction._id} className={classes.transactionCard}>
  //	        <CardActionArea
  //	          className={classes.transactionContent}
  //	          onClick={() => viewTransactionDetail(transaction.bolt.specID)}
  //	        >
  //	          {/* TODO add logo section */}
  //	          <Avatar className={classes.avatar}>
  //	            <FontAwesomeIcon icon={["far", "store"]} />
  //	          </Avatar>
  //	          <div className={classes.transactionInfo}>
  //	            <Typography variant="body1" noWrap className={classes.overflowText}>
  //	              {/* {transaction.bolt.specName} */}
  //	              {sender}
  //	            </Typography>
  //	            <Typography
  //	              variant="body1"
  //	              color="textSecondary"
  //	              noWrap
  //	              className={classes.overflowText}
  //	            >
  //	              {/* {transaction.bolt.issuerName} */}
  //	              {receiver}
  //	            </Typography>
  //	            <div className={classes.transactionAmount}>
  //	              <AttachMoneyIcon fontSize="small" color="secondary" />
  //	              <Typography
  //	                variant="body2"
  //	                component="p"
  //	                color="secondary"
  //	                noWrap
  //	                className={classes.overflowText}
  //	              >
  //	                <b>{transaction.amount} </b>
  //	              </Typography>
  //	            </div>
  //	          </div>
  //	        </CardActionArea>
  //	        {/* <div className={classes.transactionActions}>
  //	            <IconButton className={classes.acceptButton} onClick={() => handleAccept(transaction)} >
  //	              <FontAwesomeIcon icon={['far', 'check-circle']} size='lg' />
  //	            </IconButton>
  //	            <IconButton className={classes.rejectButton} onClick={() => handleReject(transaction)} >
  //	              <FontAwesomeIcon icon={['far', 'times-circle']} size='lg' />
  //	            </IconButton>
  //	          </div> */}
  //	      </div>
  //	    );
  //	  };
  ////////////////////////////////////////////////////////////////

  //Maps the transactions unto the page based on whether pending or finalized is active
  /* eslint-disable-next-line @typescript-eslint/no-unused-vars */ /* FIXLATER */
  const transactionDisplay = () => {
    if (viewToggle === 'pending' && sortedIOTransactions !== []) {
      return (
        <Fragment>
          {sortedIOTransactions.map((transactions) => (
            <TransactionLine
              key={transactions._id}
              variant={
                transactions.sender.id === user?.id ? 'outgoing' : 'incoming'
              }
              transaction={transactions}
              setAcceptDialogState={setAcceptDialogState}
              setTxnDialogOpen={setTxnDialogOpen}
              setChosenBoltID={setChosenBoltID}
            />
          ))}
          {acceptDialogState.isOpen && (
            <AcceptTransaction
              dialogState={acceptDialogState}
              setDialogState={setAcceptDialogState}
            />
          )}
          {txnDialogOpen && (
            <BoltView boltID={chosenBoltID} onCloseView={setTxnDialogOpen} />
          )}
        </Fragment>
      );
    } else if (viewToggle === 'complete') {
      const sortedTrans = Object.values(finalizedTransactions).sort((a, b) => {
        return b._id < a._id ? -1 : 1;
      });
      return sortedTrans.map((transactions, i) => (
        <Fragment key={i}>
          {
            <TransactionLine
              key={transactions._id}
              variant={'finalized'}
              transaction={transactions}
              setAcceptDialogState={setAcceptDialogState}
              setTxnDialogOpen={setTxnDialogOpen}
              setChosenBoltID={setChosenBoltID}
            />
          }
          {txnDialogOpen && (
            <BoltView boltID={chosenBoltID} onCloseView={setTxnDialogOpen} />
          )}
        </Fragment>
      ));
    }
  };

  return (
    <Container>
      <Title>Activity</Title>
      <Paper className={classes.paper}>
        <Box className={classes.userInfoBox}>
          {user && profilePic !== undefined
            ? returnAvatar()
            : console.log(user)}

          <Box className={classes.nameBox}>
            <Box display="flex" alignItems="center">
              {user ? (
                <Typography variant="h1" className={classes.name}>
                  {user.firstName} {user.lastName}
                </Typography>
              ) : null}
            </Box>

            <Box>
              {user ? (
                <Typography variant="h5" className={classes.username}>
                  @{user.username}
                </Typography>
              ) : null}
            </Box>
          </Box>
        </Box>
        <Box className={classes.transactionsBox}>
          {
            /* eslint-disable-next-line eqeqeq */ /* FIXLATER */
            sortedIOTransactions.length == 0 && (
              <div>
                <Fragment>
                  {sortedIOTransactions.map((transactions) => (
                    <TransactionLine
                      key={transactions._id}
                      variant={
                        transactions.sender.id === user?.id
                          ? 'outgoing'
                          : 'incoming'
                      }
                      transaction={transactions}
                      setAcceptDialogState={setAcceptDialogState}
                      setTxnDialogOpen={setTxnDialogOpen}
                      setChosenBoltID={setChosenBoltID}
                    />
                  ))}
                  {acceptDialogState.isOpen && (
                    <AcceptTransaction
                      dialogState={acceptDialogState}
                      setDialogState={setAcceptDialogState}
                    />
                  )}
                  {txnDialogOpen && (
                    <BoltView
                      boltID={chosenBoltID}
                      onCloseView={setTxnDialogOpen}
                    />
                  )}
                </Fragment>
              </div>
            )
          }
          <div>
            {Object.values(finalizedTransactions)
              .sort((a, b) => {
                return b._id < a._id ? -1 : 1;
              })
              .map((transactions) => (
                <Fragment key={transactions._id}>
                  <TransactionLine
                    variant={'finalized'}
                    transaction={transactions}
                    setAcceptDialogState={setAcceptDialogState}
                    setTxnDialogOpen={setTxnDialogOpen}
                    setChosenBoltID={setChosenBoltID}
                  />
                  {txnDialogOpen && (
                    <BoltView
                      boltID={chosenBoltID}
                      onCloseView={setTxnDialogOpen}
                    />
                  )}
                </Fragment>
              ))}
          </div>
        </Box>
        {SwitchProfile()}
      </Paper>
    </Container>
  );
}

export default Profile;
