import React from 'react';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import {
  makeStyles,
  Theme,
  Typography,
  createStyles,
  Grid,
} from '@material-ui/core';
import StoreIcon from '@material-ui/icons/Store';
import { GROUPEDBOLTDATA } from '../../utils/types';
import { asCurrencyString } from '../../utils/money';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: 20,
      border: '2px solid ' + theme.palette.primary.light,
      boxShadow: '0px 10px 10px #CCCCCC',
      backgroundColor: theme.palette.background.default,
      transition: 'background-color .4s, box-shadow .4s',
      '&:hover': {
        cursor: 'pointer',
        border: '2px solid ' + theme.palette.secondary.light,
        boxShadow: '10px 20px 20px #CCCCCC',
      },
      marginTop: -145,
      aspectRatio: '1.586', //Aspect ratio based on standard credit card size
    },
    // right padding to balance svg size
    boltAmountNumber: {
      fontWeight: 'lighter',
    },
    boltCardInfo: {
      display: 'inline-block',
      maxWidth: '100%',
      padding: 0,
    },
    // alternate positioning for vertical-variant BoltCards
    columnBoltCardInfo: {
      padding: 0,
    },
    // force text ellipses when too long
    overflowText: {
      maxWidth: '100%',
      fontWeight: 'lighter',
    },
    issueLogo: {
      minHeight: '15vh',
      padding: 0,
      margin: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'hidden',
    },
  })
);

type CardProps = {
  boltGroup: GROUPEDBOLTDATA; //list of bolt data with issuer information
  onClickCard: (boltGroup: GROUPEDBOLTDATA) => void;
  logo?: any;
  vertical?: boolean; // alternate card orientation
  className?: string;
};

//This component displays a card with information on a group of BoLTs created by a common issuer. The onClickCard callback is used to specify action that should happen on card press.
// FIXLATER
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function GroupedBoltCard(props: CardProps) {
  const classes = useStyles();
  const boltGroup = props.boltGroup;
  const onClickCard = props.onClickCard;
  const logo = props.logo;
  const onClick = () => {
    if (onClickCard) {
      onClickCard(boltGroup);
    } else {
      console.log('On click card function is empty');
    }
  };
  return (
    <Grid item xs={12}>
      <Card className={clsx(classes.root, props.className)} onClick={onClick}>
        <CardContent
          className={clsx({
            [classes.boltCardInfo]: !props.vertical,
            [classes.columnBoltCardInfo]: props.vertical,
          })}
        >
          <Grid container direction="row" alignItems="center" justify="center">
            <Grid item xs={6} alignItems="center" justify="center">
              <Typography
                variant="h5"
                noWrap
                color="textSecondary"
                className={classes.overflowText}
              >
                {boltGroup.issuerName}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                variant="h5"
                color="secondary"
                noWrap
                className={classes.overflowText}
                align="right"
              >
                {asCurrencyString(boltGroup.totalAmount)}
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.issueLogo}>
              {!logo && <StoreIcon color="primary" fontSize="large" />}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
}

export default GroupedBoltCard;
