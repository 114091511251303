import { loginURL, logoutURL } from './api';
import { authAxios, noauthAxios } from './serviceUtils';

// This function is used to login with a specific user at the backend and tracks the logged in user using cookies

// FIXLATER
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const loginNewUser = async (username: string, password: string) => {
  // formulate request data
  const data = {
    'Content-Type': 'application/json;charset=UTF-8',
    username,
    password,
  };

  // send post request to backend
  const response = await noauthAxios
    .post(loginURL, data, { withCredentials: true })
    .catch((err) => {
      console.log(err);
      return err.response;
    });
  return response.data;
};

// This function signals user logout so the server can perform cleanup logic

// FIXLATER
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const logoutUser = async () => {
  // send post request with user session cookie
  const response = await authAxios.post(logoutURL).catch((err) => {
    console.log(err);
    return err.response;
  });
  return response.data;
};

const loginService = {
  loginNewUser,
  logoutUser,
};
export default loginService;
