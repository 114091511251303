// Component for displaying a list of campaigns
// Renders a list of Campaign components to display all the campaigns associated with a single vendor.
import React from 'react';
import { Grid } from '@material-ui/core';
import Campaign from './Campaign';
import { CAMPAIGNDATA } from '../../utils/types';
import Title from '../common/Title';

type CampaignsProps = {
  campaigns: CAMPAIGNDATA[];
};

// FIXLATER
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function Campaigns(props: CampaignsProps) {
  const { campaigns } = props;
  return (
    <Grid
      item
      xs={12}
      style={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <b>
        <Title>ZUZ Campaigns</Title>
      </b>
      {campaigns.map((campaign: CAMPAIGNDATA) => (
        <Campaign
          key={campaign.bolt}
          title={campaign.name}
          current={
            campaign.goal.completed === undefined ? 0 : campaign.goal.completed
          }
          fundGoal={campaign.goal.amount}
          campaign={campaign.description}
          videos={
            campaign.videos === undefined ? [] : (campaign.videos as string[])
          }
          buyzuz={
            campaign.buyzuz === undefined ? '' : (campaign.buyzuz as string)
          }
          logo={campaign.logo === undefined ? '' : (campaign.logo as string)}
        />
      ))}
    </Grid>
  );
}

export default Campaigns;
