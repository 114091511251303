import {
  AuthActionsTypes,
  BoltActionsTypes,
  TxnActionsTypes,
  UsersActionsTypes,
} from './actionTypes';

const isEnum =
  <T>(enumType: T) =>
  (item: any): item is T[keyof T] =>
    Object.values(enumType).includes(item as T[keyof T]);

export const isAuthActionType = isEnum(AuthActionsTypes);
export const isTxnActionType = isEnum(TxnActionsTypes);
export const isBoltActionType = isEnum(BoltActionsTypes);
export const isUsersActionType = isEnum(UsersActionsTypes);
