import { Dispatch } from 'redux';
import { QrActionTypes } from './actionTypes';

// action to set QR code
// FIXLATER
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const setQrCodeAction = (code: string) => {
  return {
    type: QrActionTypes.SET_CODE,
    payload: { code },
  };
};

// FIXLATER
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const xxsetQrCode = (code: string) => {
  console.log(`setting qr code: ${code}`);
  return (dispatch: Dispatch) => {
    return Promise.resolve(dispatch(setQrCodeAction(code))).then(() => {
      console.log('qr code set');
    });
  };
};

// action to open/close QR display
// FIXLATER
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const setOpen = (newstate: boolean) => {
  return {
    type: newstate ? QrActionTypes.SET_OPEN : QrActionTypes.SET_CLOSED,
  };
};

// action to indicate force QR to be closed
// FIXLATER
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const forceClose = () => {
  return {
    type: QrActionTypes.FORCE_CLOSED,
  };
};
