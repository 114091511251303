import { authAxios } from './serviceUtils';
import { txnBoltURL, txnSqlURL, userTxnsURLV2 } from './api';
import { SPECUUID, USERUUID } from '../utils/types';

// This function is used to fetch all user transactions

// FIXLATER
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const getTxnService = async (merchantID?: USERUUID) => {
  const response = await authAxios
    .get(userTxnsURLV2(merchantID))
    .catch((err) => {
      console.log(err);
      return err.response;
    });
  return response.data;
};

// This function is used to initiate a transaction

// FIXLATER
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const initTxnService = async (
  boltID: SPECUUID,
  userID: USERUUID,
  amount: { base: number; tip: number } | number,
  qrCreator: string,
  tip?: number,
  merchantID?: USERUUID,
  usingPOS?: string,
  posTid?: string
) => {
  // formulate request data
  const data = {
    'Content-Type': 'application/json;charset=UTF-8',
    boltID,
    userID,
    amount,
    qrCreator,
    tip,
    ...(merchantID ? { merchantID } : {}),
    ...(usingPOS ? { usingPOS } : {}),
    ...(posTid ? { posTid } : {}),
  };
  // send post request to backend
  const response = await authAxios.post(txnBoltURL, data).catch((err) => {
    console.log(err);
    return err.response;
  });
  return response.data;
};

// This function is used to initiate a transaction

// FIXLATER
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const initSqrTxnService = async (sqlurl: string) => {
  // formulate request data
  const data = { 'Content-Type': 'application/json;charset=UTF-8', sqlurl };
  // send post request to backend
  const response = await authAxios.post(txnSqlURL, data).catch((err) => {
    console.log(err);
    return err.response;
  });
  return response.data;
};

const txnService = {
  initTxnService,
  getTxnService,
  initSqrTxnService,
};
export default txnService;
