import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
  makeStyles,
} from '@material-ui/core';
import { ITXNDATA } from '../../utils/types';

const useStyles = makeStyles(() => ({
  dialogContent: {
    textAlign: 'center',
  },
}));

export type ACCTXNDIALOG = {
  isOpen: boolean;
  transaction?: ITXNDATA;
  processing?: boolean;
};

type ACCTXNPROPS = {
  dialogState: ACCTXNDIALOG;
  setDialogState: (dialogState: ACCTXNDIALOG) => void;
};

// FIXLATER
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function AcceptTransaction(props: ACCTXNPROPS) {
  const classes = useStyles();

  const transaction = props.dialogState.transaction;
  const sender = transaction?.sender;
  const processing = props.dialogState.processing;
  const setDialogState = props.setDialogState;

  const handleClose = (closeDisabled: boolean) => {
    if (!closeDisabled) {
      setDialogState({ isOpen: false });
    }
  };

  return (
    <Dialog open={true} onClose={() => handleClose(processing || false)}>
      <DialogTitle>
        Accepting Transaction from {sender?.firstName} {sender?.lastName} (
        {sender?.email})
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {processing ? (
          <CircularProgress />
        ) : transaction?.status === 'finalized' ||
          transaction?.status === 'confirmed' ? (
          <p>Successfully accepted transaction {transaction._id}</p>
        ) : (
          <p>
            Encountered an error while processing transaction {transaction?._id}
          </p>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => handleClose(processing || false)}
          color="secondary"
          disabled={processing}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AcceptTransaction;
