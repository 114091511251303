import React, { useState, useEffect } from 'react';
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Button,
  Fab,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import Title from "../common/Title";
import DelegationModal from './DelegationModal';
import { getUserDelegation } from '../../services/userService';
// import {getUserById} from "../../services/userSearchStoreService";
// import { useDispatch, useStore } from "react-redux";
//import Autocomplete from "@material-ui/lab/Autocomplete";

const useStyles = makeStyles((theme: Theme) => ({
  boundedContainer: {
    padding: theme.spacing(2),
  },
  addIconDiv: {
    textAlign: 'right',
  },
  addIcon: {
    margin: theme.spacing(2),
    padding: 0,
  },
  avatar: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    width: 40,
    height: 40,
  },
  slipContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 9,
    marginTop: 9,
    padding: 0,
    '& .appear-item': {
      display: 'none',
    },
    '&:hover .appear-item': {
      display: 'block',
    },
  },
  editButton: {
    whiteSpace: 'nowrap',
  },
}));

/**
 * Top-level component to view and create permissions delegated from the
 * current user to other users
 */
// FIXLATER
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function DelegatedPermissions() {
  interface Delegate {
    [key: string]: any;
  }

  const classes = useStyles();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [delegates, setDelegates] = useState<string[]>([]);
  const [delegateInfo, setDelegateInfo] = useState<Delegate>({});
  // const [username, setUsername] = useState("");
  // const getState = useStore().getState;
  // const dispatch = useDispatch();
  // const getUserByIdCurrent = getUserById(dispatch, getState);

  const fetchDelegates = () => {
    getUserDelegation().then((res: any) => {
      console.log(res);
      setDelegateInfo(res.permissions);
      const del = Object.keys(res.permissions);
      setDelegates(del);
    });
  };

  useEffect(() => {
    fetchDelegates();
  }, []);

  return (
    <main>
      <Box maxWidth="lg" style={{ marginTop: '40px', marginBottom: '40px' }}>
        <Typography component="h3" variant="h4" gutterBottom>
          Delegated Permissions
        </Typography>
        {delegates.length > 0 ? (
          <div>
            {delegates.map((delegate) => {
              return (
                <Accordion key={delegate} className={classes.slipContainer}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={2} sm={1} lg={1}>
                        <Avatar className={classes.avatar}>
                          <FontAwesomeIcon icon={['far', 'user']} />
                        </Avatar>
                      </Grid>
                      <Grid item xs={6} sm={6} lg={6} md={7}>
                        <Typography>Username Placeholder</Typography>
                      </Grid>
                      <div className="appear-item">
                        <AccordionActions>
                          <Grid item xs={4} sm={5} md={5} lg={4}>
                            <Button
                              color="primary"
                              variant="outlined"
                              className={classes.editButton}
                            >
                              Edit Permissions
                            </Button>
                          </Grid>
                        </AccordionActions>
                      </div>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div>
                      {delegateInfo[delegate].map(
                        (permission: {
                          type: string;
                          sell:
                            | { maxLimit: number; specIDs: Array<string> }
                            | undefined;
                        }) => {
                          return (
                            <div key={`${delegate}-${permission.type}`}>
                              {permission.type} ZUZ
                            </div>
                          );
                        }
                      )}
                    </div>
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </div>
        ) : (
          <Typography>
            No Delegates! Click the button below to add delegates!
          </Typography>
        )}
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={2} sm={1} className={classes.addIcon}>
            <Fab className={classes.avatar}>
              <AddIcon onClick={() => setDialogOpen(true)} />
            </Fab>
          </Grid>
          <Grid item xs={7} sm={9} lg={6} md={7}>
            <Typography>Add Delegate</Typography>
          </Grid>
        </Grid>
      </Box>
      {dialogOpen && <DelegationModal setDialogOpen={setDialogOpen} />}
    </main>
  );
}

export default DelegatedPermissions;
