import { Dispatch, Middleware } from 'redux';
import { IAppState } from '../reducers/reducer';
import { isTxnActionType } from '../actions/actionTypeGuards';
import { getUserBolts } from '../actions/authAction';
import { BoltActionsTypes, TxnActionsTypes } from '../actions/actionTypes';
import { BoltState } from '../reducers/boltReducer';
import { UsersState } from '../reducers/usersReducer';
import { DRYTXNDATA, IBOLTDATA } from '../../utils/types';
import { getSingleUser } from '../actions/usersAction';
import { error } from '../actions/alertAction';

import boltService from '../../services/boltService';

/** functions for checking presence of data necessary for hydration */

const checkTxn = async (
  dispatch: Dispatch,
  users: UsersState,
  bolts: BoltState,
  transaction: DRYTXNDATA
) => {
  //console.log(`checkTxn: ${JSON.stringify(transaction)}`);
  //console.trace();
  // maybe old api? (coming from websocket?)
  if (typeof transaction.bolt === 'object') {
    const tobj = transaction.bolt as IBOLTDATA;
    const specID = tobj.specID;
    console.log(
      `Erasing information about ${specID} from ${JSON.stringify(transaction)}`
    );
    transaction.bolt = specID;
  }
  // get bolt specs from the backend if missing
  if (!(transaction.bolt in bolts)) {
    // need new API
    await dispatch<any>(getUserBolts());
    // if still not in
    if (!(transaction.bolt in bolts)) {
      await boltService
        .getBoltByID(transaction.bolt)
        .then((bolt) => {
          dispatch({
            type: BoltActionsTypes.GET_USER_BOLTS,
            payload: {
              bolts: [
                {
                  amount: 0,
                  bolt: bolt,
                },
              ],
            },
          });
        })
        .catch((err) => {
          console.log('Got err when getting bolts');
          console.log(err);
          dispatch(error(err.message));
        });
    }
  }

  // get sender and receiver's information if missing
  if (!(transaction.sender in users && transaction.receiver in users)) {
    await dispatch<any>(getSingleUser(transaction.sender));
    await dispatch<any>(getSingleUser(transaction.receiver));
  }
};

// ensures that all data necessary for hydration is present in the store
export const hydrationMiddleware: Middleware<any, IAppState> =
  (storeAPI) => (next) => (action) => {
    const bolts = storeAPI.getState().userBolts;
    const users = storeAPI.getState().users;
    if (isTxnActionType(action.type)) {
      switch (action.type) {
        // special case where payload has multiple transactions
        case TxnActionsTypes.GET_TXNS:
          // need to wait until all async checks finish
          const checkAll = async () => {
            for (const txnID in action.payload.transactions) {
              await checkTxn(
                storeAPI.dispatch,
                users,
                bolts,
                action.payload.transactions[txnID]
              );
            }
          };
          return checkAll().then(() => {
            return next(action);
          });

        // single transaction case
        default:
          // need to wait until the check finishes
          console.log(`default case in hydrationMiddleware ${action.type}`);
          return checkTxn(
            storeAPI.dispatch,
            users,
            bolts,
            action.payload.transaction
          ).then(() => {
            return next(action);
          });
      }
    }
    return next(action);
  };
