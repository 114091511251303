// Round an amount to the nearest penny and format (currently for US)
export function asCurrencyString(amt: number): string {
  //const val = Math.round( ( amt + Number.EPSILON ) * 100 ) / 100;
  return amt.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
}
