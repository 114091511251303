import { ModalActionsTypes } from '../actions/actionTypes';

// This reducer currently controls only the ExpiredSessionModal but
// may be expanded to include other modals (e.g. ConfirmLogout, Confirmation)
export interface IModalState {
  open: boolean;
}

interface IModalAction {
  type: ModalActionsTypes;
}

const initialState: IModalState = {
  open: false,
};

// FIXLATER
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const modalReducer = (
  state: IModalState = initialState,
  action: IModalAction
) => {
  switch (action.type) {
    // open top-level modal
    case ModalActionsTypes.OPEN_MODAL:
      return { open: true };
    // close top-level modal
    case ModalActionsTypes.CLOSE_MODAL:
      return { open: false };
    default:
      return state;
  }
};

export default modalReducer;
