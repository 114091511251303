import React, { useState, useEffect } from 'react';
import {
  createStyles,
  makeStyles,
  Theme,
  TextField,
  InputAdornment,
  Grid,
  Modal,
  Typography,
  Box,
} from '@material-ui/core';
import IssuedBolts from './dashboard/IssuedBolts';
import Transactions from './dashboard/Transactions';
import IncomingTransactions from './dashboard/IncomingTransactions';
import OutgoingTransactions from './dashboard/OutgoingTransactions';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { IUSERDATA, GitInfo } from '../utils/types';
import UserSearchDialog from './UserSearchDialog';
import { useSelector } from 'react-redux';
import { IAppState } from '../redux/reducers/reducer';
import gitInfoService from '../services/gitInfoService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//Get the frontend git info from json file
import frontendGitInfo from '../git/git-status.json';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    margin: {
      margin: theme.spacing(1),
      marginBottom: 0,
    },
    paper: {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.secondary.main,
      minHeight: '150px',
      width: '400px',
      alignItems: 'center',
      padding: 10,
      outline: 'none',
    },
    gitInfoTitle: {
      alignContent: 'center',
    },
    gitInfoBody: {
      textDecoration: 'underline',
    },
  })
);

// FIXLATER
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function Home() {
  const classes = useStyles();
  const defaultBackendGit = {
    branch: '',
    commit: '',
  };
  const reduxState = useSelector((state: IAppState) => state);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<IUSERDATA>();
  const users = Object.values(reduxState.users);
  const [backendGitInfo, setBackendGitInfo] = useState(defaultBackendGit);

  useEffect(() => {
    //Getting git information for backend
    gitInfoService.getBackendGitInfo().then((response: GitInfo) => {
      setBackendGitInfo(response.git);
    });
  }, []);

  //For the git modal
  const [modalOpen, setModalOpen] = useState(true);

  //Handle closing the git modal
  const handleClose = () => {
    setModalOpen(false);
  };

  //What is displayed in the git modal
  const modalBody = (
    <div className={classes.paper}>
      <Typography variant="h5" align="center">
        Git Info
      </Typography>
      <Typography variant="body1" component="div" gutterBottom>
        <Box className={classes.gitInfoBody} display="inline">
          Current Frontend Branch:{' '}
        </Box>{' '}
        {frontendGitInfo.branch}
      </Typography>
      <Typography variant="body1" gutterBottom>
        <Box className={classes.gitInfoBody} display="inline">
          Current Frontend Commit:
        </Box>{' '}
        {frontendGitInfo.commit}
      </Typography>
      <Typography variant="body1" gutterBottom>
        <Box className={classes.gitInfoBody} display="inline">
          Current Backend Branch:
        </Box>{' '}
        {backendGitInfo.branch}
      </Typography>
      <Typography variant="body1" gutterBottom>
        <Box className={classes.gitInfoBody} display="inline">
          Current Backend Commit:
        </Box>{' '}
        {backendGitInfo.commit}
      </Typography>
    </div>
  );

  return (
    <div>
      <Modal
        open={modalOpen}
        onClose={handleClose}
        aria-labelledby="git-modal-label"
        aria-describedby="git-model-info"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {modalBody}
      </Modal>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Autocomplete
            id="combo-box-demo"
            options={users}
            getOptionLabel={(user) =>
              `${user.firstName} ${user.lastName} - ${user.email}`
            }
            onChange={(event, newValue) => {
              console.log(1);
              if (newValue !== null) {
                setSelectedUser(newValue);
                setDialogOpen(true);
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                className={classes.margin}
                id="input-with-icon-textfield"
                placeholder="Find a person or store"
                fullWidth
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      <FontAwesomeIcon icon={['far', 'expand']} />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <IncomingTransactions></IncomingTransactions>
        </Grid>
        <Grid item xs={12}>
          <OutgoingTransactions></OutgoingTransactions>
        </Grid>
        <Grid item xs={12}>
          <IssuedBolts></IssuedBolts>
        </Grid>
        <Grid item xs={12}>
          <Transactions></Transactions>
        </Grid>
      </Grid>
      {dialogOpen && (
        <UserSearchDialog
          setDialogOpen={setDialogOpen}
          selectedUser={selectedUser}
        />
      )}
    </div>
  );
}

export default Home;
