import React from 'react';
import { Container, Card, Typography, Grid } from '@material-ui/core';
import git_status from '../git/git-status.json';

// FIXLATER
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function About() {
  return (
    <>
      <Container maxWidth="md">
        <Card style={{ margin: 10, borderRadius: 20, padding: 20 }}>
          <Grid container spacing={4} alignItems="flex-start">
            <Grid item>
              <Typography variant="h3">About</Typography>
              <Typography variant="caption">
                Branch: {git_status.branch} | Commit: {git_status.commit}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1">
                Zuz is a platform for digital assets. We support local
                currencies like Sharpsburg Bucks. Local currencies promote the
                community's economic development, and encourage the use of
                locally produced goods and services. Join us as we support local
                businesses, and promote sustainability!
              </Typography>
              <Typography
                href="https://zuzlab.com/"
                component="a"
                variant="caption"
              >
                Home
              </Typography>
            </Grid>
          </Grid>
        </Card>
      </Container>
    </>
  );
}

export default About;
