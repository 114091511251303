import { makeStyles } from '@material-ui/core';
import React from 'react';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
}));

export type SlipProps = {
  className?: string;
};

/**
 * A Slip is essentially a miniature, horizontally-oriented Card;
 * it should contain a small amount of content and up to a couple of
 * actions about a single subject. The Slip component has only minimal
 * styling for positioning its children.
 *
 * Intended usage is as a container for a SlipContent XOR SlipActionArea
 * component, with an optional SlipActions component for buttons.
 * When one of the content components is used in conjunction with
 * SlipActions, the action buttons are justified to the end/right of the
 * Slip. The size of the action buttons takes precedence, so that they appear
 * in a single line; the content components should shrink their width to fit
 * as necessary.
 */
const Slip: React.FC<SlipProps> = (props) => {
  const { children, className, ...other } = props;
  const classes = useStyles();

  return (
    <div className={clsx(className, classes.root)} {...other}>
      {children}
    </div>
  );
};

export default Slip;
