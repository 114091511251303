//component that displays side bar for filtering results on the main web marketplace page

import React from 'react';
import {
  Typography,
  Radio,
  Checkbox,
  CheckboxProps,
  makeStyles,
  FormControl,
  FormControlLabel,
  Input,
} from '@material-ui/core/';

import {
  ArrowDropDown as ArrowDropDownIcon,
  ArrowRight as ArrowRightIcon,
} from '@material-ui/icons';

type Category = {
  id: number;
  name: string;
};

type FilterContainerProps = {
  categoryId: number;
  setCategoryId: (...args: any[]) => any;
  categories: Array<Category>;
};

const useStyles = makeStyles((/*theme*/) => ({
  filterContainer: {
    flexDirection: 'column',
    flex: 1,
    paddingLeft: '1%',
  },

  radio: {
    color: 'black',
  },
  radioGroup: {
    padding: '0 5%',
  },
  filterForm: {
    padding: '0 20% 0 5%',
  },
  checkbox: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    borderRadius: '50%',
    width: 16,
    height: 16,
    border: 'solid 1px black',
  },
  checkedIcon: {
    backgroundColor: 'black',
  },
  textField: {
    border: 'solid 1px black',
    fontSize: '0.8rem',
    paddingLeft: '5%',
    margin: '5%',
  },
  checkboxLabel: {
    fontSize: '0.8rem',
    fontStyle: 'italic',
    color: 'gray',
  },
  checkboxRoot: {
    padding: 0,
    margin: 0,
  },
}));

function StyledCheckbox(props: CheckboxProps) {
  const classes = useStyles();

  return (
    <Checkbox
      className={classes.checkbox}
      disableRipple
      color="default"
      checkedIcon={
        <span className={`${classes.icon} ${classes.checkedIcon}`} />
      }
      icon={<span className={classes.icon} />}
      inputProps={{ 'aria-label': 'decorative checkbox' }}
      {...props}
    />
  );
}

// FIXLATER
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function FilterContainer(props: FilterContainerProps) {
  const classes = useStyles();
  const [isCategory, setIsCategory] = React.useState<boolean>(false);
  const [isDistance, setIsDistance] = React.useState<boolean>(false);

  const toggleIsCategory = () => {
    setIsCategory(!isCategory);
  };
  const toggleIsDistance = () => {
    setIsDistance(!isDistance);
  };

  return (
    <div className={classes.filterContainer}>
      <Typography
        component="h6"
        variant="h6"
        style={{ padding: '25% 0 10% 0' }}
      >
        Filters
      </Typography>
      <FormControl className={classes.filterForm}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <div>Category</div>
          <div onClick={toggleIsCategory} style={{ marginTop: 5 }}>
            {isCategory ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
          </div>
        </div>
        {isCategory && (
          <div className={classes.radioGroup}>
            {props.categories.map((cat) => (
              <FormControlLabel
                key={cat.id}
                control={
                  <Radio
                    checked={cat.id === props.categoryId}
                    onChange={() => {
                      props.setCategoryId((state: number) => {
                        if (state === cat.id) {
                          return -1;
                        } else {
                          return cat.id;
                        }
                      });
                    }}
                  />
                }
                label={cat.name}
                classes={{
                  root: classes.checkboxRoot,
                  label: classes.checkboxLabel,
                }}
              />
            ))}
          </div>
        )}
        <div
          style={{
            display: 'none',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <div>Distance</div>
          <div onClick={toggleIsDistance} style={{ marginTop: 5 }}>
            {isDistance ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
          </div>
        </div>
        {isDistance && (
          <div className={classes.radioGroup}>
            <Input
              placeholder="Zip code"
              disableUnderline
              classes={{ root: classes.textField }}
            />
            <div>
              <FormControlLabel
                value="less-than-5"
                control={<StyledCheckbox />}
                label="< 5 miles away"
                classes={{
                  root: classes.checkboxRoot,
                  label: classes.checkboxLabel,
                }}
              />
              <FormControlLabel
                value="5-to-10"
                control={<StyledCheckbox />}
                label="5 - 10 miles away"
                classes={{
                  root: classes.checkboxRoot,
                  label: classes.checkboxLabel,
                }}
              />
              <FormControlLabel
                value="10-to-20"
                control={<StyledCheckbox />}
                label="10 - 20 miles away"
                classes={{
                  root: classes.checkboxRoot,
                  label: classes.checkboxLabel,
                }}
              />
            </div>
          </div>
        )}
      </FormControl>
    </div>
  );
}

export default FilterContainer;
