import React, { ReactNode } from 'react';
import { createMuiTheme, ThemeProvider } from '@material-ui/core';

const headerFont = "'Montserrat', sans-serif";
const font = "'Roboto', sans-serif";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#0071BC',
      dark: '#2E3192',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#00a99D',
      light: '#00FFFF',
      contrastText: '#000000',
    },
  },
  typography: {
    fontFamily: font,
    h1: {
      fontFamily: headerFont,
      fontWeight: 600,
    },
    h2: {
      fontFamily: headerFont,
      fontWeight: 600,
    },
    h3: {
      fontFamily: headerFont,
      fontWeight: 600,
    },
    h4: {
      fontFamily: headerFont,
      fontWeight: 600,
    },
    h5: {
      fontFamily: headerFont,
      fontWeight: 600,
    },
    h6: {
      fontFamily: headerFont,
      fontWeight: 600,
    },
  },
});

// Typography responsive sizing . . . they seem kind of large
// theme.typography.h1 = {
//   fontFamily: headerFont,
//   fontSize: '2.17em',
//   [theme.breakpoints.up('md')]: {
//     fontSize: '4em',
//   },
// };
// theme.typography.h2 = {
//   fontFamily: headerFont,
//   fontSize: '1.75em',
//   [theme.breakpoints.up('md')]: {
//     fontSize: '3em',
//   },
// };
// theme.typography.h3 = {
//   fontFamily: headerFont,
//   fontSize: '1.5em',
//   [theme.breakpoints.up('md')]: {
//     fontSize: '2.33em',
//   },
// };
// theme.typography.body1 = {
//   fontSize: '1.17em',
//   [theme.breakpoints.up('md')]: {
//     fontSize: '2em',
//   },
// };
// theme.typography.button = {
//   fontSize: '1.15em',
//   [theme.breakpoints.up('sm')]: {
//     fontSize: '1.17em',
//   },
//   [theme.breakpoints.up('lg')]: {
//     fontSize: '2em',
//   },
// }

theme.overrides = {
  MuiIconButton: {
    root: {
      color: theme.palette.primary.main,
    },
    colorPrimary: {
      color: theme.palette.primary.contrastText,
    },
  },
  MuiBottomNavigation: {
    root: {
      background: `linear-gradient(to right, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
    },
  },
  MuiBottomNavigationAction: {
    root: {
      color: theme.palette.secondary.light,
      '&$selected': {
        color: theme.palette.primary.contrastText,
      },
    },
  },
};

theme.props = {
  MuiMenuItem: {
    // enable keyboard tab navigation of menu items
    tabIndex: 0,
  },
};

// FIXLATER
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function OuterThemeProvider({ children }: { children: ReactNode }) {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}

export default OuterThemeProvider;
