import { authAxios } from './serviceUtils';
import {
  userBoltURL,
  mintBoltURL,
  createBoltURL,
  donateBoltURL,
  getBoltsByIDsURL,
  requestZuzCreateURL,
  sellBoltURL,
} from './api';
import { SPECUUID, USERUUID, IBOLTDATA } from '../utils/types';
import { Cache } from './cachedGet';

//Axios call for requesting zuz creation authorization
// FIXLATER
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const reqZuzCreation = async (request: boolean) => {
  const body = {
    request: request,
  };
  const response = await authAxios
    .post(requestZuzCreateURL, body)
    .catch((err) => {
      console.log(err);
      return err.response;
    });

  return response.data;
};

//Axios call for minting bolts
// FIXLATER
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const mintBolt = async (
  specID: SPECUUID,
  value: number,
  merchantID?: USERUUID
) => {
  const body = {
    specID: specID,
    value: value,
    ...(merchantID ? { merchantID } : {}),
  };

  const response = await authAxios.post(mintBoltURL, body).catch((err) => {
    console.log(err);
    return err.response;
  });

  return response.data;
};

//Axios call for selling bolts
// FIXLATER
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const sellBolt = async (specID: SPECUUID, amount: number, email: string) => {
  const body = {
    boltID: specID,
    amount: amount,
    email: email,
  };

  const response = await authAxios.post(sellBoltURL, body).catch((err) => {
    console.log(err);
    return err.response;
  });

  return response.data;
};

//Axios call for donating bolts
// FIXLATER
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const donateBolt = async (specID: SPECUUID, amount: number) => {
  const body = {
    amount: amount,
  };

  const response = await authAxios
    .put(donateBoltURL + specID, body)
    .catch((err) => {
      console.log(err);
      return err.response;
    });

  return response.data;
};

//Contains the axios call for creating a new bolt
// FIXLATER
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const createNewBolt = async (
  name: string,
  maturity: string,
  rate: number,
  number: number,
  donation: boolean,
  buyback: { [specID: string]: number } | null
) => {
  const body = {
    name: name,
    maturity: maturity,
    redeem: ['1'],
    interest: {
      rate: rate,
      number: number,
    },
    donation: donation,
  };
  if (buyback) {
    Object.assign(body, { buyback: buyback });
  }
  const response = await authAxios.post(createBoltURL, body).catch((err) => {
    console.log(err);
    return err.response;
  });
  return response.data;
};

//Fetch user bolts present in wallet
// FIXLATER
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const getUserBolts = async (merchantID?: USERUUID) => {
  // send post request to backend
  const response = await authAxios.get(userBoltURL(merchantID)).catch((err) => {
    console.log(err);
    return err.response;
  });
  // return json data
  return response.data;
};

const boltSpecCache = new Cache<IBOLTDATA>(
  'bolt',
  10,
  '/v2/bolt?specIDs=',
  getBoltsByIDsURL,
  (x: IBOLTDATA) => x.specID
);

const getBoltsByIDs: (specID: string[]) => Promise<Array<IBOLTDATA>> = async (
  specIDs: string[]
) => {
  return (await boltSpecCache.getList(specIDs)) as any;
};
const getBoltByID: (specID: string) => Promise<IBOLTDATA> = async (
  specID: string
) => {
  return await boltSpecCache.get(specID);
};

const boltService = {
  getUserBolts,
  mintBolt,
  createNewBolt,
  donateBolt,
  getBoltsByIDs,
  getBoltByID,
  reqZuzCreation,
  sellBolt,
};
export default boltService;
