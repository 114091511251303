// Component for displaying information associated with a campaign
// Renders a card containing the title, description, video and progress thermometer associated with a single campaign.
import React from 'react';
import ReactPlayer from 'react-player';
import {
  createStyles,
  Avatar,
  makeStyles,
  Theme,
  Typography,
  Box,
  Button,
  Card,
  Grid,
} from '@material-ui/core';

import { getImageURL } from '../../services/api';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    subtitle: {
      fontSize: '1.7 rem',
      color: 'black',
      textAlign: 'center',
    },
    secondary: {
      display: 'flex',
      flexWrap: 'wrap',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'center',
    },
    campaignDetailText: {},
    title: {
      fontStyle: 'italic',
    },
    large: {
      width: '100%',
      padding: theme.spacing(2),
      height: theme.spacing(20),
      textAlign: 'center',
      '& img': {
        objectFit: 'contain',
      },
      maxWidth: '100%',
    },
    glass: {
      width: '100%',
      height: '20px',
      background: '#C7C7C7',
      borderRadius: '10px',
      float: 'left',
      marginTop: 5,
    },
    progress: {
      float: 'left',
      height: '20px',
      background: '#0071BC',
      borderRadius: '10px',
    },
    actionButtons: {
      marginBottom: 10,
      borderRadius: 100,
    },
    contentCard: {
      width: '100%',
      padding: theme.spacing(2),
      height: '100%',
      borderRadius: 10,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    titleSector: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  })
);

type CampaignProps = {
  title: string;
  videos: Array<string>;
  images?: Array<string>;
  current: number;
  fundGoal: number;
  campaign: string;
  buyzuz: string;
  logo: string;
};

// FIXLATER
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function Campaign(props: CampaignProps) {
  const classes = useStyles();
  const { title, videos, current, fundGoal, campaign, buyzuz, logo } = props;

  function fundThermo() {
    //places two differently coloered boxes over each other
    //based on current funding vs funding goal
    const prog =
      ((current / fundGoal) * 100 >= 100
        ? 100 + '%'
        : (current / fundGoal) * 100) + '%';
    return (
      <>
        <Box className={classes.glass}>
          <Box className={classes.progress} width={prog} />
        </Box>
        <Typography className={classes.campaignDetailText}>
          Achieved ${current} of ${fundGoal} goal
        </Typography>
      </>
    );
  }

  return (
    <div className={classes.secondary}>
      <Card className={classes.contentCard}>
        <Grid container spacing={2} className={classes.titleSector}>
          <Grid item xs={12} className={classes.titleSector}>
            <Typography variant="h6" className={classes.title}>
              {title}
            </Typography>
          </Grid>
          {logo != '' && (
            <Grid item xs={12} className={classes.titleSector}>
              <Avatar
                variant="rounded"
                src={getImageURL(logo)}
                className={classes.large}
              />
            </Grid>
          )}
          {buyzuz !== undefined && buyzuz !== '' && (
            <Grid item xs={12} md={4} style={{ width: '100%' }}>
              <Button
                className={classes.actionButtons}
                variant="contained"
                size="large"
                color="primary"
                fullWidth
                onClick={() => window.open(buyzuz, '_blank')}
              >
                Buy ZUZ
              </Button>
            </Grid>
          )}
          {videos.length > 0 && (
            <Grid item xs={12} md={6}>
              <ReactPlayer url={videos[0]} controls width="100%" height={220} />
            </Grid>
          )}
          <Grid item xs={12} md={videos.length > 0 ? 6 : 12}>
            <Typography className={classes.campaignDetailText}>
              <span dangerouslySetInnerHTML={{ __html: campaign }} />
            </Typography>
          </Grid>
          {
            /* Only show goal and thermometer if they have a goal */
            fundGoal > 0 && (
              <Grid item xs={12}>
                {fundThermo()}
              </Grid>
            )
          }
        </Grid>
      </Card>
    </div>
  );
}

export default Campaign;
