import {
  TablePagination,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  Paper,
} from '@material-ui/core';
import Title from '../common/Title';
import React, { Fragment, useState, useCallback, useEffect } from 'react';
import StyledTableCell from './StyledTableCell';
import { useSelector } from 'react-redux';
import { UsersState } from '../../redux/reducers/usersReducer';
import { getFinalizedTransactions } from '../../redux/selectors';

const rowsPerPageOptions = [5, 10, 25];

type REDUXSTATE = {
  users: UsersState;
};

// FIXLATER
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function Transactions() {
  const transactions = useSelector(getFinalizedTransactions);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [
    sortMethod,
    // FIXLATER
    setSortMethod, // eslint-disable-line @typescript-eslint/no-unused-vars
  ] = useState('recent');
  const [displayTransactions, setDisplayTransactions] = useState<any[]>([]);
  const users = useSelector((state: REDUXSTATE) => state.users);

  //Handles the page change in the table
  const handleChangePage = useCallback(
    (__, page) => {
      setPage(page);
    },
    [setPage]
  );

  useEffect(() => {
    const displayFunction = (finalizedTransactions: any) => {
      //Sorts and updates the array that the component renders from

      let sortedTransactions: any;

      // Sort transactions by sortMethod state
      if (sortMethod === 'recent') {
        sortedTransactions = finalizedTransactions
          .slice()
          .sort(function (a: any, b: any) {
            if (a.updatedAt > b.updatedAt) {
              return 1;
            } else {
              return -1;
            }
          });
      } else {
        sortedTransactions = finalizedTransactions.slice();
      }

      setDisplayTransactions(sortedTransactions);
    };
    //To get the transactions
    //Make the date of the transaction look prettier
    //Put all sender and receiver info if missing
    const finalizedTransactions = Object.values(transactions).map(
      (transaction) => {
        const updatedAtDate = new Date(transaction.updatedAt);
        const updatedAtString = updatedAtDate.toDateString();
        if (transaction.sender) {
          if (
            transaction.sender.email === undefined ||
            transaction.receiver.email === undefined
          ) {
            const sender = users[transaction.sender.id];
            const receiver = users[transaction.receiver.id];
            return {
              ...transaction,
              updatedAt: updatedAtString,
              sender: sender,
              receiver: receiver,
            };
          }
        }
        return {
          ...transaction,
          updatedAt: updatedAtString,
        };
      }
    );
    displayFunction(finalizedTransactions);
  }, [transactions, sortMethod]);

  //Handles changes in the number of rows per page in the table
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Fragment>
      <Title>Transactions</Title>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>Type</StyledTableCell>
              <StyledTableCell>Updated At</StyledTableCell>
              <StyledTableCell>To</StyledTableCell>
              <StyledTableCell>From</StyledTableCell>
              <StyledTableCell>ID</StyledTableCell>
              <StyledTableCell>Value</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {displayTransactions
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((transaction) => (
                <TableRow key={transaction._id}>
                  <TableCell>{transaction.status}</TableCell>
                  <TableCell>{transaction.updatedAt}</TableCell>
                  <TableCell>{transaction.receiver.email}</TableCell>
                  <TableCell>{transaction.sender.email}</TableCell>
                  <TableCell>{transaction._id}</TableCell>
                  <TableCell>{transaction.amount}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        count={displayTransactions.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Fragment>
  );
}
export default Transactions;
