import { combineReducers } from 'redux';
import alertReducer, { IAlertState } from './alertReducer';
import authReducer, { IAuthStateData } from './authReducer';
import txnReducer, { TxnDryState } from './txnReducer';
import boltReducer, { BoltState } from './boltReducer';
import usersReducer, { UsersState } from './usersReducer';
import modalReducer, { IModalState } from './modalReducer';
import accountDataReducer, { IAccountDataState } from './accountDataReducer';
import qrReducer, { IQrState } from './qrReducer';

export interface IAppState {
  transactions: TxnDryState;
  authState: IAuthStateData;
  accountDataState: IAccountDataState;
  alertState: IAlertState;
  modalState: IModalState;
  userBolts: BoltState;
  users: UsersState;
  qr: IQrState;
}

export const rootReducer = combineReducers<IAppState>({
  transactions: txnReducer,
  authState: authReducer,
  accountDataState: accountDataReducer,
  alertState: alertReducer,
  modalState: modalReducer,
  userBolts: boltReducer,
  users: usersReducer,
  qr: qrReducer,
});
