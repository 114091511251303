import { makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  slipContent: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    flexShrink: 1,
    minWidth: '150px',
    borderRadius: 8,
    padding: theme.spacing(2),
  },
}));

/**
 * A SlipContent component should be used as the main content
 * component for a Slip. It includes only minimal styling for
 * self-positioning and padding.
 */
const SlipContent: React.FC = (props) => {
  const { children, ...other } = props;
  const classes = useStyles();

  return (
    <div className={classes.slipContent} {...other}>
      {children}
    </div>
  );
};

export default SlipContent;
