import { Store, AnyAction } from 'redux';
import { authAxios } from '../../services/serviceUtils';
import { openModal } from '../actions/modalAction';
import { IAppState } from '../reducers/reducer';

/**
 * Handles re-login/modal in case of expired session tokens
 */
// FIXLATER
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const intercept = (store: Store<IAppState, AnyAction>) => {
  authAxios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      // error code for expired session token; see /backend/src/error/LoginError.ts
      if (error.response.data.code === 'BOLT1005') {
        store.dispatch(openModal());
      }
      return Promise.reject(error);
    }
  );
};
