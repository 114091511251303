import { AlertActionsTypes } from '../actions/actionTypes';

export interface IAlertState {
  type: string;
  message: string;
}

interface IErrorAction {
  type: string;
  payload: any;
}

const initialState: IAlertState = {
  type: AlertActionsTypes.CLEAR,
  message: '',
};

// FIXLATER
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const alertReducer = (
  state: IAlertState = initialState,
  action: IErrorAction
) => {
  switch (action.type) {
    //Reducer for a success message
    case AlertActionsTypes.SUCCESS:
      return {
        type: 'success',
        message: action.payload.message,
      };
    //Reducer for an error message
    case AlertActionsTypes.ERROR:
      return {
        type: 'error',
        message: action.payload.message,
      };
    //Reducer for clearing message
    case AlertActionsTypes.CLEAR:
      return initialState;
    default:
      return state;
  }
};

export default alertReducer;
