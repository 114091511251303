import React, { useState } from 'react';
import {
  Grid,
  Container,
  Card,
  Tab,
  Tabs,
  Typography,
  Hidden,
  Divider,
  MobileStepper,
  CircularProgress,
  makeStyles,
  Box,
} from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { useSelector } from 'react-redux';
import { selectAuthenticated, selectUserRole } from '../../redux/selectors';

type helpData = {
  merchant: [
    {
      tocentry: string;
      title: string;
      desktop: [
        {
          title: string;
          text: string;
          image: string;
        }
      ];
      mobile: [
        {
          title: string;
          text: string;
          image: string;
        }
      ];
    }
  ];
  customer: [
    {
      tocentry: string;
      title: string;
      desktop: [
        {
          title: string;
          text: string;
          image: string;
        }
      ];
      mobile: [
        {
          title: string;
          text: string;
          image: string;
        }
      ];
    }
  ];
};

const useStyles = makeStyles((theme) => ({
  title: {
    display: 'inline-flex',
    gap: '1em',
    alignItems: 'center',
  },
  step: {
    backgroundColor: theme.palette.grey[100],
    width: 40,
    height: 40,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  viewToggle: {
    padding: '4px 8px',
    borderRadius: 20,
    textTransform: 'none',
    fontStyle: 'italic',
  },
  tabWrapper: {
    flexDirection: 'row',
    justifyContent: 'start',
    textAlign: 'left',
  },
}));

// FIXLATER
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function Help() {
  const [tab, setTab] = useState(0);
  const [helpData, setHelpData] = useState<helpData>();
  const [device, setDevice] = useState<'desktop' | 'mobile'>('mobile');
  const [userType, setUserType] = useState<'customer' | 'merchant'>('customer');

  const isAuthenticated = useSelector(selectAuthenticated);
  const isMerchant = useSelector(selectUserRole);

  const classes = useStyles();

  React.useEffect(() => {
    fetch('/help.json', {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    }).then((res) => res.json().then((data) => setHelpData(data)));
  }, []);

  React.useEffect(() => {
    setTab(0);
  }, [userType, device]);

  React.useEffect(() => {
    if (!isAuthenticated) return;
    if (isMerchant) setUserType('merchant');
    else setUserType('customer');
  }, [isAuthenticated, isMerchant]);

  const handleChange = (event: any, newValue: React.SetStateAction<number>) => {
    setTab(newValue);
  };

  return helpData ? (
    <Container maxWidth="lg">
      <Card style={{ margin: 10, borderRadius: 20, padding: 20 }}>
        <Grid container spacing={4} alignItems="flex-start" justify="center">
          <Grid item xs={12}>
            <Grid container spacing={10} alignItems="center">
              <Grid item>
                <Typography variant="h3">Help</Typography>
              </Grid>
              <Hidden smDown>
                <Grid item>
                  <ToggleButtonGroup
                    onChange={(e, v) => {
                      setDevice(v ?? device);
                    }}
                    exclusive
                    value={device}
                  >
                    <ToggleButton
                      value={'desktop'}
                      className={classes.viewToggle}
                    >
                      Desktop
                    </ToggleButton>
                    <ToggleButton
                      value={'mobile'}
                      className={classes.viewToggle}
                    >
                      Mobile
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
              </Hidden>
              {!isAuthenticated && (
                <Grid item>
                  <ToggleButtonGroup
                    onChange={(e, v) => {
                      setUserType(v ?? userType);
                    }}
                    exclusive
                    value={userType}
                  >
                    <ToggleButton
                      value={'customer'}
                      className={classes.viewToggle}
                    >
                      Customer
                    </ToggleButton>
                    <ToggleButton
                      value={'merchant'}
                      className={classes.viewToggle}
                    >
                      Merchant
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
              )}
            </Grid>
          </Grid>

          {/* Desktop vertical help menu */}
          <Hidden smDown>
            <Grid item md={3}>
              <Tabs value={tab} onChange={handleChange} orientation="vertical">
                {helpData[userType]
                  ?.filter((section) => !!section[device])
                  .map((section) => (
                    <Tab
                      key={section.tocentry}
                      label={section.tocentry}
                      classes={{ wrapper: classes.tabWrapper }}
                    />
                  ))}
              </Tabs>
            </Grid>
          </Hidden>

          {/* Mobile horizontal help menu */}
          <Hidden mdUp>
            <Grid item xs={12}>
              <Tabs
                value={tab}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="on"
              >
                {helpData[userType]
                  ?.filter((section) => !!section.mobile)
                  .map((section) => (
                    <Tab key={section.tocentry} label={section.tocentry} />
                  ))}
              </Tabs>
              <Divider />
            </Grid>
            <MobileStepper
              variant="dots"
              steps={helpData[userType]?.length}
              position="static"
              activeStep={tab}
              nextButton={<></>}
              backButton={<></>}
            />
          </Hidden>

          {/* Desktop help content */}
          <Grid item md={9} hidden={device === 'mobile'}>
            <Grid container spacing={5}>
              <Grid item>
                <Typography variant="h5">
                  {
                    helpData[userType]?.filter(
                      (section) => !!section[device]
                    )?.[tab]?.title
                  }
                </Typography>
              </Grid>
              {helpData[userType]
                ?.filter((section) => !!section[device])
                ?.[tab]?.[device]?.map((section, index) => (
                  <Grid item key={section.title}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <div className={classes.title}>
                          <span className={classes.step}>
                            <Typography color="textSecondary">
                              {index + 1}
                            </Typography>
                          </span>
                          <Typography>
                            <b>{section.title}</b>
                          </Typography>
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="body2">{section.text}</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Box
                          border={2}
                          borderRadius="borderRadius"
                          borderColor="grey.500"
                          style={{
                            overflow: 'hidden',
                            width: '80%',
                            marginInline: '10% 10%',
                            writingMode: 'horizontal-tb',
                          }}
                        >
                          <img
                            src={section.image}
                            alt="img"
                            style={{
                              width: '100%',
                            }}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
            </Grid>
          </Grid>

          {/* Mobile help content */}
          <Grid item xs={12} md={9} hidden={device === 'desktop'}>
            <Grid container spacing={5}>
              <Grid item>
                <Typography variant="h5">
                  {
                    helpData[userType]?.filter((section) => !!section.mobile)[
                      tab
                    ]?.title
                  }
                </Typography>
              </Grid>
              {helpData[userType]
                ?.filter((section) => !!section.mobile)
                [tab]?.mobile.map((section, index) => (
                  <Grid item key={section.title}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <div className={classes.title}>
                          <span className={classes.step}>
                            <Typography color="textSecondary">
                              {index + 1}
                            </Typography>
                          </span>
                          <Typography>
                            <b>{section.title}</b>
                          </Typography>
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="body2">{section.text}</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Box
                          border={2}
                          borderRadius="borderRadius"
                          borderColor="grey.500"
                          style={{
                            overflow: 'hidden',
                            width: '40%',
                            marginInline: '30% 30%',
                            writingMode: 'horizontal-tb',
                          }}
                        >
                          <img
                            src={section.image}
                            alt="img"
                            style={{
                              width: '100%',
                            }}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </Container>
  ) : (
    <CircularProgress />
  );
}

export default Help;
