import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
  makeStyles,
  createStyles,
  Theme,
  DialogActions,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { requestZUZCreationAuth } from '../../redux/actions/boltAction';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    boltViewPaper: {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.secondary.main,
      alignItems: 'center',
      padding: 10,
    },
  })
);

type ADDBOLTS = {
  setDialogOpen: (isOpen: boolean) => void;
};

//Component for merchants to request ZUZ creation privileges
// FIXLATER
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function RequestCreateZuzDialog(props: ADDBOLTS) {
  const dispatch = useDispatch();
  const classes = useStyles();

  //Triggered when the user clicks confirm
  const handleSubmit = () => {
    props.setDialogOpen(false);
    dispatch(requestZUZCreationAuth(true, true));
  };

  return (
    <Dialog
      open={true}
      classes={{
        paper: classes.boltViewPaper,
      }}
    >
      <DialogTitle id="form-dialog-title">
        Request Authorization to Create ZUZ
      </DialogTitle>
      <DialogContent>
        <Typography color="textSecondary" gutterBottom>
          As part of our initial launch, we are manually authorizing merchants
          to create ZUZ Specifications. If you'd like to create your own ZUZ
          please hit the confirm button below and we will be in touch with you
          soon!
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => props.setDialogOpen(false)}
          color="primary"
          variant="contained"
        >
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary" variant="contained">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default RequestCreateZuzDialog;
