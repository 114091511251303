import {
  makeStyles,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Paper,
  Button,
  TablePagination,
} from '@material-ui/core';
import Title from '../common/Title';
import React, { Fragment, useState, useCallback } from 'react';
import { ITXNDATA } from '../../utils/types';
import StyledTableCell from './StyledTableCell';
import { useDispatch, useSelector } from 'react-redux';
import {
  acceptTxnAction,
  rejectTxnAction,
} from '../../redux/actions/txnAction';
import AcceptTransaction, { ACCTXNDIALOG } from './AcceptTransaction';
import { getIncomingTransactions } from '../../redux/selectors';

const useStyles = makeStyles((theme) => ({
  cardsSection: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  table: {
    minWidth: 700,
  },
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));

const rowsPerPageOptions = [5, 10, 25];

// FIXLATER
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function IncomingTransactions() {
  const transactions = useSelector(getIncomingTransactions);
  const classes = useStyles();

  // Reformatting date strings
  const incomingTransactions = Object.values(transactions).map(
    (transaction) => {
      const updatedAt = new Date(transaction.updatedAt);
      const createdAt = new Date(transaction.createdAt);
      const formattedUpdatedAt = updatedAt.toDateString();
      const formattedCreatedAt = createdAt.toDateString();
      return {
        ...transaction,
        createdAt: formattedCreatedAt,
        updatedAt: formattedUpdatedAt,
      };
    }
  );

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  //Function for handling changing pages in Incoming Transactions table
  const handleChangePage = useCallback(
    (__, page) => {
      setPage(page);
    },
    [setPage]
  );

  //Function for handling changes the number of rows in the table
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [dialogState, setDialogState] = useState<ACCTXNDIALOG>({
    isOpen: false,
  });

  const handleAccept = (transaction: ITXNDATA) => {
    setDialogState({
      isOpen: true,
      transaction: transaction,
      processing: true,
    });
    const callback = (status: string, txn?: ITXNDATA) => {
      let dialogTxn;
      if (status === 'error') {
        dialogTxn = transaction;
      } else {
        dialogTxn = txn;
      }
      setDialogState({
        isOpen: true,
        transaction: dialogTxn,
        processing: false,
      });
    };
    dispatch(acceptTxnAction(transaction, callback));
  };

  const handleReject = (transaction: ITXNDATA) => {
    dispatch(rejectTxnAction(transaction));
  };

  const dispatch = useDispatch();

  return (
    <Fragment>
      <Title>Incoming Transactions</Title>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Status</StyledTableCell>
              <StyledTableCell>Date Created</StyledTableCell>
              <StyledTableCell>Date Updated</StyledTableCell>
              <StyledTableCell>From</StyledTableCell>
              <StyledTableCell>ZUZ ID</StyledTableCell>
              <StyledTableCell>Value</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {incomingTransactions
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((transaction) => (
                <TableRow key={transaction._id}>
                  <TableCell>
                    <div className={classes.root}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          handleAccept(transaction);
                        }}
                      >
                        Accept
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleReject(transaction)}
                      >
                        Reject
                      </Button>
                    </div>
                  </TableCell>
                  <TableCell>{transaction.createdAt}</TableCell>
                  <TableCell>{transaction.updatedAt}</TableCell>
                  <TableCell>{transaction.sender.email}</TableCell>
                  <TableCell>{transaction.bolt.specID}</TableCell>
                  <TableCell>{transaction.amount}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        count={incomingTransactions.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
      {dialogState.isOpen && (
        <AcceptTransaction
          dialogState={dialogState}
          setDialogState={setDialogState}
        />
      )}
    </Fragment>
  );
}

export default IncomingTransactions;
