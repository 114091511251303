import { IUSERBOLTDATA, SPECUUID, USERUUID } from '../../utils/types';
import { Dispatch } from 'redux';
import { BoltActionsTypes } from './actionTypes';
import boltService from '../../services/boltService';
//import userSearchService from "../../services/userSearchService";
import { error, success } from './alertAction';
import { IAppState } from '../reducers/reducer';

//Action for getting all the user bolts
// FIXLATER
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getUserBoltsAction = (bolts: Array<IUSERBOLTDATA>) => {
  return {
    type: BoltActionsTypes.GET_USER_BOLTS,
    payload: { bolts },
  };
};

//Action for requesting ZUZ creation authorization
export const requestZUZCreationAuth = (
  request: boolean,
  displayMsg: boolean
) => {
  // FIXLATER
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return (dispatch: Dispatch /*, getState: () => IAppState*/) => {
    boltService
      .reqZuzCreation(request)
      .then((res) => {
        if (res.status === 'error') {
          dispatch(error(res.msg));
        } else {
          if (displayMsg) {
            dispatch(
              success('Successfully requested ZUZ Creation Authorization!')
            );
          }
        }
      })
      .catch((/*err*/) => {
        dispatch(error('Error requesting ZUZ Creation Authorization'));
      });
  };
};

//Action for creating a bolt (just the service calls)
export const createBolt = (
  name: string,
  maturity: string,
  rate: number,
  number: number,
  donation: boolean,
  buyback: { [specID: string]: number } | null,
  setIsLoading: (state: boolean | null) => void
) => {
  // FIXLATER
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return (dispatch: Dispatch, getState: () => IAppState) => {
    boltService
      .createNewBolt(name, maturity, rate, number, donation, buyback)
      .then((res) => {
        if (res.status === 'error') {
          setIsLoading(false);
          dispatch(error(res.msg));
        } else {
          // temporary hack - different backend response when buyback is included in request params
          if (res.bolt.issuer?.id) {
            res.bolt.issuer = res.bolt.issuer.id;
          }
          if (!res.bolt.specName) {
            res.bolt.specName = res.bolt.name;
          }
          if (!res.bolt.issuerName) {
            res.bolt.issuerName = getState().users[res.bolt.issuer].username;
          }
          console.log(res.bolt);
          dispatch(createBoltAction(res.bolt));
          setIsLoading(false);
          dispatch(success('Successfully created ZUZ!'));
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        dispatch(error('Error creating ZUZ'));
      });
  };
};

//Action for creating a bolt (actually calls reducer)
// FIXLATER
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const createBoltAction = (bolt: IUSERBOLTDATA) => {
  return {
    type: BoltActionsTypes.CREATE_BOLT,
    payload: { bolt },
  };
};

//Action for minting a bolt (contains the service call)
export const mintBolt = (
  specID: SPECUUID,
  value: number,
  setIsMinting: (state: boolean | null) => void,
  merchantID?: USERUUID
) => {
  // FIXLATER
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return (dispatch: Dispatch) => {
    boltService
      .mintBolt(specID, value, merchantID)
      .then((res) => {
        if (res.status === 'error') {
          setIsMinting(false);
          dispatch(error(res.msg));
        } else {
          dispatch(mintBoltAction(res.bolt));
          setIsMinting(false);
          dispatch(success('Successfully minted ZUZ!'));
        }
      })
      .catch((/*err*/) => {
        setIsMinting(false);
        dispatch(error('Error minting ZUZ'));
      });
  };
};

//Action for selling a bolt (contains the service call)
export const sellBolt = (
  boltID: SPECUUID,
  amount: number,
  setIsSelling: (state: boolean | null) => void,
  email: string
) => {
  // FIXLATER
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return (dispatch: Dispatch) => {
    boltService
      .sellBolt(boltID, amount, email)
      .then((res) => {
        if (res.status === 'error') {
          setIsSelling(false);
          dispatch(error(res.msg));
        } else {
          setIsSelling(false);
          dispatch(
            success('Successfully sold ZUZ! Customer notified via e-mail.')
          );
        }
      })
      .catch((/*err*/) => {
        setIsSelling(false);
        dispatch(error('Error selling ZUZ'));
      });
  };
};

//Action for donating a bolt (contains the service call)
export const donateBolt = (specID: SPECUUID, amount: number) => {
  // FIXLATER
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return (dispatch: Dispatch) => {
    boltService
      .donateBolt(specID, amount)
      .then((res) => {
        if (res.status === 'error') {
          dispatch(error(res.msg));
        } else {
          dispatch(donateBoltAction(specID, res.remaining));
          dispatch(success('Successfully donated ZUZ!'));
        }
      })
      .catch((/*err*/) => {
        dispatch(error('Error donating ZUZ'));
      });
  };
};

//Action for donating a bolt that calls the reducer
// FIXLATER
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const donateBoltAction = (specID: string, newAmt: number) => {
  return {
    type: BoltActionsTypes.DONATE_BOLT,
    payload: {
      specID: specID,
      newAmt: newAmt,
    },
  };
};

//Action for minting a bolt (actually calls the reducer)
// FIXLATER
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const mintBoltAction = (bolt: IUSERBOLTDATA) => {
  return {
    type: BoltActionsTypes.MINT_BOLT,
    payload: { bolt },
  };
};

/*
type ISSUER = {
  bolts: Array<SPECUUID>;
};
*/
