import React, { useState } from 'react';
import { useDependenciesDebugger } from '../../utils/depdebug';
import { makeStyles } from '@material-ui/core/styles';
import MyBolt from './UserBolt';
import NewBolt from './NewBolt';
import RequestCreateZuzDialog from './RequestCreateZuzDialog';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import PendingTransactions from './PendingTransactions';
import Title from '../common/Title';
import { useSelector } from 'react-redux';
import {
  selectIsDelegate,
  selectPermissions,
  selectUserRole,
} from '../../redux/selectors';
import { useDispatch } from 'react-redux';
import { requestZUZCreationAuth } from '../../redux/actions/boltAction';
const { REACT_APP_ALLOW_ZUZSPEC } = process.env;

const useStyles = makeStyles((theme) => ({
  boundedContainer: {
    padding: theme.spacing(0.7),
  },
  addIconDiv: {
    textAlign: 'right',
  },
  addIcon: {
    margin: theme.spacing(1),
  },
  cardsContainer: {
    padding: theme.spacing(0.7),
    marginTop: theme.spacing(2),
  },
}));

// This component displays all the information and functionality
// associated with a user's wallet. This includes, a new zuz button,
// an incoming transactions list and a list of the user's BoLTs
// FIXLATER
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function Wallet() {
  const classes = useStyles();
  const [dialogOpen, setDialogOpen] = useState(false);
  const permissions = useSelector(selectPermissions);

  // state var to keep track of spec creation auth dialog open status
  const [specAuthDialogOpen, setSpecAuthDialogOpen] = useState(false);

  const dispatch = useDispatch();

  const isMerchant = useSelector(selectUserRole);
  const isDelegate = useSelector(selectIsDelegate);

  useDependenciesDebugger({
    isMerchant,
    isDelegate,
    permissions,
  });

  //Handles clicking the create bolt button
  const handleClick = () => {
    if (REACT_APP_ALLOW_ZUZSPEC === 'true') {
      setDialogOpen(true);
    } else {
      //make requestZUZCreationAuth API call but don't display success message
      dispatch(requestZUZCreationAuth(false, false));
      setSpecAuthDialogOpen(true);
    }
  };
  return (
    <main>
      {/* Only non-delegate merchants should be able to create new zuz specs */}
      {isMerchant && !isDelegate && (
        <div className={classes.addIconDiv}>
          <Fab
            size="medium"
            color="primary"
            aria-label="add"
            className={classes.addIcon}
            variant="extended"
            onClick={handleClick}
          >
            <AddIcon />
            New ZUZ
          </Fab>
        </div>
      )}
      <Title>My ZUZ Wallet</Title>

      {/* {isLoadingWallet && <CircularProgress />} */}

      {/* transactions go here */}
      <div className={classes.boundedContainer}>
        {permissions.accept && <PendingTransactions />}
      </div>

      {/* ZUZ held goes here */}
      <div className={classes.cardsContainer}>
        <MyBolt />
      </div>
      {dialogOpen && <NewBolt setDialogOpen={setDialogOpen} />}
      {specAuthDialogOpen && (
        <RequestCreateZuzDialog setDialogOpen={setSpecAuthDialogOpen} />
      )}
    </main>
  );
}

export default Wallet;
