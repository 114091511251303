import { useRef, useMemo } from 'react';

// include useDependenciesDebugger({ var1, var2, ..., varn }) in a
// component to determine which of the n var's is triggering a
// re-render

// copied from https://stackoverflow.com/questions/55187563/determine-which-dependency-array-variable-caused-useeffect-hook-to-fire

const compareInputs = (
  inputKeys: string[],
  oldInputs: { [name: string]: any },
  newInputs: { [name: string]: any }
) => {
  inputKeys.forEach((key) => {
    const oldInput = oldInputs[key];
    const newInput = newInputs[key];
    if (oldInput !== newInput) {
      console.log('change detected', key, 'old:', oldInput, 'new:', newInput);
    }
  });
};

// FIXLATER
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useDependenciesDebugger = (inputs: { [name: string]: any }) => {
  const oldInputsRef = useRef(inputs);
  const inputValuesArray = Object.values(inputs);
  const inputKeysArray = Object.keys(inputs);
  useMemo(() => {
    const oldInputs = oldInputsRef.current;

    compareInputs(inputKeysArray, oldInputs, inputs);

    oldInputsRef.current = inputs;
  }, inputValuesArray);
};
