import { makeStyles, CardActionArea } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  slipActionArea: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    flexShrink: 1,
    minWidth: '150px',
    borderRadius: 8,
    padding: theme.spacing(0),
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
}));

export type SlipActionAreaProps = {
  onClick?: () => void;
};

/**
 * A SlipActionArea component should be used as the main content
 * component for a Slip. It provides hover highlighting and click
 * ripple effects, so an onClick function should most likely be
 * specified. If the content is not clickable, consider using
 * SlipContent instead.
 */
const SlipActionArea: React.FC<SlipActionAreaProps> = (props) => {
  const { children, onClick, ...other } = props;
  const classes = useStyles();

  return (
    <CardActionArea
      className={classes.slipActionArea}
      onClick={onClick}
      {...other}
    >
      {children}
    </CardActionArea>
  );
};

SlipActionArea.defaultProps = {
  onClick: () => undefined,
};

export default SlipActionArea;
