import React, { useState, ChangeEvent, Fragment } from 'react';
import QRCode from 'react-qr-code';
import {
  makeStyles,
  Dialog,
  Typography,
  DialogContent,
  Card,
  CardContent,
  TextField,
  Button,
  Grid,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { selectActingUser } from '../../redux/selectors';
import { IUSERDATA } from '../../utils/types';
import { IAppState } from '../../redux/reducers/reducer';
import { setOpen } from '../../redux/actions/qrAction';
import { QrUniqId } from '../../context/WebSocketContext';

const { REACT_APP_QR_PREFIX } = process.env;

const useStyles = makeStyles((theme) => ({
  requestCard: {
    minHeight: '455px',
    maxWidth: '400px',
    padding: 10,
    display: 'flex',
    justifyContent: 'center',
    color: theme.palette.secondary.main,
  },
  textFieldStyle: {
    marginTop: 0,
  },
  notesTextField: {
    width: '100%',
    height: '75%',
    display: 'block',
    marginTop: '16px',
  },
  requestTypography: {
    textAlign: 'center',
    display: 'inline-flex',
    alignItems: 'center',
  },
  cardGrid: {
    minHeight: '100%',
  },
  generateButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.secondary.light,
    width: 150,
    '&:hover': {
      backgroundColor: theme.palette.secondary.light,
      color: theme.palette.primary.main,
    },
  },
  dialogContent: {
    paddingBottom: '20px',
  },
}));

// name of destination wallet for transfer
function getQRname(usr: IUSERDATA | undefined) {
  if (usr === undefined) return '';
  if (
    'businessName' in usr &&
    usr.businessName !== undefined &&
    usr.businessName !== ''
  )
    return usr.businessName;
  return usr.username;
}

interface QRvalueObject {
  name: string;
  code: string;
  id: string;
  amt: number;
}

function makeQRvalue(info: QRvalueObject): string {
  if (false) {
    // previous method
    return JSON.stringify(info);
  } else {
    // new version, web URL
    return `${REACT_APP_QR_PREFIX}${info.amt}/${
      info.id
    }/${info.code.toUpperCase()}`;
  }
}

// FIXLATER
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function QRCodeCreator() {
  const dispatch = useDispatch();
  const classes = useStyles();

  //Get store information
  const store = useSelector(selectActingUser);
  console.log(store);
  const displayName = getQRname(store);

  //Open qr dialog state
  const openQrDialog = useSelector((state: IAppState) => state.qr.open);
  const setOpenQrDialog = (open: boolean) => dispatch(setOpen(open));

  //State for the amount
  const [amount, setAmount] = useState('');

  //State for the validity of the amount
  const [amountValid, setAmountValid] = useState(true);

  //Function for generating and displaying qr code
  const handleGenerateQr = () => {
    if (!amountValid) {
      setAmountValid(false);
    } else {
      setOpenQrDialog(true);
    }
  };

  //Function for handling the amount
  const handleAmountChange = (e: ChangeEvent<HTMLInputElement>) => {
    setAmount(e.target.value);

    //Checking validity of the user input
    if (e.target.value === '') {
      setAmountValid(true);
    } else if (isNaN(Number(e.target.value)) || Number(e.target.value) < 0) {
      setAmountValid(false);
    } else {
      setAmountValid(true);
    }
  };

  return (
    <Fragment>
      <Card className={classes.requestCard}>
        <CardContent>
          <Grid
            container
            spacing={0}
            direction="column"
            alignContent="center"
            justify="center"
            style={{ height: '100%', width: '100%' }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center',
              }}
            >
              <Typography
                variant="h6"
                component="h6"
                className={classes.requestTypography}
              >
                Request <span>&nbsp;&nbsp;</span>
              </Typography>
              <form>
                <TextField
                  className={classes.textFieldStyle}
                  required
                  variant="outlined"
                  color="secondary"
                  margin="none"
                  fullWidth
                  id="amount"
                  label="Amount of Zuz"
                  name="amount"
                  autoFocus
                  value={amount}
                  onChange={handleAmountChange}
                  error={!amountValid}
                  helperText={
                    amountValid ? '' : 'Enter an amount that is greater than 0'
                  }
                />
              </form>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <TextField
                variant="outlined"
                color="secondary"
                margin="none"
                className={classes.notesTextField}
                fullWidth
                rows={10}
                multiline
                id="notes"
                label="Notes"
                name="Notes"
              />
            </div>

            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '16px',
              }}
            >
              <Button
                className={classes.generateButton}
                onClick={handleGenerateQr}
              >
                Generate QR Code
              </Button>
            </div>
          </Grid>
        </CardContent>
      </Card>
      <Dialog open={openQrDialog} onClose={() => setOpenQrDialog(false)}>
        <DialogContent
          className={classes.dialogContent}
          style={{ display: 'flex', alignContent: 'center' }}
        >
          <QRCode
            value={makeQRvalue({
              name: displayName,
              id: store === undefined ? '' : store.id,
              amt: amount === '' ? 0 : Number(amount),
              code: QrUniqId,
            })}
            level={'Q'}
          />
        </DialogContent>
      </Dialog>
    </Fragment>
  );
}

export default QRCodeCreator;
