import {
  Dialog,
  TextField,
  Box,
  makeStyles,
  createStyles,
  Button,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import React, { useState, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { loginAction } from '../../redux/actions/authAction';
import { WebSocketContext, WS } from '../../context/WebSocketContext';

interface AddProfileModalStateProps {
  open: boolean;
  closeFunction: () => void;
}

const useStyles = makeStyles((/*theme: Theme*/) =>
  createStyles({
    modalContainer: {
      display: 'flex',
      padding: '15px',
      flexDirection: 'column',
    },
    textFieldContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
  }));

// FIXLATER
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const AddProfileModal = ({
  open,
  closeFunction,
}: AddProfileModalStateProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [userName, setUserName] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [rememberMe, setRememberMe] = useState<boolean>(false);

  const ws = useContext(WebSocketContext) as WS;

  //Logins in new account, onClick for button
  const loginNewAccount = () => {
    dispatch(loginAction(userName, password, rememberMe, ws));
  };

  return (
    <Dialog open={open} onClose={closeFunction}>
      <Box className={classes.modalContainer}>
        <Box className={classes.textFieldContainer}>
          <TextField
            label={'User Name'}
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
          />
          <TextField
            type={'password'}
            label={'Password'}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <FormControlLabel
            control={
              <Checkbox
                value="remember"
                checked={rememberMe}
                onChange={(e) => setRememberMe(e.target.checked)}
              />
            }
            label="Remember me"
          />
        </Box>
        <Button onClick={loginNewAccount}>Add Profile</Button>
      </Box>
    </Dialog>
  );
};

export default AddProfileModal;
