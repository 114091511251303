import React, { useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Typography,
  Fab,
  Avatar,
  Grid,
  Button,
  makeStyles,
  createStyles,
  Theme,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { selectProfiles, selectLoginUser } from '../../redux/selectors';
import { WebSocketContext, WS } from '../../context/WebSocketContext';
import { USERUUID } from '../../utils/types';
import {
  switchAccounts,
  logoutAccount,
  logoutAllAccounts,
} from '../../redux/actions/authAction';
import AddProfileModal from '../profile/addProfileModal';
import Slip from '../common/Slip';
import SlipActions from '../common/SlipActions';
import SlipActionArea from '../common/SlipActionArea';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    addAccountSection: {
      display: 'flex',
      textAlign: 'left',
      marginBottom: 15,
    },
    addIcon: {
      width: 40,
      height: 40,
    },
    buttonSection: {
      display: 'flex',
      float: 'right',
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
    },
    avatar: {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
      width: 40,
      height: 40,
    },
    listItemProfiles: {
      '&:hover': {
        background: theme.palette.primary,
      },
    },
    profileDetails: {
      fontStyle: 'italic',
    },
    transactionsBox: {
      marginTop: '3rem',
      width: '100%',
    },
    slipContainer: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: 9,
      marginTop: 9,
      padding: 0,
      '&:hover': {
        backgroundColor: '#00A89E1A',
      },
      '& .appear-item': {
        display: 'none',
      },
      '&:hover .appear-item': {
        display: 'block',
      },
    },
  })
);

// FIXLATER
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function ManageAccounts() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const ws = useContext(WebSocketContext) as WS;

  // list of account profiles on this device
  const profiles = useSelector(selectProfiles);
  const loginUser = useSelector(selectLoginUser);

  console.log(loginUser);

  const [show, setShow] = useState(false);

  //onClick function for card. Changes profiles
  const changeProfile = (loginID: USERUUID, actingID: USERUUID) => {
    dispatch(switchAccounts(loginID, actingID, ws));
  };

  const logoutProfile = (accountID: string) => {
    dispatch(logoutAccount(accountID, ws.socket));
  };
  const logoutAllProfiles = () => {
    dispatch(logoutAllAccounts(ws.socket));
  };

  const handleMouseOver = () => {
    setShow(true);
  };
  const handleMouseOut = () => {
    setShow(false);
  };

  //State for modal to add users
  const [addProfileModalState, toggleAddProfileModalState] =
    useState<boolean>(false);

  return (
    <Box maxWidth="lg" style={{ marginTop: '40px', marginBottom: '40px' }}>
      <Typography component="h3" variant="h4" gutterBottom>
        Manage Accounts
      </Typography>
      <AddProfileModal
        open={addProfileModalState}
        closeFunction={() => toggleAddProfileModalState(false)}
      />
      {profiles.length > 0 ? (
        <div>
          {profiles.map((profile) => {
            return (
              <Slip key={profile.loginID} className={classes.slipContainer}>
                {profile.profileData?.businessName && (
                  <SlipActionArea>
                    <Grid
                      container
                      spacing={2}
                      alignItems="center"
                      onMouseOver={handleMouseOver}
                      onMouseOut={handleMouseOut}
                      style={{
                        backgroundColor:
                          profile.loginID == loginUser?.id && !show
                            ? '#9DC9EF4D'
                            : 'transparent' /* eslint-disable-line eqeqeq */ /* FIXLATER */,
                      }}
                    >
                      <Grid item xs={2} sm={1} lg={1}>
                        <Avatar className={classes.avatar}>
                          <FontAwesomeIcon icon={['far', 'store']} />
                        </Avatar>
                      </Grid>
                      <Grid item xs={4} sm={7} lg={8} md={7}>
                        <Typography>
                          @{profile.profileData?.businessName} (Merchant)
                        </Typography>
                      </Grid>
                      <div className="appear-item">
                        <SlipActions>
                          <Grid item xs={6}>
                            <Button
                              component="label"
                              color="primary"
                              variant="outlined"
                              onClick={() =>
                                changeProfile(profile.loginID, profile.loginID)
                              }
                            >
                              Switch
                            </Button>
                          </Grid>
                          <Grid item xs={6}>
                            <Button
                              component="label"
                              color="primary"
                              variant="outlined"
                              onClick={() => logoutProfile(profile.loginID)}
                            >
                              Logout
                            </Button>
                          </Grid>
                        </SlipActions>
                      </div>
                    </Grid>
                  </SlipActionArea>
                )}
                {!profile.profileData?.businessName && (
                  <SlipActionArea>
                    <Grid
                      container
                      spacing={2}
                      alignItems="center"
                      onMouseOver={handleMouseOver}
                      onMouseOut={handleMouseOut}
                      style={{
                        backgroundColor:
                          profile.loginID == loginUser?.id && !show
                            ? '#9DC9EF4D'
                            : 'transparent' /* eslint-disable-line eqeqeq */ /* FIXLATER */,
                      }}
                    >
                      <Grid item xs={2} sm={1} lg={1}>
                        <Avatar className={classes.avatar}>
                          <FontAwesomeIcon icon={['far', 'user']} />
                        </Avatar>
                      </Grid>
                      <Grid item xs={4} sm={7} lg={8} md={7}>
                        <Typography>
                          @{profile.profileData?.username} (User)
                        </Typography>
                      </Grid>
                      <div className="appear-item">
                        <SlipActions>
                          <Grid item xs={6}>
                            <Button
                              component="label"
                              color="primary"
                              variant="outlined"
                              onClick={() =>
                                changeProfile(profile.loginID, profile.loginID)
                              }
                            >
                              Switch
                            </Button>
                          </Grid>
                          <Grid item xs={6}>
                            <Button
                              component="label"
                              variant="outlined"
                              color="primary"
                              onClick={() => logoutProfile(profile.loginID)}
                            >
                              Logout
                            </Button>
                          </Grid>
                        </SlipActions>
                      </div>
                    </Grid>
                  </SlipActionArea>
                )}
              </Slip>
            );
          })}
        </div>
      ) : (
        <Typography>No Linked Accounts!</Typography>
      )}
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={2} sm={1} lg={1}>
          <Fab className={classes.avatar}>
            <AddIcon onClick={() => toggleAddProfileModalState(true)} />
          </Fab>
        </Grid>
        <Grid item xs={7} sm={9} lg={8} md={7}>
          <Typography>Add Account</Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2} justify="center" alignItems="center">
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={() => logoutAllProfiles()}
          >
            Logout of All Accounts
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

export default ManageAccounts;
