import { authAxios, AxiosError } from './serviceUtils';
import {
  searchUsersURL,
  searchIssuersURL,
  issuerURL,
  SearchResponse,
  APIError,
} from './api';
import { IssuerData } from '../utils/types';
import { Cache } from './cachedGet';

// This function is used to search users

// FIXLATER
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const userSearchService = async (query: string) => {
  // send post request to backend
  const response = (await authAxios
    .get(searchUsersURL(query))
    .catch((err: AxiosError) => {
      console.log(err);
      return err.response;
    })) as unknown as SearchResponse;
  // return json data
  return response.data;
};

// cache of issuer data
const issuersCache = new Cache<IssuerData>(
  'issuer',
  12,
  issuerURL,
  (ids: string[]) => `${issuerURL}${ids}`,
  (x: IssuerData) => x.id.toString()
);

const getIssuer = async (issuerID: string): Promise<IssuerData> => {
  const x = await issuersCache.get(issuerID);
  return x;
};

// return array of issuer data in response to search
const issuerSearchService = async (
  params: Record<string, any>
): Promise<Array<IssuerData>> => {
  const getHydrated = issuersCache.isEmpty();
  if (!getHydrated) {
    console.log(`Adding idsonly: ${issuersCache.isEmpty()}`);
    // first time we get anything, get them hydrated, otherwise just get by ids and hydrate from cache
    params['ids'] = true;
  }
  const response = await authAxios
    .get(searchIssuersURL, { params })
    .catch((err) => {
      console.log(err);
      return err.response;
    });
  if (response.data.status === 'error') {
    // error from backend
    throw new APIError(response.data);
  }
  if (getHydrated) {
    // install in cache
    issuersCache.install(response.data.result);
    return response.data.result;
  } else {
    // hydrate response
    return issuersCache.getList(
      response.data.result.map((x: number) => x.toString())
    );
  }
};

const userSearchServiceService = {
  userSearchService,
  issuerSearchService,
  getIssuer,
};
export default userSearchServiceService;
